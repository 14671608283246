import * as React from 'react';
import {TouchableHighlight, StyleSheet} from 'react-native';
import _ from 'lodash';
import {BottomSheet, InputProps, ListItem} from 'react-native-elements';
import ItText from '../../../../components/ItText';

export interface DropdownProps extends InputProps {
  items: {
    label: string;
    value: any;
  }[];
}

const Dropdown: React.SFC<DropdownProps> = ({
  items,
  ...props
}: DropdownProps) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const selected = _.find(
    items,
    (i) => _.toString(props.value) === _.toString(i.value),
  );
  const label = selected ? selected.label : 'Seleccione';
  return (
    <>
      <TouchableHighlight onPress={() => setIsVisible(true)} style={styles.presseable}>
        <ItText testID="dropdowntoggle" size={18}>
          {label}
        </ItText>
      </TouchableHighlight>
      <BottomSheet
        isVisible={isVisible}
        modalProps={{
          onRequestClose: () => setIsVisible(false),
        }}>
        {items.map((l, i) => (
          <ListItem
            testID={`dropdown_item_${i}`}
            key={i}
            onPress={() => {
              setIsVisible(false);
              if (props.onChangeText) {
                props.onChangeText(String(l.value));
              }
            }}>
            <ListItem.Content>
              <ListItem.Title>{l.label}</ListItem.Title>
            </ListItem.Content>
          </ListItem>
        ))}
      </BottomSheet>
    </>
  );
};

const styles = StyleSheet.create({
  presseable: {
    flex: 1,
    height: 40,
    justifyContent: 'center',
  },
});

export default Dropdown;
