import {Card, CardProps} from 'react-native-elements';
import * as React from 'react';
import {StyleSheet} from 'react-native';

export interface StyledCardProps extends CardProps {}

const StyledCard: React.FunctionComponent<StyledCardProps> = ({
  containerStyle,
  ...props
}: StyledCardProps) => {
  return (
    <Card {...props} containerStyle={[containerStyle, styles.container]} />
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    padding: 0,
  },
});

export default StyledCard;
