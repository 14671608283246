// @ts-ignore
export const primary = '#FF801F';
export const primaryDark = '#FF801F';
export const primaryDarkAlt = '#FF801F';
export const secondary = '#FF801F';
export const secondaryDark = '#FF801F';
export const pageBackground = '#EDEDED';
export const borders = '#DEDEDE';
export const third = '#FFBB02';
export const lightGray = 'rgba(135, 135, 135, 1)';
export const lightGrayAlt = '#ccc';
export const black = '#000000';
export const danger = '#C13737';
export const approved = '#A4C944';
export const lightText = '#656565';
export const pending = '#0033cc';
export const white = '#FFFFFF';
export const fourth = '#00325E';
export const contentBackground = '#E3F5FF';
export const infoSecondary = '#9A9A9A';
export const titles = '#113C72';
export const sectionThird = '#E5EFF4';
export const primaryRgb = '255, 128, 31';
export const warning = '#FD7E14';
// @deprecated
export const buttonLong: string = primary;
