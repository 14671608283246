import AsyncStorage from '@callstack/async-storage';
import Toast from 'react-native-root-toast-updated';


import {API_HOST} from '../../../env';
import {ItAppState, ItUser} from '../../../store';

export const actionTypes = {
  USER_INFO_LOADED: 'AUTH/SET_USER_INFO',
  USER_LOGOUT: 'AUTH/USER_LOGOUT',
  SET_USER: 'AUTH/SET_USER',
  SET_SENDING_TYC: 'AUTH/SET_SENDING_TYC',
};

export function authReducer(state = {}, action: {payload: any; type: string}) {
  if (action.type === actionTypes.USER_INFO_LOADED) {
    AsyncStorage.setItem('token', action.payload.access_token);
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === actionTypes.SET_USER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === actionTypes.USER_LOGOUT) {
    AsyncStorage.clear();
    setTimeout(() => {
      
      window.location.reload()
    }, 0);
    return {};
  }
  if (action.type === actionTypes.SET_SENDING_TYC) {
    return {
      ...state,
      sendingTyc: action.payload,
    };
  }
  return state;
}

export function userInfoLoaded(payload: any) {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.USER_INFO_LOADED,
      payload,
    });
  };
}

export function selectAuthenticatedUser(state: ItAppState) {
  return state?.auth?.user;
}

export function setUserInfo(user: ItUser) {
  return {
    type: actionTypes.SET_USER,
    payload: {user},
  };
}

export function getMe() {
  return async (dispatch: any) => {
    const token = await AsyncStorage.getItem('token');
    const res = await fetch(`${API_HOST}/api/oauth/pdv/me`, {
      headers: {Authorization: `Bearer ${token}`},
    });
    if (!res.ok) {
      return
    }
    const {data} = await res.json();
    dispatch(setUserInfo(data));
  };
}

export function logout() {
  return {type: actionTypes.USER_LOGOUT};
}

export function acceptTerms(): any {
  return async (dispatch, getState) => {
    const user = selectAuthenticatedUser(getState());
    const token = await AsyncStorage.getItem('token');
    dispatch({type: actionTypes.SET_SENDING_TYC, payload: true});
    try {
      const res = await fetch(
        `${API_HOST}/api/oauth/pdv/accept/current/terms`,
        {
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      );
      if (!res.ok) {
        throw new Error();
      }
    } catch (ex) {
      Toast.show('Error conectando al servidor');
    }
    dispatch({type: actionTypes.SET_SENDING_TYC, payload: false});
    dispatch(
      setUserInfo({
        ...user,
        terms_accepted: true,
      }),
    );
  };
}
