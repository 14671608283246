import * as React from 'react';
import {StyleSheet} from 'react-native';
import ItText from '../../../components/ItText';
import {Block} from '../../../components/views';
import {Space} from '../../../theme';
import CopyIcon from '../../shopper/icons/CopyIcon';
import Clipboard from '@react-native-clipboard/clipboard';
import {BorderlessButton} from 'react-native-gesture-handler';
import Toast from 'react-native-root-toast-updated';

export interface CopyBarTextProps {
  text: string;
}

const CopyBarText: React.FunctionComponent<CopyBarTextProps> = (
  props: CopyBarTextProps,
) => {
  return (
    <Block style={styles.shareBar} justifySpaceBetween>
      <ItText size={13} style={styles.link}>
        {props.text}
      </ItText>
      <BorderlessButton
        hitSlop={{
          horizontal: 20,
          vertical: 20,
        }}
        onPress={() => {
          Clipboard.setString(props.text);
          Toast.show('Texto copiado al portapapeles');
        }}>
        <CopyIcon />
      </BorderlessButton>
    </Block>
  );
};

const styles = StyleSheet.create({
  shareBar: {
    backgroundColor: '#FCFCFC',
    borderColor: '#DEDEDE',
    borderWidth: 1,
    paddingHorizontal: Space.SMALL,
    paddingVertical: Space.SMALL,
  },
  link: {
    color: '#787681',
  },
});

export default React.memo(CopyBarText);
