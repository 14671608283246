import _ from 'lodash';
import {Question} from '../entities/commercialAction';

const EMPTY_TEXT = 'Sin respuesta';
export function getQuestionResponseText(question: Question): string {
  if (question.answers && question.answers.length > 0) {
    return question.answers[0].text ?? EMPTY_TEXT;
  }
  const selectedOptions = question.options?.filter(
    (opt) => opt.answers.length > 0,
  );

  return !selectedOptions
    ? 'Ninguno'
    : selectedOptions
        .map((selectedOption) => {
          if (selectedOption.other_which_one) {
            return _.get(
              selectedOption,
              'answers[0].other_which_one',
              EMPTY_TEXT,
            );
          }
          return _.get(selectedOption, 'name', EMPTY_TEXT);
        })
        .join(', ');
}
