import AsyncStorage from '@callstack/async-storage';
import {API_HOST} from '../../../env';

export const communityDelete = async (id: any) => {
  const res = await fetch(`${API_HOST}/api/app/community/${id}`, {
    method: 'delete',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await AsyncStorage.getItem('token')}`,
    },
  });
  if (!res.ok) {
    console.error(await res.text());
    throw new Error();
  }
  return res.json();
};
