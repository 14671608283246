import * as React from 'react';
import {API_HOST} from '../../../env';
import useFetch from 'use-http';
import {InputProps} from 'react-native-elements';
import Dropdown from '../../ui/modules/inputs/Dropdown';
import {Block} from '../../../components/views';

export interface CitiesAutoCompleteProps extends InputProps {
  countryId: string;
  onChangeValue: Function;
}

const CitiesAutocomplete: React.SFC<CitiesAutoCompleteProps> = (
  props: CitiesAutoCompleteProps,
) => {
  const {data = {}} = useFetch(
    `${API_HOST}/api/city/list/${props.countryId}`,
    {},
    [],
  );
  const {data: cities = []} = data;
  const options = React.useMemo(() => {
    return cities.map((c: any) => ({label: c.descripcion, value: c.id}));
  }, [cities]);

  return (
    <Block testID="cities_dropdown">
      <Dropdown items={options} {...props} />
    </Block>
  );
};

export default CitiesAutocomplete;
