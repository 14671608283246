import * as React from 'react';
import {ActivityIndicator, RefreshControl, StyleSheet} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';
import useFetch from 'use-http';
import {API_HOST} from '../../../env';
import {useNavigation} from '@react-navigation/native';
import EmptyListComponent from '../../../components/EmptyListComponent';
import {CommercialActionListResponse} from '../entities/commercialAction';
import CampaignListItem from './CampaignListItem';
import Loading from '../../../components/Loading';

export interface CampaignListItemProps {
  endpoint?: string;
}

const DEFAULT_ENDPOINT = `${API_HOST}/api/app/comercial/action?page=1&pageSize=2000`;

const CampaignList: React.SFC<CampaignListItemProps> = ({
  endpoint = DEFAULT_ENDPOINT,
}) => {
  const navigation = useNavigation();
  const {
    data = {
      data: [],
    },
    get,
    loading,
    cache,
  } = useFetch<CommercialActionListResponse>(endpoint, {}, [endpoint]);
  const [refreshing, setRefreshing] = React.useState(false);
  const {data: items = []} = data;

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', async () => {
      await get();
    });
    return unsubscribe;
  }, [get, navigation]);

  if (loading) {
    return <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}}><Loading  size={20} show /></div>;
  }
  return (
    <div style={{
      maxHeight: 'calc(100vh - 80px)',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <FlatList
        keyboardShouldPersistTaps="always"
        contentContainerStyle={styles.listContainer}
        ListEmptyComponent={() =>
          loading ? (
            <ActivityIndicator style={{
	    alignSelf: 'center',
	    }} />
          ) : (
            <EmptyListComponent
              iconName="eye-slash"
              text="Aún no tienes campañas asignadas"
            />
          )
        }
        refreshControl={
          <RefreshControl
            onRefresh={async () => {
              setRefreshing(true);
              cache.clear();
              await get();
              setRefreshing(false);
            }}
            refreshing={refreshing}
          />
        }
        data={items}
        keyExtractor={(item) => String(item.id)}
        renderItem={({item}) => <CampaignListItem item={item} />}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  listContainer: {
    marginBottom: 100,
    paddingBottom: 200
  },
});

export default React.memo(CampaignList);
