import * as React from 'react';
import {ActivityIndicator, Dimensions, useWindowDimensions, View} from 'react-native';
import {Button, Overlay} from 'react-native-elements';
import {ScrollView} from 'react-native-gesture-handler';
import {useDispatch, useSelector} from 'react-redux';
import HtmlPreview from '../../../components/HtmlPreview';
import {Block} from '../../../components/views';
import {primary} from '../../../theme';
import {
  acceptTerms,
  logout,
  selectAuthenticatedUser,
} from '../../auth/redux/authRedux';

const {height} = Dimensions.get("screen")
const TYCValidator: React.FunctionComponent = () => {
  const user = useSelector(selectAuthenticatedUser);
  const sendingTyc = useSelector((state: any) => state?.auth?.sendingTyc);
  const dispatch = useSelector(useDispatch);
  
  const _onDissmiss = React.useCallback(() => {}, []);
  const _onNoAccept = React.useCallback(() => {
    dispatch(logout());
  }, [dispatch]);
  const _acceptTerms = React.useCallback(() => {
    dispatch(acceptTerms());
  }, [dispatch]);

  if (!user || user?.terms_accepted) {
    return null;
  }

  return (
    <View
      style={{
        height: 200,
      }}>
      <Overlay fullScreen onDismiss={_onDissmiss} isVisible>
        <>
          <ScrollView
            style={{
              height: height - 300,
            }}
            contentContainerStyle={{
              backgroundColor: 'white',
              width: '100%',
            }}>
            <HtmlPreview htmlContent={user?.terms?.terms} />
          </ScrollView>
          <Block
            style={{
              position: 'absolute',
              bottom: 10,
              alignSelf: 'center',
            }}>
            <Button
              buttonStyle={{
                backgroundColor: '#ccc',
              }}
              disabled={sendingTyc}
              title="RECHAZAR TERMINOS"
              onPress={_onNoAccept}
            />
            <Button
              title={sendingTyc ? 'CARGANDO...' : 'ACEPTAR TERMINOS'}
              disabled={sendingTyc}
              buttonStyle={{
                marginLeft: 10,
              }}
              onPress={_acceptTerms}
            />
          </Block>
        </>
      </Overlay>
    </View>
  );
};

export default React.memo(TYCValidator);
