import * as React from 'react';
import {Button} from 'react-native-elements';
import {Alert, Platform, View} from 'react-native';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import Touchable from 'react-native-platform-touchable';
import ItText from '../../ui/ItText';
import {primary} from '../../../theme/colors';
import {useDispatch, useSelector} from 'react-redux';
import ImagePicker from '../../images/libs/ImagePicker';
import {CampaignExecutedOverlay} from './CampaignExecutedOverlay';
import {CampaignsPreviewImages} from './CampaignsPreviewImages';
import {CampaignsSendButton} from './CampaignsSendButton';
import {useNavigation} from '@react-navigation/native';
import {selectImagesByCampaignId} from '../state/campaignImages/campaignImagesSelectors';
import {ItAppState} from '../../../store';
import {addCampaignImage} from '../state/campaignImages/campaignImagesActions';
import {File} from '../../../entities/File';

export interface CampaignSelectImageCardProps {
  challengeId: any;
  photos_quantity: number;
}

const CampaignSelectImageCard: React.SFC<CampaignSelectImageCardProps> = (
  props: CampaignSelectImageCardProps,
) => {
  const [completed, setCompleted] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const files = useSelector((state: ItAppState) =>
    selectImagesByCampaignId(state, props.challengeId),
  );

  const dispatch = useDispatch();

  const navigation = useNavigation();
  const uuid = useSelector((state: any) => state.auth.user.uuid);
  const pickerInstance = React.useMemo(
    () =>
      new ImagePicker(
        async (result: {
          didCancel: any;
          error: any;
          uri: string;
          fileName: any;
          type: any;
        }) => {
          if (result.didCancel) {
            return;
          }
          if (result.error) {
            Alert.alert('Error accediento a la camara');
            return;
          }
          dispatch(
            addCampaignImage(props.challengeId, {
              name: result.fileName || 'demo.jpg',
              type: result.type,
              uri:result.uri
            }),
          );
        },
      ),
    [dispatch, props.challengeId],
  );


  const userPickerImages = files.length > 0;
  if (userPickerImages) {
    const pickImagesCompleted = files.length == props.photos_quantity;
    return (
      <View
        style={{
          marginTop: 20,
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}>
        <CampaignExecutedOverlay
          completed={completed}
          setCompleted={setCompleted}
        />
        <CampaignsPreviewImages
          campaignId={props.challengeId}
          previewUri={files.map((f: File) => f.uri)}
        />
        {pickImagesCompleted ? (
          <CampaignsSendButton
            setCompleted={setCompleted}
            disabled={disabled}
            setDisabled={setDisabled}
            files={files}
            uuid={uuid}
            challengeId={props.challengeId}
          />
        ) : (
          <Button
            title="TOMAR OTRA FOTO"
            disabled={disabled}
            buttonStyle={{
              marginTop: 30,
              width: 290,
            }}
            onPress={() => pickerInstance.fromCamera()}
          />
        )}
        <Button
          title="CANCELAR"
          type="outline"
          onPress={() => {
            navigation.goBack();
          }}
          buttonStyle={{
            minWidth: 290,
            marginTop: 10,
            marginBottom: 40,
          }}
        />
      </View>
    );
  }
  return (
    <Touchable
      onPress={() => {
        pickerInstance.fromCamera();
      }}>
      <View
        style={{
          backgroundColor: 'rgb(255,224,217)',
          justifyContent: 'center',
          alignItems: 'center',
          paddingVertical: 15,
        }}>
        <FontAwesome5Icon light name="camera" color={primary} size={50} />
        <ItText
          underline
          size={14}
          style={{
            marginTop: 10,
          }}>
          Iniciar captura fotografía
        </ItText>
      </View>
    </Touchable>
  );
};

export default CampaignSelectImageCard;
