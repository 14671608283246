import {useRoute} from '@react-navigation/native';
import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import StyledCard from '../../../components/StyledCard';
import CampaignList from '../../campaings/components/CampaignList';
import NewsList from '../../news/NewsList';
import Tab from '../../ui/modules/tabs/Tab';
import TabsContainer from '../../ui/modules/tabs/TabsContainer';

export interface HomeTabsProps {}

const HomeTabs: React.FunctionComponent<HomeTabsProps> = () => {
  const route = useRoute<any>();
  const [activeTabIndex, setTabIndex] = React.useState(
    route.params?.activeTab || 0,
  );

  return (
    <StyledCard
      containerStyle={[
        styles.container,
        {
          padding: 0,
          elevation: activeTabIndex === 0 ? 0 : 1,
        },
      ]}>
      <TabsContainer>
        <Tab isActive={activeTabIndex === 0} onPress={() => setTabIndex(0)}>
          Retos
        </Tab>
        <Tab isActive={activeTabIndex === 1} onPress={() => setTabIndex(1)}>
          Noticias
        </Tab>
      </TabsContainer>
      <div style={{
	flex: 1,
	height: '100%',
      }} id="tabscon">
        {activeTabIndex === 0 && <CampaignList />}
        {activeTabIndex === 1 && <NewsList />}
      </div>
    </StyledCard>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 0,
    backgroundColor: '#F2F2F2',
    borderWidth: 0,
    maxHeight: 'calc(100vh - 70px)',
    height: '100%',
  },
  content: {
    backgroundColor: '#F2F2F2',
    height: '100%',
  },
});

export default HomeTabs;
