import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {secondary} from '../../../../theme/colors';

export interface OverlayIconProps {
  name: string;
}

const OverlayIcon: React.SFC<OverlayIconProps> = (props: OverlayIconProps) => {
  return (
    <View style={styles.icon}>
      <FontAwesome5Icon name={props.name} size={35} solid color="white" />
    </View>
  );
};

const styles = StyleSheet.create({
  icon: {
    backgroundColor: secondary,
    width: 90,
    height: 90,
    borderRadius: 45,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginBottom: 16,
  },
});

export default OverlayIcon;
