import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import {Avatar} from 'react-native-elements';
import {Block} from '../../../components/views';
import {Space} from '../../../theme';
import ItText from '../../ui/ItText';
import {ProfileInfo} from '../entities/ProfileInfo';

export interface AuthUserInfoViewProps {
  profile: ProfileInfo;
  subtitleElement?: React.ReactNode;
}

const AuthUserInfoView: React.FunctionComponent<AuthUserInfoViewProps> = ({
  profile,
  subtitleElement,
}) => {
  if (!profile) {
    return null;
  }
  return (
    <Block style={styles.container}>
      <View style={styles.avatarContainer}>
        {!!profile?.avatar && (
          <Avatar rounded size={72} source={{uri: profile?.avatar}} />
        )}
      </View>
      <View style={{flex: 1}}>
        <ItText style={{ color: '#FF801F'}} size={21} strong>
          {profile?.name}
        </ItText>
        {subtitleElement}
      </View>
    </Block>
  );
};

const styles = StyleSheet.create({
  avatarContainer: {
    marginRight: Space.LARGE,
  },
  container: {
    margin: Space.LARGE,
  },
});

export default AuthUserInfoView;
