// @ts-nocheck
import * as React from 'react';
import ReactDOM from 'react-dom';
import {
  CameraOptions,
  ImageLibraryOptions,
  ImagePickerResponse,
} from 'react-native-image-picker';
import Toast from 'react-native-root-toast-updated';
import ImagePickerCrop from 'react-native-image-crop-picker';
import Webcam from 'react-webcam';
import ImagePickerWebCamera from './ImagePickerWebCamera';

export default class ImagePicker {
  private readonly cameraOptions: CameraOptions = {
    mediaType: 'photo',
    maxWidth: 300,
    maxHeight: 300,
    quality: 0.5,
  };

  private readonly libraryOptions: ImageLibraryOptions = {
    mediaType: 'photo',
    maxWidth: 300,
    maxHeight: 300,
    quality: 0.5,
  };

  constructor(private onImageSelected: Function, private onError?: Function) {
    this.onImageSelected = onImageSelected;
    this.onError = onError;
  }

  private async requirePermissions(): Promise<boolean> {
    return true;
  }

  onResponse = (response: ImagePickerResponse): void => {
    if (response.errorCode) {
      if (this.onError) {
        this.onError(response.errorMessage);
        return;
      }
      Toast.show(response.errorMessage || 'Error completando la acción', {
        backgroundColor: 'red',
      });
    } else if (response.uri) {
      if (response.uri) {
        const photo = {
          uri: response.uri,
          type: response.type,
          name: response.fileName || 'file.jpg',
        };
        this.onImageSelected(photo);
      }
    }
  };

  async fromCamera(): Promise<void> {
    if (!(await this.requirePermissions())) {
      return;
    }
    this.fromCameraWithPicker();
  }

  async fromCameraWithPicker(): Promise<void> {
    ReactDOM.render(
      <ImagePickerWebCamera
        onChange={(file) => {
          this.onImageSelected(file);
          ReactDOM.unmountComponentAtNode(document.getElementById("camera"))
        }}
      ></ImagePickerWebCamera>,
      document.getElementById("camera")
    )
  }

  async fromLibrary(): Promise<void> {
    const input = document.createElement("input")
    input.type = "file";
    input.accept = "image/png, image/gif, image/jpeg"
    input.onchange = (event) => {
      const [photo] = event.target.files;
      this.onImageSelected(photo);
    }
    input.click()
  }
}
