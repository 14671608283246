import React from 'react'
import {FlatList, Image, View} from 'react-native';
import useFetch from 'use-http';
import _ from 'lodash';
import {ListItem} from 'react-native-elements';
import ItText from '../../ui/ItText';
import {useNavigation} from '@react-navigation/native';

interface RedemptionMethod {
      "id": number,
      "img": string,
      color: string,
      "name": String
}

const CATALOG_ID = 4;

export function LoyaltyMenu() {
  const navigation = useNavigation();
  const {data} = useFetch('/api/app/redemption/menu', []);
  const items = _.get(data, 'data', []);
  const config = _.get(data, 'config', []);

  return (
    <FlatList<RedemptionMethod>
      data={items}
      contentContainerStyle={{paddingBottom: 20}}
      ListHeaderComponent={() => (
	<ItText strong style={{
	  marginHorizontal: 15,
	  marginTop: 25,
	  marginBottom: 10
	}} size={18}>¿Como quiere redimir tus puntos?</ItText>
      )}
      renderItem={({item}) => (
	<ListItem
	  style={{
	    padding: 0
	  }}
	  onPress={() => {
	    if (item.id == CATALOG_ID) {
	      navigation.navigate('RedemptionCatalogScreen');
	      return;
	    }
	    navigation.navigate('RedemptionFormScreen', {
	      item: item,
	      config
	    })
	  }}
	>
	  <View style={{
	    alignItems: 'center',
	    paddingVertical: 20,
	    width: '100%',
	    backgroundColor: item.color
	  }}>
	    <img src={item.img} style={{height: 30}}/>
	  </View>
	</ListItem>
      )}
    >
    </FlatList>
  
  )
}

export default LoyaltyMenu;
