import React from 'react';
import {View, StyleSheet} from 'react-native';
import ItText from './ItText';
import {Overlay} from 'react-native-elements';
import {ItButton} from './buttons';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import Touchable from 'react-native-platform-touchable';
import {secondary} from '../theme/colors';
import {Block} from './views';

interface OverlayConfirmMessageProps {
  isOverlayOpen: boolean;
  title: string;
  text: string | React.ReactElement;
  onConfirm?: () => void;
  customIcon?: React.ReactElement;
  setIsOverlayOpen: (next: boolean) => void;
}
const OverlayConfirmMessage: React.FC<OverlayConfirmMessageProps> = ({
  isOverlayOpen,
  title,
  text,
  onConfirm,
  setIsOverlayOpen,
  customIcon,
}: OverlayConfirmMessageProps) => {
  return (
    <Overlay
      isVisible={isOverlayOpen}
      // @ts-ignore
      height="auto">
      <View style={styles.overlayContainer}>
        <Touchable
          style={styles.timesIcon}
          onPress={(): void => setIsOverlayOpen(false)}>
          <FontAwesome5Icon name="times" size={30} />
        </Touchable>
        {customIcon ? (
          customIcon
        ) : (
          <View
            style={{
              backgroundColor: secondary,
              width: 90,
              borderRadius: 45,
              height: 90,
              marginBottom: 20,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <FontAwesome5Icon name="check" color="white" size={35} />
          </View>
        )}
        <ItText
          style={{
            marginBottom: 10,
            fontSize: 18,
            fontWeight: 'bold',
            color: 'black',
            textAlign: 'center',
          }}>
          {title}
        </ItText>
        <ItText style={{marginBottom: 30, fontSize: 14, textAlign: 'center'}}>
          {text}
        </ItText>
        <Block>
          <ItButton
            type="solid"
            title="ACEPTAR"
            titleStyle={{
              fontWeight: 'bold',
            }}
            containerStyle={{
              flex: 1,
            }}
            buttonStyle={{
              width: '100%',
            }}
            onPress={(): void => {
              setIsOverlayOpen(false);
              setTimeout(() => {
                if (onConfirm) {
                  onConfirm();
                }
              }, 0);
            }}
          />
        </Block>
      </View>
    </Overlay>
  );
};

const styles = StyleSheet.create({
  overlayContainer: {
    paddingHorizontal: 23,
    marginVertical: 52,
    justifyContent: 'center',
    alignItems: 'center',
  },
  timesIcon: {
    position: 'absolute',
    top: -40,
    right: 10,
  },
});

export default OverlayConfirmMessage;
