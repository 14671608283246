import * as React from 'react';
import AsyncStorage from '@callstack/async-storage';
import {ScrollView, StyleSheet} from 'react-native';
import Onesignal from '../../../Onesignal';
import TYCValidator from '../../termsAndConditions/components/TYCValidator';
import HomeTabs from '../components/HomeTabs';
import HomeUserCard from '../components/HomeUserCard';
import {useDispatch} from 'react-redux';
import {getMe} from '../../auth/redux/authRedux';

const HomeScreen: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const getParam = (name: string) => {
    const url = new URL(window.location.href);
    return url.searchParams.get(name);
  };
  React.useEffect(() => {
    const param = getParam('token');
    (async () => {
        if (param) {
          console.log(param)
          await AsyncStorage.setItem('token', param);
          dispatch(getMe());
        } 
    })();
  }, []);
  return (
    <div id="cont">
      <TYCValidator />
      <Onesignal />
      <ScrollView style={styles.container}>
        <HomeUserCard />
        <HomeTabs />
      </ScrollView>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  search: {
    paddingHorizontal: 10,
  },
  inputWrapper: {
    borderColor: 'black',
  },
  inputContainer: {
    backgroundColor: '#F5F5F5',
    borderColor: '#F5F5F5',
    borderWidth: 1,
    borderRadius: 14,
    paddingHorizontal: 20,
  },
});

export default HomeScreen;
