import React from 'react';
import {ActivityIndicator, FlatList, RefreshControl} from 'react-native';
import CommunityPostCard from './CommunityPostCard';
import {produce} from 'immer';
import NetworkErrorView from '../../../components/NetworkErrorView';
import EmptyListComponent from '../../../components/EmptyListComponent';
import useFetch, {CachePolicies} from 'use-http';
import {primary} from '../../../theme';
import {useNavigation} from '@react-navigation/native';

const CommunityPostList = () => {
  const navigation = useNavigation();
  const [page, setPage] = React.useState(1);
  const [refreshing, setRefreshing] = React.useState(false);
  const eventColor = primary;

  const {error, loading: isLoading, cache, data = [], get} = useFetch(
    `/api/app/community?page=${page}&pageSize=20`,
    {
      data: [],
      persist: false,
      onNewData: (currentData, {data: newData}) => {
        if (page == 1) {
          return newData;
        }

        return produce(currentData, (draft: unknown[]) => {
          return draft.concat(newData);
        });
      },
      cachePolicy: CachePolicies.NO_CACHE,
    },
    [page],
  );

  React.useEffect(() => {
    navigation.addListener('focus', () => {
      get();
    });
  });

  const _reload = React.useCallback(async () => {
    cache.clear();
    if (page == 1) {
      get();
    } else {
      setPage(1);
    }
  }, [cache, page, get]);

  const _onEndReached = React.useCallback((): void => {
    setPage(page + 1);
  }, [page]);

  const _refresh = React.useCallback(async (): Promise<void> => {
    setRefreshing(true);
    await _reload();
    setRefreshing(false);
  }, [_reload]);

  const _keyExtractor = React.useCallback((item: any) => `${item.id}`, []);

  const _renderItem = React.useCallback(
    ({item}) => <CommunityPostCard item={item} onRemove={_reload} />,
    [_reload],
  );

  return (
    <>
      {isLoading && <ActivityIndicator size={20} color={eventColor} />}
      {error ? (
        <NetworkErrorView retryCallback={_reload} />
      ) : (
        <FlatList
          onEndReachedThreshold={0.5}
          onEndReached={_onEndReached}
          style={{
            flex: 1,
            marginBottom: 80
          }}
          initialNumToRender={1}
          contentContainerStyle={{paddingBottom: 100, height: 'calc(100vh - 130px)' }}
          keyExtractor={_keyExtractor}
          data={data}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={_refresh} />
          }
          ListEmptyComponent={
            <EmptyListComponent
              iconName="eye-slash"
              text="No se han realizado publicaciones"
            />
          }
          renderItem={_renderItem}
        />
      )}
    </>
  );
};

export default CommunityPostList;
