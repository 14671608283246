import * as React from 'react';
import {getCampaignLabelColor} from '../utils/getLabelColor';
import {getLabelExecutionCampaign} from '../utils/getLabelExecution';
import CommercialActionHeader from './CommercialActionHeader';

export interface CampaignHeaderProps {
  item: any;
}

const CampaignHeader: React.SFC<CampaignHeaderProps> = ({
  item,
}: CampaignHeaderProps) => {
  return (
    <CommercialActionHeader
      title={item.name}
      end={item.end}
      badgeColor={getCampaignLabelColor(item)}
      label={getLabelExecutionCampaign(item)}
    />
  );
};

export default React.memo(CampaignHeader);
