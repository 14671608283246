import {useNavigation} from '@react-navigation/native';
import * as React from 'react';
import {View} from 'react-native';
import styled from 'styled-components/native';
import {Title} from '../../../components/ItText';
import TopRoundedCard from '../../ui/modules/cards/TopRoundedCard';
import LoyaltyResumeCard from '../components/LoyaltyResumeCard';
import RedemtionHistoricList from '../modules/redemptions/components/RedemtionHistoricList';

export interface RedemptionHistoryScreenProps {}

const RedemptionHistoryScreen: React.FunctionComponent<RedemptionHistoryScreenProps> = () => {
  const navigation = useNavigation();
  return (
    <View
      style={{
        flex: 1,
      }}>
      <LoyaltyResumeCard
        linkLabel="Estado Puntos"
        linkIconName="gem"
        onPressLabel={() => {
          navigation.navigate('Logros');
        }}
      />
      <View
        style={{
          flex: 1,
        }}>
        <TopRoundedCard minHeight={400}>
          <StyledTitle>Historial Redenciones</StyledTitle>
          <RedemtionHistoricList />
        </TopRoundedCard>
      </View>
    </View>
  );
};

const StyledTitle = styled(Title)`
  margin-bottom: 20;
`;

export default RedemptionHistoryScreen;
