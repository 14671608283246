import _ from 'lodash';
import {
  Campaign,
  Challenge,
  CommercialAction,
} from '../entities/commercialAction';
import {wasExecutedCampaign, wasExecutedChallenge} from './wasExecuted';

export function getLabelExecutionCampaign(campaign: Campaign) {
  return wasExecutedCampaign(campaign) ? 'Aprobado' : 'Sin Ejecutar';
}

export function getLabelExecutionChallenge(challenge: Challenge) {
  if (!wasExecutedChallenge(challenge)) {
    return 'Sin Ejecutar';
  }
  return _.get(_.last(challenge.executions), 'audit_result.name');
}

export enum CommercialActionType {
  challenge,
  campaign,
}

export function getLabelForCommertialActionByType(
  commercialActionItem: Challenge | Campaign,
  type: CommercialActionType,
) {
  if (type == CommercialActionType.campaign) {
    return getLabelExecutionCampaign(commercialActionItem as Campaign);
  }
  return getLabelExecutionChallenge(commercialActionItem as Challenge);
}

const getLabelExecution = (item: CommercialAction): string => {
  const isCampaign = item.challenges.length == 0;
  if (isCampaign) {
    return getLabelExecutionCampaign(item.campaigns[0]);
  }
  return getLabelExecutionChallenge(item.challenges[0]);
};

export default getLabelExecution;
