import * as React from 'react';
import {View} from 'react-native';
import {InputProps} from 'react-native-elements';
import useFetch from 'use-http';
import {API_HOST} from '../../../env';
import ItText from '../../ui/ItText';
import Dropdown from '../../ui/modules/inputs/Dropdown';

export interface TypeDocumentPickerProps extends InputProps {
  country: string | number;
}

const TypeDocumentPicker: React.SFC<TypeDocumentPickerProps> = (
  props: TypeDocumentPickerProps,
) => {
  const {
    data = {},
  } = useFetch(`${API_HOST}/api/types/document/${props.country}`, {}, [
    props.country,
  ]);
  const {data: documentTypes = []} = data;
  const options = documentTypes.map((d: any) => ({
    label: d.name,
    value: d.id,
  }));

  if (!props.country || options.length === 0) {
    return null;
  }

  return (
    <View
      testID="type_document_picker"
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
      }}>
      <Dropdown {...props} items={options} />
      <ItText isErrorMessage>{props.errorMessage}</ItText>
    </View>
  );
};

export default TypeDocumentPicker;
