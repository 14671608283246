// @ts-nocheck
import {useNavigation} from '@react-navigation/native';
import * as React from 'react';
import {Image, StyleSheet, View} from 'react-native';
import {Button, Card, Divider} from 'react-native-elements';
import {ScrollView} from 'react-native-gesture-handler';
import ItText, {SectionTitle} from '../../../components/ItText';
import {primary} from '../../../theme/colors';

export interface SignupInstructionsProps {}

const SignupInstructions: React.SFC<SignupInstructionsProps> = () => {
  const navigation = useNavigation();
  return (
    <ScrollView testID="signup_instructions_view">
      <Card>
        <SectionTitle>Antes de Continuar</SectionTitle>
        <ItText>Ten en cuenta las siguientes recomendaciones</ItText>
        <View style={styles.infoContainer}>
          <ItText style={styles.title}>Código de cliente</ItText>
          <Image style={styles.image} source={require('../assets/code.png')} />
          <ItText style={styles.instructions}>
            Ten a mano tu código de cliente
          </ItText>
        </View>
        <Divider />
        <View style={styles.infoContainer}>
          <ItText style={styles.title}>Conexión a Internet</ItText>
          <Image style={styles.image} source={require('../assets/wifi.png')} />
          <ItText style={styles.instructions}>
            Asegurate de tener una buena conexión
          </ItText>
        </View>
        <Divider />
        <View style={styles.infoContainer}>
          <ItText style={styles.title}>Celular</ItText>
          <Image style={styles.image} source={require('../assets/phone.png')} />
          <ItText style={styles.instructions}>
            Por favor Acepta los permisos que te serán solicitados en el
            registro
          </ItText>
        </View>
        <Divider />
        <View style={styles.infoContainer}>
          <ItText style={styles.title}>Usuario único</ItText>
          <Image style={styles.image} source={require('../assets/user.png')} />
          <ItText style={styles.instructions}>
            Solo puedes crear un usuario
          </ItText>
        </View>
        <Button
          title="CONTINUAR"
          testID="go_to_form"
          style={{marginTop: 40}}
          onPress={() => {
            navigation.navigate('signupValidatePhone');
          }}
        />
        <Button
          title="YA TENGO UNA CUENTA"
          type="outline"
          onPress={() => {
            navigation.navigate('login');
          }}
          style={{
            marginBottom: 20,
            marginTop: 10,
          }}
        />
      </Card>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  title: {
    color: primary,
    textAlign: 'center',
  },
  infoContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 20,
  },
  image: {
    marginTop: 30,
  },
  instructions: {
    marginTop: 10,
    textAlign: 'center',
  },
});

export default SignupInstructions;
