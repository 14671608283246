import {useNavigation} from '@react-navigation/native';
import * as React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {ItBlockButtonOutline} from '../../../components/buttons';
import {primaryRgb, Space} from '../../../theme';
import ItText from '../../ui/ItText';
import ShopperInvitation from '../components/ShopperInvitation';

export interface ShopperCreateClientProps {}

const ShopperCreateClient: React.FunctionComponent<ShopperCreateClientProps> = () => {
  const navigation = useNavigation();
  return (
    <ScrollView
      contentContainerStyle={styles.container}
      style={styles.scrollView}>
      <View style={styles.share}>
        <ItText strong size={16} style={styles.title}>
          Crear Cliente
        </ItText>
        <ItText size={13} style={styles.subtitle}>
          Ingresa el número de celular del cliente para vincularlo.
        </ItText>
        <ShopperInvitation />
      </View>
      <View style={styles.setInfoContainer}>
        <ItText size={13} style={styles.setInfoTitle}>
          Ingresa datos para creación del cliente.
        </ItText>
        <ItBlockButtonOutline
          onPress={() => {
            navigation.navigate('ShopperCreateClientFormScreen');
          }}
          title="INFORMACIÓN CLIENTE"
        />
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: Space.SMALL,
    backgroundColor: 'white',
    paddingVertical: Space.SMALL,
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    marginBottom: Space.SMALL,
  },
  setInfoContainer: {
    backgroundColor: `rgba(${primaryRgb}, 0.04)`,
    marginVertical: Space.xl,
    paddingVertical: Space.LARGE,
    paddingHorizontal: Space.xl,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  subtitle: {
    marginBottom: Space.xl,
    textAlign: 'center',
    color: '#636363',
  },
  scrollView: {
    flex: 1,
  },
  share: {
    paddingHorizontal: Space.MEDIUM,
    width: '100%',
  },
  setInfoTitle: {
    color: '#012128',
    fontSize: 13,
    marginBottom: Space.SMALL,
  },
});

export default ShopperCreateClient;
