import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

export interface CopyIconProps {}

const CopyIcon: React.FunctionComponent<CopyIconProps> = (
  props: CopyIconProps,
) => {
  return (
    <Svg
      id="ic_content_copy_black_24px"
      width="21.419"
      height="19.8"
      viewBox="0 0 21.419 19.8">
      <Path
        id="Path_86"
        data-name="Path 86"
        d="M0,0H21.419V19.8H0Z"
        fill="none"
      />
      <Path
        id="Path_87"
        data-name="Path 87"
        d="M14.494,1H3.785A1.727,1.727,0,0,0,2,2.65V14.2H3.785V2.65H14.494Zm2.677,3.3H7.355A1.727,1.727,0,0,0,5.57,5.95V17.5a1.727,1.727,0,0,0,1.785,1.65h9.817a1.727,1.727,0,0,0,1.785-1.65V5.95A1.727,1.727,0,0,0,17.172,4.3Zm0,13.2H7.355V5.95h9.817Z"
        transform="translate(-0.215 -0.175)"
        fill="#049bbd"
      />
    </Svg>
  );
};

export default CopyIcon;
