import * as React from 'react';
import {View, TouchableOpacity} from 'react-native';
import {Input} from 'react-native-elements';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import styled from 'styled-components/native';
import {Block} from './views';

export interface AmountSelectorProps {
  amount: number | string;
  onChange: (value: string) => void;
}

const AmountSelector: React.FunctionComponent<AmountSelectorProps> = ({
  amount,
  onChange,
}: AmountSelectorProps) => {
  return (
    <Block
      style={{
        width: 100,
        alignItems: 'center',
        shadowColor: '#656565',
        backgroundColor: 'white',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
        justifyContent: 'center',
      }}>
      <TouchableOpacity
        onPress={() => {
          onChange(String(+amount - 1));
        }}>
        <AmountContainer>
          <FontAwesome5Icon size={14} name="minus" color="#656565" />
        </AmountContainer>
      </TouchableOpacity>
      <View
        style={{
          left: -10,
          width: 30,
          height: 30,
          marginHorizontal: 4,
        }}>
        <Input
          inputContainerStyle={{
            width: 30,
            height: 30,
            borderWidth: 0,
            borderColor: 'transparent',
          }}
          inputStyle={{
            fontSize: 14,
            textAlign: 'center',
          }}
          keyboardType="numeric"
          value={String(amount)}
          onChangeText={onChange}
        />
      </View>
      <TouchableOpacity
        onPress={() => {
          onChange(String(+amount + 1));
        }}>
        <AmountContainer>
          <FontAwesome5Icon name="plus" color="#656565" size={14} />
        </AmountContainer>
      </TouchableOpacity>
    </Block>
  );
};

export default AmountSelector;

const AmountContainer = styled(View)`
  width: 30;
  height: 30;
  align-items: center;
  justify-content: center;
`;
