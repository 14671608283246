// @ts-nocheck
import * as React from 'react';
import {ActivityIndicator} from 'react-native';
import Toast from 'react-native-root-toast-updated';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components/native';
import useFetch from 'use-http';
import {primary} from '../../../theme';
import {getMe, selectAuthenticatedUser} from '../../auth/redux/authRedux';
import ImageFromNetwork from '../../images/components/ImageFromNetwork';
import ItSelectImageTouchable from '../../images/components/ItSelectImageTouchable';

export interface EditableAvatarProps {}

const EditableAvatar: React.FunctionComponent<EditableAvatarProps> = () => {
  const user = useSelector(selectAuthenticatedUser);
  const dispatch = useDispatch();
  const {post, response, loading} = useFetch(
    `/api/app/pdv/profile_image/update/${user.id}`,
    {
      cacheLife: 1,
      headers: {
        Accept: 'application/json',
        /* 'Content-Type': 'multipart/form-data', */
      },
    },
  );
  return (
    <AvatarContainer>
      <ItSelectImageTouchable
        containerStyle={{
          width: 100,
          height: 100,
          borderRadius: 50,
        }}
        onImageSelected={async (r: any) => {
          const fd = new FormData();
          const file = r.uri ? await (await fetch(r.uri)).blob(): r;
          fd.append('file', file);
          await post(fd);
          if (response.ok) {
            Toast.show('Imágen Actualizada', {
              backgroundColor: 'green',
            });
            dispatch(getMe());
            return;
          }

          Toast.show('Error conectando con el servidor', {
            backgroundColor: 'red',
          });
        }}>
        <>
          <AvatarImage uri={user.avatar} />
          <EditBtn>
            <FontAwesome5Icon color="white" size={20} name="edit" />
          </EditBtn>
        </>
      </ItSelectImageTouchable>
      {loading && (
        <ActivityIndicator
          size="large"
          color={primary}
          style={{
            alignSelf: 'center',
            position: 'absolute',
          }}
        />
      )}
    </AvatarContainer>
  );
};

const AvatarImage = styled(ImageFromNetwork)`
  width: 100;
  height: 100;
  border-radius: 50;
  resize-mode: cover;
  aspect-ratio: 1;
  z-index: 1;
`;

const AvatarContainer = styled.View`
  align-self: center;
  width: 100;
  height: 100;
  margin-bottom: 30;
  align-items: center;
  justify-content: center;
`;

const EditBtn = styled.View`
  background-color: ${primary};
  width: 40;
  height: 40;
  z-index: 999;
  elevation: 2;
  aspect-ratio: 1;
  border-radius: 20;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export default EditableAvatar;
