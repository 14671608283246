import {useRoute} from '@react-navigation/native';
import * as React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {Card} from 'react-native-elements';
import HtmlPreview from '../../../components/HtmlPreview';
import {SectionTitle} from '../../../components/ItText';
import {Space} from '../../../theme';
import CampaignHeader from '../components/CampaignHeader';
import CampaignSelectImageCard from '../components/CampaignSelectImageCard';

export interface ChallengeExeProps {}

const ChallengeExe: React.FunctionComponent<ChallengeExeProps> = () => {
  const route = useRoute<any>();
  const {item} = route.params;

  return (
    <div style={{
      overflowY: 'scroll',
      maxHeight: 'calc(100vh - 60px)',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <CampaignHeader item={item} />
      <Card>
        <SectionTitle>¿Cómo debes ejecutar este reto?</SectionTitle>
        <View style={styles.html}>
          <HtmlPreview htmlContent={item.instructions} />
        </View>
        <CampaignSelectImageCard
          photos_quantity={item.photos_quantity}
          challengeId={item.id}
        />
      </Card>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: Space.BOTTOM_PADDING,
  },
  html: {
    marginTop: Space.SMALL,
    marginBottom: Space.SMALL,
  },
});

export default ChallengeExe;
