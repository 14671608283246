import * as React from 'react';

export interface OnesignalProps {
  
}
 
const Onesignal: React.FunctionComponent<OnesignalProps> = (props: OnesignalProps) => {
  return (
    <div></div>
  );
}
 
export default Onesignal;