// @ts-nocheck
import {useNavigation, useRoute} from '@react-navigation/native';
import {Formik} from 'formik';
import * as React from 'react';
import {Button, Card, Divider, Input} from 'react-native-elements';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5';
import {SectionTitle} from '../../../components/ItText';
import {primary} from '../../../theme/colors';
import codeVerificationRequestHandler from '../utils/codeVerificationRequestHandler';

export interface SignupCompleteProps {}

const SignupComplete: React.SFC<SignupCompleteProps> = () => {
  const route = useRoute<any>();
  const navigation = useNavigation();

  return (
    <Formik
      initialValues={{
        code: '',
      }}
      onSubmit={async (values, helpers) => {
        try {
          const {data} = await codeVerificationRequestHandler({
            prefix: route.params?.data?.prefix,
            phone: route.params?.data?.phone,
            code: values.code,
          });

          navigation.navigate('SignupVerification', {
            data: data,
          });
        } catch (ex) {
          helpers.setErrors({code: ex.message});
        }
      }}>
      {({
        values,
        handleChange,
        handleSubmit,
        touched,
        errors,
        isSubmitting,
      }) => (
        <Card>
          <SectionTitle>Paso 2 - Confirma el código</SectionTitle>
          <Divider style={{marginVertical: 20}} />
          <Input
            label="Código recibido"
            value={values.code}
            testID="code"
            onChangeText={handleChange('code')}
            keyboardType="phone-pad"
            errorMessage={touched.code ? errors.code : undefined}
            leftIcon={
              <FontAwesome5Icon
                name="comment-alt"
                color={primary}
                light
                size={20}
              />
            }
          />
          <Button
            testID="validate_code"
            loading={isSubmitting}
            title="Validar número"
            onPress={() => handleSubmit()}
          />
        </Card>
      )}
    </Formik>
  );
};

export default SignupComplete;
