import * as React from 'react';
import {ListItem} from 'react-native-elements';
import {Question} from '../entities/commercialAction';
import {getQuestionResponseText} from '../utils/getQuestionResponseText';

export interface CampaignQuestionProps {
  question: Question;
}

const CampaignQuestion: React.FunctionComponent<CampaignQuestionProps> = ({
  question,
}: CampaignQuestionProps) => {
  const response = getQuestionResponseText(question);
  return (
    <ListItem>
      <ListItem.Content>
        <ListItem.Title>{question.name}</ListItem.Title>
        <ListItem.Subtitle>R. {response}</ListItem.Subtitle>
      </ListItem.Content>
    </ListItem>
  );
};

export default CampaignQuestion;
