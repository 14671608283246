import * as React from 'react';
import useFetch from 'use-http';
import {API_HOST} from '../../../env';
import {ItTextProps} from '../../../components/ItText';
import ItText from '../../ui/ItText';
import _ from 'lodash';
import {useNavigation} from '@react-navigation/native';

export interface UserPointsProps extends Omit<ItTextProps, 'children'> {}

const UserPoints: React.FunctionComponent<UserPointsProps> = (
  props: UserPointsProps,
) => {
  const {data, get, loading} = useFetch(
    `${API_HOST}/api/app/my/current/points`,
    {
      cacheLife: 1,
    },
    [],
  );
  const navigation = useNavigation();

  React.useEffect(() => {
    get();
    const unsubscribe = navigation.addListener('focus', () => {
      get();
    });
    return unsubscribe;
  }, [get, navigation]);

  const total = _.get(data, 'data.total');
  return <ItText {...props}>{total ? total : loading ? '...' : 0}</ItText>;
};

export default React.memo(UserPoints);
