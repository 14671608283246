import React from 'react';
import {View} from 'react-native';
import {Button} from 'react-native-elements';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {primary} from '../theme/colors';
import {RectButton} from 'react-native-gesture-handler';

/* import HeartSVG from '../assets/skandia/heart.svg'; */

interface Props {
  isLiked: boolean;
  isSendingLike: boolean;
  handleLike: Function;
  setLikesCount: Function;
  likesCount: number;
  commentsEnabled?: boolean;
  setIsLiked: Function;
  handleComment?: any;
}

const CardWithInteractionsBar = ({
  isLiked,
  isSendingLike,
  handleLike,
  setLikesCount,
  likesCount,
  setIsLiked,
  handleComment,
  commentsEnabled = true,
}: Props): React.ReactElement => {
  const eventColor = primary;
  return (
    <View
      style={{
        flexDirection: 'row',
        /* marginHorizontal: -10,
        paddingHorizontal: 10,
        marginVertical: -10,
        paddingVertical: 7, */
        marginHorizontal: -10,
        paddingHorizontal: 10,
        paddingVertical: 5,
        bottom: -10,
        borderTopWidth: 3,
        borderTopColor: 'rgba(239, 239, 239, 0.87)',
        /* backgroundColor: 'red', */
        /* backgroundColor: 'rgba(239, 239, 239, 0.87)', */
      }}>
      <View style={{flexDirection: 'row'}}>
        <Button
          TouchableComponent={RectButton}
          type="clear"
          title="Me gusta"
          /* disabled={isLiked || isSendingLike} */
          disabled={isSendingLike}
          onPress={(_ev: any): void => {
            handleLike();
            setLikesCount(isLiked ? likesCount - 1 : likesCount + 1);
            setIsLiked(!isLiked);
          }}
          titleStyle={{
            color: eventColor,
            fontWeight: 'bold',
            fontSize: 14,
          }}
          icon={
            <FontAwesome5Icon
              name="heart"
              /* solid={isLiked || isSendingLike} */
              solid={!!isLiked}
              size={16}
              style={{marginRight: 10}}
              color={eventColor}
            />
          }
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'flex-end',
          flex: 1,
          justifyContent: 'flex-end',
        }}>
        {commentsEnabled && (
          <Button
            type="clear"
            title="comentar"
            onPress={handleComment}
            titleStyle={{
              color: eventColor,
              fontWeight: 'bold',
              fontSize: 14,
            }}
            icon={
              <FontAwesome5Icon
                name="comment-alt"
                /* solid={isLiked || isSendingLike} */
                size={16}
                style={{marginRight: 10}}
                color={eventColor}
              />
            }
          />
        )}
      </View>
    </View>
  );
};

export default CardWithInteractionsBar;
