import * as React from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import ShopperMenuItem from '../components/ShopperMenuItem';
import ShopperIcon from '../icons/ShopperIcon';

export interface ShoppersMenuScreenProps {}

const ShoppersMenuScreen: React.FunctionComponent<ShoppersMenuScreenProps> = (
  props: ShoppersMenuScreenProps,
) => {
  return (
    <ScrollView style={styles.container}>
      <ShopperMenuItem
        iconName="user-plus"
        title="CREAR UN CLIENTE"
        subtitle="Crea tus clientes"
        bgImage={require('../assets/user-plus.png')}
        routeName="ShopperCreateClient"
      />
      <ShopperMenuItem
        iconName="percent"
        title="CREAR UNA PROMOCIÓN"
        subtitle="CREAR UNA PROMOCIÓN"
        bgImage={require('../assets/percent.png')}
        routeName="OfferCreateFormScreen"
      />
      <ShopperMenuItem
        iconElement={<ShopperIcon />}
        title="ACTIVAR DOMICILIOS"
        subtitle="Activar domicilios"
        bgImage={require('../assets/shopper.png')}
        routeName="DeliveryActivateScreen"
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {},
});

export default ShoppersMenuScreen;
