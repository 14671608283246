// @ts-nocheck
import {useNavigation} from '@react-navigation/native';
import * as React from 'react';
import {Button, Overlay, OverlayProps} from 'react-native-elements';
import ItText from '../../ui/ItText';
import {
  OverlayBody,
  OverlayContent,
} from '../../ui/modules/overlays/OverlayHelpers';
import OverlayIcon from '../../ui/modules/overlays/OverlayIcon';

export interface SignupSuccessProps extends Omit<OverlayProps, 'children'> {}

const SignupSuccess: React.SFC<SignupSuccessProps> = (
  props: SignupSuccessProps,
) => {
  const navigation = useNavigation();
  return (
    <Overlay {...props}>
      <OverlayBody>
        <OverlayIcon name="check" />
        <OverlayContent>
          <ItText>Gracias por registrarte</ItText>
        </OverlayContent>
        <Button
          title="OK"
          onPress={() => {
            navigation.navigate('login');
          }}
        />
      </OverlayBody>
    </Overlay>
  );
};

export default SignupSuccess;
