// @ts-nocheck
import {useNavigation, useRoute} from '@react-navigation/native';
import * as React from 'react';
import {Alert, ScrollView, StyleSheet, View} from 'react-native';
import {Button, Card, Divider, Overlay} from 'react-native-elements';
import {API_HOST} from '../../../env';
import ItText from '../../ui/ItText';
import CampaignHeader from '../components/CampaignHeader';
import QuestionRenderer from '../components/QuestionRenderer';
import useFetch from 'use-http';
import {
  OverlayBody,
  OverlayContent,
  OverlayTitle,
} from '../../ui/modules/overlays/OverlayHelpers';
import OverlayIcon from '../../ui/modules/overlays/OverlayIcon';
import {Space} from '../../../theme';
import _ from 'lodash';
import Toast from 'react-native-root-toast-updated';
import {selectAuthenticatedUser} from '../../auth/redux/authRedux';
import {useSelector} from 'react-redux';

export interface CampaignExeProps {}

interface QuestionResponse {
  campaign_question_option_id: number;
  other_which_one: string;
  text: string;
}

const CampaignExe: React.SFC<CampaignExeProps> = () => {
  const route = useRoute<any>();
  const item = route.params.item;
  const {post, cache, loading, response} = useFetch(
    `${API_HOST}/api/app/campaign/execute/new`,
  );
  const [activeQuestionIndex, setActiveQuestionIndex] = React.useState(0);
  const [questionResponse, setQuestionResponse] = React.useState<
    QuestionResponse[]
  >([]);
  const [answers, setAnswers] = React.useState<any[]>([]);
  const user = useSelector(selectAuthenticatedUser);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const {questions = []} = item;
  const navigation = useNavigation();

  const activeQuestion = questions[activeQuestionIndex];

  const isWitchOneById = React.useCallback(
    (id: number) => {
      const witchOneOptions = _.filter(activeQuestion.options, {
        other_which_one: 1,
      }).map((option) => option.id);

      return witchOneOptions.indexOf(id) > -1;
    },
    [activeQuestion],
  );

  if (questions.length === 0) {
    return null;
  }

  return (
    <div style={{
    height: 'calc(100vh - 150px)',
    marginBottom: 100,
    overflowY: 'scroll'
    }}>
      <CampaignHeader item={item} />
      <Card>
        <View style={styles.headerContainer}>
          <ItText primary style={[styles.question, styles.textCenter]}>
            Pregunta {activeQuestionIndex + 1}/{questions.length}
          </ItText>
          <Divider style={styles.divider} />
          <ItText style={[styles.question]}>
            {questions[activeQuestionIndex]?.name}
          </ItText>
          <View style={styles.questionContainer}>
            <QuestionRenderer
              setAnswers={setQuestionResponse}
              answers={questionResponse}
              activeQuestion={activeQuestion}
            />
          </View>
          <View style={styles.question}>
            <Button
              title="SIGUIENTE"
              loading={loading}
              onPress={async (_ev: any) => {
                cache.clear();
                if (questionResponse.length === 0) {
                  Toast.show('Debes contestar esta pregunta', {
                    backgroundColor: 'red',
                  });
                  return;
                }

                const emptyWitchOne = questionResponse.find(
                  (qr: QuestionResponse) =>
                    isWitchOneById(qr.campaign_question_option_id) &&
                    qr.other_which_one === '',
                );

                if (emptyWitchOne) {
                  Toast.show(
                    'Debes ingresar tu respuesta en el campo de texto',
                    {
                      backgroundColor: 'red',
                      duration: Toast.durations.LONG,
                    },
                  );
                  return;
                }

                const nextAnswers = [
                  ...answers,
                  {
                    answers: questionResponse,
                    campaign_question_id: activeQuestion.id,
                  },
                ];
                setAnswers(nextAnswers);

                setQuestionResponse([]);
                const nextIndex = activeQuestionIndex + 1;

                if (nextIndex === questions.length) {
                  await post({
                    uuid: user.uuid,
                    comercial_action_id: item.comercial_action_id,
                    questions: nextAnswers,
                    isNew: true,
                  });
                  if (response.ok) {
                    setShowConfirmation(true);
                  } else {
                    Alert.alert('Error conectando con el servidor');
                  }
                  return;
                }
                setActiveQuestionIndex(nextIndex);
              }}
            />
          </View>
        </View>
      </Card>
      <Overlay
        isVisible={showConfirmation}
        onDismiss={() => setShowConfirmation(false)}>
        <OverlayBody>
          <OverlayIcon name="check" />
          <OverlayTitle>Tu encuesta se ha enviado exitosamente</OverlayTitle>
          <OverlayContent>
            Esté pendiente de los retos y complételos para acumular muchos
            puntos y reclamar premios increíles.
          </OverlayContent>
          <Button
            onPress={() => {
              setShowConfirmation(false);
              navigation.navigate('main', {
                screen: 'tabs.Retos',
              });
            }}
            title="ACEPTAR"
          />
        </OverlayBody>
      </Overlay>
    </div>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    paddingVertical: Space.SMALL,
  },
  question: {
    paddingHorizontal: Space.SMALL,
    marginBottom: Space.MEDIUM,
  },
  divider: {
    marginBottom: Space.MEDIUM,
  },
  questionContainer: {
    marginTop: 20,
    marginBottom: 50,
  },
  textCenter: {textAlign: 'right'},
});

export default CampaignExe;
