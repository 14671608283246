import {combineReducers} from 'redux';
import {authReducer} from '../modules/auth/redux/authRedux';
import {campaignImagesReducer} from '../modules/campaings/state/campaignImages/campaignImagesReducer';
import {shoppingCartReducer} from '../modules/shoppingCart/state/ShoppingCartState';

const rootReducer = combineReducers({
  auth: authReducer,
  shoppingCart: shoppingCartReducer,
  campaingsImages: campaignImagesReducer,
});

export default rootReducer;
