import * as React from 'react';
import {ActivityIndicator, FlatList, SectionList, StyleSheet, View} from 'react-native';
import Accordion from 'react-native-collapsible/Accordion';
import {RectButton, ScrollView} from 'react-native-gesture-handler';
import useFetch from 'use-http';
import {API_HOST} from '../../../../../env';
import EmptyListComponent from '../../../../../components/EmptyListComponent';
import {isCloseToBottom} from '../../../../../utils/isCloseToBottom';
import {Redemption} from '../entities/Redemption';
import RedemptionAccordionBody from './RedemptionAccordionBody';
import RedemptionAccordionHeader from './RedemptionAccordionHeader';
import _ from 'lodash';
import dayjs from 'dayjs';
import ItText from '../../../../ui/ItText';
import {Block} from '../../../../../components/views';

export interface RedemtionHistoricListProps {}

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const years = _.range(10).map(i => dayjs().subtract(i, 'year').format('YYYY'));

const RedemtionHistoricList: React.FunctionComponent<RedemtionHistoricListProps> = () => {
  const [page, setPage] = React.useState(1);
  const [lastPage, setLastPage] = React.useState(1);
  const [month, setMonth] = React.useState(new Date().getMonth() + 1);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const {loading, data = []} = useFetch<Redemption[]>(
    `${API_HOST}/api/app/redemptions/historic?month=${month}&year=${year}`,
      [year, month],
  );
  const redemptions = _.get(data, 'data', [])

  // @ts-ignore
  const groups = _.groupBy(redemptions, r => dayjs(r.date).fromNow())
  const sections = Object.keys(groups).map(k => ({title: k, data: groups[k]}))

  return (
    <>
      {loading && <ActivityIndicator color="black" size="large" />}
      <Block justifySpaceBetween style={{ marginBottom: 20}} >
	<View style={{ width: '47%'}}
	>
	  <ItText style={{ marginBottom: 5 }} size={10} strong>Año</ItText>
	  <select
	    style={{
	      color: 'black',
	      height: 40
	    }}
	    value={year}
	    onChange={(e) => {
	      // @ts-ignore
	      setYear(e.target.value)
	    }}
	  >
	    {years.map(y => <option value={y}>{y}</option>)}
	  </select>
	</View>

	<View style={{ width: '47%'}}
	>
	  <ItText style={{ marginBottom: 5 }} size={10} strong>Mes</ItText>

	  <select
	    style={{
	      color: 'black',
	      height: 40
	    }}
	    value={month}

	    onChange={(e) => {
	      // @ts-ignore
	      setMonth(e.target.value)}}
	  >
	    {months.map((y, index) => <option value={index + 1}>{y}</option>)}
	  </select>
	</View>

      </Block>

      <SectionList
	style={{
	  maxHeight: 300
	}}
	contentContainerStyle={{
	  paddingBottom: 100
	}}
	renderItem={({item}) => <View style={[styles.item, item.type == 'subtraction' && styles.subs]}>
	  <ItText style={{
	    color: item.type == 'subtraction' ? 'red' : 'green',
	    }} strong>{item.type == 'subtraction' ? '-' : '+'}{item.points} puntos</ItText>
	  <ItText >{item.description}</ItText>
	</View>}
	renderSectionHeader={({ section: { title } }) => (
	  <ItText style={styles.header}>{title}</ItText>
	)}
		sections={sections}
      >


      </SectionList>
    </>

  );
};

const styles = StyleSheet.create({
  header: {
    fontWeight: 'bold',
    marginVertical: 10,
    alignSelf: 'center'
  },
  item: {
    padding: 10,
    borderRadius: 10,
    backgroundColor: '#eee',
    maxWidth: '80%',
    marginBottom: 10
  },
  subs: {
    alignSelf: 'flex-end',
  }
})

export default RedemtionHistoricList;
