import {
  SetCategoriesAction,
  SetLastPageAction,
  SetMakersAction,
  SetNameAction,
  SetPageAction,
  SET_CATEGORIES,
  SET_LAST_PAGE,
  SET_MAKERS,
  SET_NAME,
  SET_PAGE,
  ToggleCategoryValueAction,
  ToggleMakerValueAction,
  TOGGLE_CATEGORY_VALUE,
  TOGGLE_MAKER_VALUE,
} from './redemptionListActionTypes';

export const setPage = (page: number): SetPageAction => {
  return {
    type: SET_PAGE,
    payload: {
      page,
    },
  };
};

export const toggleMakerValue = (value: number): ToggleMakerValueAction => {
  return {
    type: TOGGLE_MAKER_VALUE,
    payload: {
      value,
    },
  };
};

export const toggleCategoryValue = (
  value: number,
): ToggleCategoryValueAction => {
  return {
    type: TOGGLE_CATEGORY_VALUE,
    payload: {
      value,
    },
  };
};

export const setLastPage = (page: number): SetLastPageAction => {
  return {
    type: SET_LAST_PAGE,
    payload: {
      lastPage: page,
    },
  };
};

export const setName = (name: string): SetNameAction => {
  return {
    type: SET_NAME,
    payload: {
      name,
    },
  };
};

export const setMakers = (makersId: number[]): SetMakersAction => {
  return {
    type: SET_MAKERS,
    payload: {
      values: makersId,
    },
  };
};

export const setCategories = (categoriesId: number[]): SetCategoriesAction => {
  return {
    type: SET_CATEGORIES,
    payload: {
      values: categoriesId,
    },
  };
};
