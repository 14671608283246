// @ts-nocheck
import React from 'react';
import Touchable from 'react-native-platform-touchable';
import {ViewStyle, View} from 'react-native';
import {BottomSheet, ListItem} from 'react-native-elements';
import ImagePicker from '../libs/ImagePicker';

export interface ImageFile {
  uri: string;
  type: string | undefined;
  name: string;
}

export interface ItSelectImageTouchableProps {
  children: React.ReactNode;
  onImageSelected: (file: ImageFile) => void;
  onError?: (error: string) => void;
  containerStyle?: ViewStyle;
  disabled?: boolean;
}

const ItSelectImageTouchable = ({
  children,
  onImageSelected,
  onError,
  disabled = false,
  containerStyle,
}: ItSelectImageTouchableProps): React.ReactElement => {
  const [isVisible, setIsVisible] = React.useState(false);
  const pickerInstance = React.useMemo(
    () => new ImagePicker(onImageSelected, onError),
    [onImageSelected, onError],
  );
  return (
    <View style={containerStyle}>
      {/* <Loader loading={isSelecting} message="Seleccionando fotografía" /> */}
      <Touchable disabled={disabled} onPress={(): void => setIsVisible(true)}>
        {children}
      </Touchable>

      <BottomSheet
        modalProps={{}}
        isVisible={isVisible}
        containerStyle={{backgroundColor: 'rgba(0.5, 0.25, 0, 0.2)'}}>
        <ListItem
          onPress={(): void => {
            setIsVisible(false);

            pickerInstance.fromCameraWithPicker();
          }}>
          <ListItem.Content>
            <ListItem.Title>Tomar Fotografía</ListItem.Title>
          </ListItem.Content>
        </ListItem>
        <ListItem
          onPress={(): void => {
            setIsVisible(false);
            pickerInstance.fromLibrary();
          }}>
          <ListItem.Content>
            <ListItem.Title>Seleccionar de galería</ListItem.Title>
          </ListItem.Content>
        </ListItem>
      </BottomSheet>
    </View>
  );
};

export default ItSelectImageTouchable;
