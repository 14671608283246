import * as React from 'react';
import {Image} from 'react-native';

export default function useImageDimensions(uri: string) {
  const [state, setState] = React.useState({
    width: 0,
    height: 0,
    aspectRatio: 1,
  });

  React.useEffect(() => {
    Image.getSize(uri, (width, height) => {
      setState({
        width,
        height,
        aspectRatio: width / height,
      });
    });
  }, [uri, setState]);

  return state;
}
