import {useNavigation} from '@react-navigation/native';
import * as React from 'react';
import {ActivityIndicator, StyleSheet} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';
import {useDispatch} from 'react-redux';
import useFetch from 'use-http';
import {API_HOST} from '../../../../../env';
import EmptyListComponent from '../../../../../components/EmptyListComponent';
import {
  addItem,
  hideShoppingCart,
} from '../../../../shoppingCart/state/ShoppingCartAction';
import MediumButton from '../../../../ui/modules/buttons/MediumButton';
import RedemptionFilters from '../../../components/RedemptionFilters';
import {Product} from '../entities/Product';
import {
  setLastPage,
  setPage,
} from '../state/redemptionList/redemptionListActions';
import {
  initialState,
  RedemptionContext,
  redemptionReducer,
} from '../state/redemptionList/redemptionsListState';
import RedemptionListItem from './RedemptionListItem';
import RedemptionShoppingCart from './RedemptionShoppingCart';

export interface RedemptionsContainerProps {}

const RedemptionsContainer: React.FunctionComponent<RedemptionsContainerProps> = () => {
  const [state, dispatch] = React.useReducer(redemptionReducer, initialState);
  const reduxDispatch = useDispatch();
  const navigation = useNavigation();

  const {loading, data = [], post} = useFetch(
    `${API_HOST}/api/app/redemption/product/list?page=${state.page}&pageSize=10&name=${state.name}`,
    {
      method: 'post',
      body: {
        categories: state.categories,
        makers: state.makers,
      },
      onNewData: (curr = [], {data: newData, last_page}) => {
        dispatch(setLastPage(last_page));
        if (state.page == 1) {
          return newData;
        }
        return [...curr, ...newData];
      },
    },
    [state.page, state.name],
  );

  React.useEffect(() => {
    post({
      categories: state.categories,
      makers: state.makers,
    });
  }, [state.categories, state.makers, post]);

  React.useEffect(() => {
    return navigation.addListener('beforeRemove', () => {
      hideShoppingCart();
    });
  }, [navigation]);

  const _onEndReached = React.useCallback(() => {
    if (state.lastPage === state.page) {
      return;
    }
    dispatch(setPage(state.page + 1));
  }, [state.page, state.lastPage]);

  const _listEmpty = React.useCallback(
    () => (
      <EmptyListComponent
        iconName="gift"
        text="Aquí encontrarás los productos disponibles para redimir en cuanto estén disponibles"
      />
    ),
    [],
  );
  const _keyExtractor = React.useCallback((item) => String(item.id), []);
  const _renderItem = React.useCallback(
    ({item}: {item: Product}) => (
      <RedemptionListItem
        ActionElement={
          <MediumButton
            title="Redimir"
            onPress={() => {
              reduxDispatch(addItem(item.id, item));
            }}
          />
        }
        product={item}
      />
    ),
    [reduxDispatch],
  );

  const contextValue = React.useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch],
  );

  return (
    <RedemptionContext.Provider value={contextValue}>
      <RedemptionFilters />
      {loading ? (
        <ActivityIndicator
          color="black"
          size="large"
          style={styles.activityIndicator}
        />
      ) : (
        <FlatList
          scrollEnabled={false}
          nestedScrollEnabled
          data={data}
          contentContainerStyle={styles.contentContainerStyle}
          onEndReachedThreshold={0.7}
          onEndReached={_onEndReached}
          ListEmptyComponent={_listEmpty}
          keyExtractor={_keyExtractor}
          renderItem={_renderItem}
        />
      )}
      <RedemptionShoppingCart />
    </RedemptionContext.Provider>
  );
};

const styles = StyleSheet.create({
  contentContainerStyle: {
    paddingBottom: 50,
  },
  activityIndicator: {
    alignSelf: 'center',
  },
});

export default React.memo(RedemptionsContainer);
