import * as React from 'react';
import {View} from 'react-native';
import {primary, secondary} from '../../../theme/colors';
import ItText from '../../ui/ItText';
import UserPoints from './UserPoints';

export interface UserPointsBannerProps {}

const UserPointsBanner: React.FunctionComponent<UserPointsBannerProps> = () => {
  return (
    <View
      style={{
        backgroundColor: secondary,
        height: 40,
        alignItems: 'center',
        width: 140,
        position: 'absolute',
        right: 0,
        top: 20,
        borderTopLeftRadius: 15,
        flexDirection: 'row',
        justifyContent: 'center',
        borderBottomLeftRadius: 15,
      }}>
      <UserPoints white size={16} strong />
      <View
        style={{
          width: 1,
          backgroundColor: 'white',
          height: 30,
          marginHorizontal: 10,
        }}
      />
      <View style={{}}>
        <ItText
          style={{
            marginLeft: 20,
            fontWeight: 'bold',
          }}
          white>
          Sus
        </ItText>
        <ItText
          white
          style={{
            fontWeight: 'bold',
          }}>
          Puntos
        </ItText>
      </View>
    </View>
  );
};

export default UserPointsBanner;
