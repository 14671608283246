import * as React from 'react';
import useFetch from 'use-http';
import {API_HOST} from '../../../env';
import DropdownMultiple from '../../ui/modules/inputs/DropdownMultiple';
import {Category} from '../entities/Category';
import {toggleCategoryValue} from '../modules/redemptions/state/redemptionList/redemptionListActions';
import {RedemptionContext} from '../modules/redemptions/state/redemptionList/redemptionsListState';

export interface MakerDropdownProps {}

const CategoryDropdown: React.FunctionComponent<MakerDropdownProps> = () => {
  const {data = {}} = useFetch(
    `${API_HOST}/api/app/redemption/categories?page=1&pageSize=200`,
    {},
    [],
  );
  const {state, dispatch} = React.useContext(RedemptionContext);
  const categories = React.useMemo(() => {
    const {data: result = []} = data;

    return result.map((m: Category) => ({
      label: m.name,
      value: m.id,
    }));
  }, [data]);

  return (
    <DropdownMultiple
      placeholder="Categorías"
      items={categories}
      onToggle={(value: number) => dispatch(toggleCategoryValue(value))}
      value={state.categories}
    />
  );
};

export default React.memo(CategoryDropdown);
