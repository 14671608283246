export const SET_PAGE = 'SET_PAGE';
export const SET_LAST_PAGE = 'SET_LAST_PAGE';
export const SET_NAME = 'SET_NAME';
export const SET_MAKERS = 'SET_MAKERS';
export const TOGGLE_MAKER_VALUE = 'TOGGLE_MAKER_VALUE';
export const TOGGLE_CATEGORY_VALUE = 'TOGGLE_CATEGORY_VALUE';
export const SET_CATEGORIES = 'SET_CATEGORIES';

export interface SetPageAction {
  type: typeof SET_PAGE;
  payload: {
    page: number;
  };
}

export interface SetMakersAction {
  type: typeof SET_MAKERS;
  payload: {
    values: number[];
  };
}

export interface SetLastPageAction {
  type: typeof SET_LAST_PAGE;
  payload: {
    lastPage: number;
  };
}

export interface SetNameAction {
  type: typeof SET_NAME;
  payload: {
    name: string;
  };
}

export interface ToggleMakerValueAction {
  type: typeof TOGGLE_MAKER_VALUE;
  payload: {
    value: number;
  };
}

export interface ToggleCategoryValueAction {
  type: typeof TOGGLE_CATEGORY_VALUE;
  payload: {
    value: number;
  };
}

export interface SetCategoriesAction {
  type: typeof SET_CATEGORIES;
  payload: {
    values: number[];
  };
}

export type ActionTypes =
  | SetPageAction
  | SetLastPageAction
  | SetNameAction
  | SetMakersAction
  | ToggleMakerValueAction
  | SetCategoriesAction
  | ToggleCategoryValueAction;
