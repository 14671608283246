import {
  ADD_CAMPAIGN_IMAGE,
  CampaignImagesActionTypes,
  REMOVE_CAMPAIGN_IMAGE,
} from './campaignsImagesActionTypes';
import {File} from '../../../../entities/File';
import {produce} from 'immer';

export interface CampaignImagesState {
  campaigns: {
    [id: string]: {
      files: File[];
    };
  };
}

const initialState = {
  campaigns: {},
};

export function campaignImagesReducer(
  state: CampaignImagesState = initialState,
  action: any,
) {
  if (action.type === ADD_CAMPAIGN_IMAGE) {
    return produce(state, (draft: CampaignImagesState) => {
      if (!(action.payload.campaignId in draft.campaigns)) {
        draft.campaigns[action.payload.campaignId] = {
          files: [],
        };
      }
      const campaignImages = draft.campaigns[action.payload.campaignId];

      campaignImages.files.push(action.payload.file);
    });
  }
  if (action.type === REMOVE_CAMPAIGN_IMAGE) {
    return produce(state, (draft: CampaignImagesState) => {
      const campaignWasCreated = action.payload.campaignId in draft.campaigns;

      if (!campaignWasCreated) {
        draft.campaigns[action.payload.campaignId] = {
          files: [],
        };
      }

      draft.campaigns[action.payload.campaignId].files.splice(
        action.payload.imageIndex,
        1,
      );
    });
  }

  // @ts-ignore
  if (action.type == 'CAMPAIGNS/CLEAR_CAMPAIGN_IMAGES') {
     return produce(state, draft => {
       draft.campaigns[action.payload.campaignId] = {
          files: [],
      };
     })
  }
  return state;
}
