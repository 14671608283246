import * as React from 'react';
import { Dimensions, TouchableOpacity } from 'react-native';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import Webcam from 'react-webcam';

export interface ImagePickerWebCameraProps {
  onChange: any
}
 
const {width} = Dimensions.get('screen');
const ImagePickerWebCamera: React.FunctionComponent<ImagePickerWebCameraProps> = ({onChange}: ImagePickerWebCameraProps) => {
  const cameraRef = React.useRef<any>(null);
  const [facingMode, setFacingMode] = React.useState('environment');
  return (
    <div style={{
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background:'black',
      textAlign: 'center'
    }}>
      <Webcam
        ref={cameraRef}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          width: 420,
          height: 500,
	  facingMode: facingMode 
        }}
        style={{
          width: '100%',
          minHeight: 500
        }}
      ></Webcam>
      <div style={{
	display: 'flex',
	alignItems: 'center',
	position: 'relative',
	width: '100%'
      }}>
      <TouchableOpacity
        onPress={async () => {
          const uri = await cameraRef.current.getScreenshot({
            width: 420,
            height: 500,
          });
          fetch(uri)
            .then(res => res.blob())
            .then((blob) => onChange({
              name: 'demo.jpg',
              type: 'image/jpeg',
              uri: uri,
            }))
        }}
        style={{
	 top: 0,
          backgroundColor: '#4a4949',
          width: 60,
          marginVertical: 10,
	  left: width / 2,
	  // translatex -50%
	  transform: [{translateX: -30}],
          justifyContent: 'center',
          alignItems: 'center',
          height: 60,
          borderRadius: 30
        }}
      >
        <FontAwesome5Icon color="white" name="camera" size={30}></FontAwesome5Icon>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={async () => {
	 setFacingMode(facingMode === 'environment' ? 'user' : 'environment');
        }}
        style={{
	  right: 20,
          backgroundColor: '#4a4949',
	  position: 'absolute',
          width: 40,
          marginHorizontal: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          height: 40,
          borderRadius: 30
        }}
      >
        <FontAwesome5Icon color="white" name="sync" size={25}></FontAwesome5Icon>
      </TouchableOpacity>
      </div>
      
    </div>
  );
}
 
export default ImagePickerWebCamera;
