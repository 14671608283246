import * as React from 'react';
import LottieView from 'lottie-react-native';

export interface LoadingProps {
  show?: boolean;
  size?: number;
}

const Loading: React.SFC<LoadingProps> = ({size = 20}: LoadingProps) => {
  return (
    <LottieView
      style={{
        aspectRatio: 1,
        width: size + 100,
        marginHorizontal: -25,
        alignSelf: 'center',
      }}
      source={require('./loading.json')}
      autoPlay
      loop
    />
  );
};

export default Loading;
