// @ts-nocheck
import * as React from 'react';
import cond from 'lodash/cond';
import stubTrue from 'lodash/stubTrue';
import SingleResponse from './SingleResponse';
import MultipleResponse from './MultipleResponse';
import TextResponse from './TextResponse';

export interface QuestionRendererProps {
  activeQuestion: any;
  setAnswers: any;
  answers: {
    campaign_question_option_id: string;
    other_which_one: string;
    text: string;
  }[];
}

export interface QuestionComponentProps {
  question: any;
  setAnswers: any;
  answers: {
    campaign_question_option_id: number;
    other_which_one: string;
    text: string;
  }[];
}

enum responseTypes {
  SINGLE_RESPONSE = 1,
  MULTIPLE_RESPONSE = 2,
  TEXT = 3,
}

type ActiveQuestion = {
  type: {
    id: number;
  };
};

const QuestionRenderer: React.SFC<QuestionRendererProps> = (
  props: QuestionRendererProps,
) => {
  // eslint-disable-next-line no-spaced-func
  const Component = cond<
    ActiveQuestion,
    (props: QuestionComponentProps) => any
  >([
    [
      ({type}) => type.id == responseTypes.SINGLE_RESPONSE,
      () => SingleResponse,
    ],
    [
      ({type}) => type.id == responseTypes.MULTIPLE_RESPONSE,
      () => MultipleResponse,
    ],
    [({type}) => type.id == responseTypes.TEXT, () => TextResponse],
    [stubTrue, () => () => null],
  ])(props.activeQuestion);

  return (
    <Component
      question={props.activeQuestion}
      setAnswers={props.setAnswers}
      answers={props.answers || []}
    />
  );
};

export default QuestionRenderer;
