import {Formik} from 'formik';
import * as React from 'react';
import {useSelector} from 'react-redux';
import {selectAuthenticatedUser} from '../../auth/redux/authRedux';
import {ItButton} from '../../../components/buttons';
import {View} from 'react-native';
import {verticalScale} from 'react-native-size-matters';
import {InjectableFormSection} from '../../../entities/InjectableFormSection';
import Accordion from 'react-native-collapsible/Accordion';
import ProfileSectionHeader from './profileSections/ProfileSectionHeader';
import ProfileSectionBody from './profileSections/ProfileSectionBody';

export interface ProfileUpdateFormProps {
  sections: InjectableFormSection[];
  handleSubmit: (values: any) => Promise<any> | void;
}

const ProfileUpdateForm: React.FunctionComponent<ProfileUpdateFormProps> = (
  props,
) => {
  const [activeSections, setActiveSections] = React.useState<number[]>([0]);
  const user = useSelector(selectAuthenticatedUser);

  const renderHeader = React.useCallback(
    (section: InjectableFormSection) => (
      <ProfileSectionHeader section={section} />
    ),
    [],
  );
  const renderContent = React.useCallback(
    (section: InjectableFormSection) => (
      <ProfileSectionBody section={section} />
    ),
    [],
  );

  return (
    <>
      <Formik
        onSubmit={props.handleSubmit}
        enableReinitialize
        initialValues={{...user, prefix: '57'}}>
        {({isSubmitting, handleSubmit}) => (
          <>
            <Accordion
              activeSections={activeSections}
              sections={props.sections}
              renderHeader={renderHeader}
              renderContent={renderContent}
              onChange={setActiveSections}
            />
            <View
              style={{
                marginTop: verticalScale(20),
              }}>
              <ItButton
                onPress={() => handleSubmit()}
                disabled={isSubmitting}
                title={isSubmitting ? 'CARGANDO...' : 'ACTUALIZAR'}
                containerStyle={{
                  marginHorizontal: 10,
                }}
              />
            </View>
          </>
        )}
      </Formik>
    </>
  );
};

export default ProfileUpdateForm;
