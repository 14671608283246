import * as React from 'react';
import {View} from 'react-native';
import {Tooltip} from 'react-native-elements';
import {s} from 'react-native-size-matters';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import styled from 'styled-components/native';
import {Block} from '../../../../../components/views';
import {secondary} from '../../../../../theme';
import ItText from '../../../../ui/ItText';

export interface RedemptionPointStatusProps {
  value: number | string;
  label: string;
  explanation: string;
}

const RedemptionPointStatus: React.FunctionComponent<RedemptionPointStatusProps> = (
  props: RedemptionPointStatusProps,
) => {
  return (
    <Wrapper>
      <Block alignCenter justifyCenter>
        <Value>{props.value}</Value>
        <Tooltip
          height={80}
          skipAndroidStatusBar
          popover={
            <View>
              <ItText white>{props.explanation}</ItText>
            </View>
          }>
          <FontAwesome5Icon name="info-circle" size={19} color="#73797C" />
        </Tooltip>
      </Block>
      <Label>{props.label}</Label>
    </Wrapper>
  );
};

const Label = styled(ItText)`
  color: #73797c;
  text-align: center;
  font-size: ${s(11)};
`;

const Wrapper = styled.View`
  padding-horizontal: 20;
  flex: 1;
  min-width: 140;
  justify-content: center;
  padding-vertical: 20;
`;

const Value = styled(ItText)`
  color: ${secondary};
  font-weight: bold;
  font-size: ${s(19)};
  margin-right: ${s(10)};
`;

export default RedemptionPointStatus;
