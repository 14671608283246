import * as React from 'react';
import {StyleSheet, Dimensions} from 'react-native';
import {Card} from 'react-native-elements';

const {width} = Dimensions.get('screen');
export interface BottomHeaderProps {
  children: any;
}

const BottomHeader: React.SFC<BottomHeaderProps> = (
  props: BottomHeaderProps,
) => {
  return <Card containerStyle={styles.container}>{props.children}</Card>;
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    width: width * 0.8,
    marginHorizontal: width * 0.1,
    borderTopLeftRadius: 20,
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderTopRightRadius: 20,
    paddingBottom: 70,
  },
});

export default BottomHeader;
