import {File} from '../../../../entities/File';

export const ADD_CAMPAIGN_IMAGE = 'ADD_CAMPAIGN_IMAGE';
export const REMOVE_CAMPAIGN_IMAGE = 'REMOVE_CAMPAIGN_IMAGE';
export const CLEAR_CAMPAIGN_IMAGES = 'CLEAR_CAMPAIGN_IMAGES';

export interface AddCampaignImageAction {
  type: typeof ADD_CAMPAIGN_IMAGE;
  payload: {
    campaignId: number;
    file: File;
  };
}

export interface RemoveCampaignImageAction {
  type: typeof REMOVE_CAMPAIGN_IMAGE;
  payload: {
    campaignId: number;
    imageIndex: number;
  };
}

export interface ClearCampaignImagesAction {
  type: typeof CLEAR_CAMPAIGN_IMAGES;
}

export type CampaignImagesActionTypes =
  | AddCampaignImageAction
  | RemoveCampaignImageAction
  | ClearCampaignImagesAction;
