import _ from 'lodash';
import {audit_status} from '../entities/audits';
import {
  Campaign,
  Challenge,
  CommercialAction,
} from '../entities/commercialAction';

export function wasExecutedCampaign(campaign: Campaign) {
  const hasTextAnswers = _.get(campaign, 'questions[0].answers.length', 0) > 0;
  const hasOptionsAnswers = _.find(
    _.get(campaign, 'questions[0].options', []),
    (option) => option.answers.length > 0,
  );
  return hasTextAnswers || hasOptionsAnswers;
}

export function wasExecutedChallenge(challenge: Challenge) {
  return challenge.executions && challenge.executions.length > 0;
}

export function wasRejectedChallenge(challenge: Challenge) {
  return (
    wasExecutedChallenge(challenge) &&
    _.last(challenge.executions)?.audit_result_type_id == audit_status.REJECTED
  );
}

function wasExecuted(item: CommercialAction) {
  if (item.campaigns && item.campaigns.length > 0) {
    return wasExecutedCampaign(item.campaigns[0]);
  }

  if (item.challenges && item.challenges.length > 0) {
    return wasExecutedChallenge(item.challenges[0]);
  }
}

export default wasExecuted;
