import * as React from 'react';
import {API_HOST} from '../../../env';
import Tab from '../../ui/modules/tabs/Tab';
import TabsContainer from '../../ui/modules/tabs/TabsContainer';
import CampaignList from './CampaignList';

export interface CampaignListProps {}

const CampaignListTabs: React.SFC = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  return (
    <>
      <TabsContainer>
        <Tab isActive={activeTab == 0} onPress={() => setActiveTab(0)}>
          Disponibles
        </Tab>
        <Tab isActive={activeTab == 1} onPress={() => setActiveTab(1)}>
          Ejecutados
        </Tab>
      </TabsContainer>
      <CampaignList
        endpoint={
          activeTab == 1
            ? `${API_HOST}/api/app/comercial/action/my/own?page=1&pageSize=2000`
            : undefined
        }
      />
    </>
  );
};

export default React.memo(CampaignListTabs);
