export const ActivityIndicatorSizes = {
  LARGE: 50,
  MEDIUM: 30,
  SMALL: 10,
};

export const IconSizes = {
  LARGE: 36,
  MEDIUM: 20,
};

export const Space = {
  LARGE: 20,
  xl: 25,
  MEDIUM: 15,
  SMALL: 10,
  // Diamante
  BOTTOM_PADDING: 30,
};
