import * as React from 'react';
import {View} from 'react-native';
import {RectButton, RectButtonProperties} from 'react-native-gesture-handler';
import styled from 'styled-components/native';
import ItText from '../../../../components/ItText';
import {primary} from '../../../../theme';

export interface MediumButtonProps extends RectButtonProperties {
  title: string;
}

const MediumButton: React.FunctionComponent<MediumButtonProps> = ({
  title,
  ...props
}: MediumButtonProps) => {
  return (
    <RectButton
      {...props}
      rippleColor={primary}
      style={{
        width: 100,
      }}>
      <TouchableContainer>
        <BtnText>{title}</BtnText>
      </TouchableContainer>
    </RectButton>
  );
};

const TouchableContainer = styled(View)`
  border-color: ${primary};
  border-width: 1px;
  padding-horizontal: 10px;
  padding-vertical: 5px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
`;

const BtnText = styled(ItText)`
  color: ${primary};
  font-weight: bold;
`;

export default MediumButton;
