import * as React from 'react';
import {Button} from 'react-native-elements';
import {Alert} from 'react-native';
import {API_HOST} from '../../../env';
import AsyncStorage from '@callstack/async-storage';
import useFetch from 'use-http';
import {clearPhotos} from '../state/campaignImages/campaignImagesActions';
import {useDispatch} from 'react-redux';
import {useNavigation} from '@react-navigation/native';

export function CampaignsSendButton(props: {
  disabled?: boolean;
  challengeId: any;
  uuid: any;
  files: any[];
  setDisabled: (disabled: boolean) => void;
  setCompleted: (completed: boolean) => void;
}) {
  const {post, cache, loading: executing} = useFetch(
    `${API_HOST}/api/app/challenge/execute`,
    {},
    [],
  );
  const dispatch = useDispatch();
  const navigation = useNavigation();
  return (
    <Button
      title="ENVIAR RETO"
      disabled={props.disabled}
      buttonStyle={{
        marginTop: 30,
        width: 290,
      }}
      loading={executing}
      onPress={async () => {
        try {
          const {data: response} = await post('', {
            challenge_id: props.challengeId,
            uuid: props.uuid,
          });
          await Promise.all(
            props.files.map(async (r: any) => {
              const file = r.uri ? await (await fetch(r.uri)).blob(): r;
              props.setDisabled(true);
              cache.clear();

              try {
                const data = new FormData();
                data.append('file', file);
                const res = await fetch(
                  `${API_HOST}/api/app/challenge/execute/${response.id}/photo`,
                  {
                    method: 'post',
                    body: data,
                    headers: {
                      Accept: 'application/json',
                      Authorization: `Bearer ${await AsyncStorage.getItem(
                        'token',
                      )}`,
                    },
                  },
                );

                if (!res.ok) {
                  console.error('ERROR', await res.text());
                  throw new Error();
                }

                await res.json();
              } catch (ex) {
                console.error(ex);
                props.setDisabled(false);
              }
            }),
          );

          dispatch(clearPhotos(props.challengeId));
          navigation.goBack();
        } catch (ex) {
          Alert.alert('Error subiendo las fotografía');
        }

        props.setCompleted(true);
      }}
    />
  );
}
