import * as React from 'react';
import Toast from 'react-native-root-toast-updated';
import useFetch from 'use-http';
import {API_HOST} from '../../../../../env';
import ItText from '../../../../ui/ItText';
import MediumButton from '../../../../ui/modules/buttons/MediumButton';

export interface RedemptionDeliveryButtonProps {
  id: number;
}

const RedemptionDeliveryButton: React.FunctionComponent<RedemptionDeliveryButtonProps> = ({
  id,
}: RedemptionDeliveryButtonProps) => {
  const {loading, put, response} = useFetch(
    `${API_HOST}/api/app/redemption/confirm/delivery/${id}`,
  );
  const [hide, setHide] = React.useState(false);

  if (hide) {
    return null;
  }

  return (
    <>
      <ItText
        style={{
          marginBottom: 7,
        }}>
        Si ya lo recibiste presiona el siguiente botón
      </ItText>
      <MediumButton
        onPress={async () => {
          if (!loading) {
            await put();
            if (response.ok) {
              Toast.show('Reporte enviado, muchas gracias');
              setHide(true);
            } else {
              Toast.show('Error conectando con el servidor', {
                backgroundColor: 'red',
              });
            }
          }
        }}
        title={loading ? 'Enviando...' : 'Recibido'}
      />
    </>
  );
};

export default RedemptionDeliveryButton;
