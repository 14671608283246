import * as React from 'react';
import {
  ScreenContainer,
  JustifyCenterContainer,
  InputContainerView,
} from '../../../components/views';
import {Card} from 'react-native-elements';
import {Formik, FormikHelpers} from 'formik';
import * as yup from 'yup';
import {secondary} from '../../../theme/colors';
import {ItBlockButton} from '../../../components/buttons';
import {FaInputIcon} from '../../../components/icons';
import {ItInput} from '../../../components/input';
import Toast from 'react-native-root-toast-updated';
import ItText from '../../../components/ItText';
import Loader from '../../../components/Loader';
import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import {AuthStackParams} from '../../auth/entities/AuthStackParams';
import {validateTokenRequest} from '../api/validateTokenRequest';

interface Values {
  token: string;
}

type NavigationProp = StackNavigationProp<
  AuthStackParams,
  'ForgotPasswordTokenScreen'
>;
type RouteProps = RouteProp<AuthStackParams, 'ForgotPasswordTokenScreen'>;

const ForgotPasswordTokenScreen = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigation = useNavigation<NavigationProp>();
  const route = useRoute<RouteProps>();
  const email = route.params.email ?? '';
  const validationSchema = yup.object().shape({
    token: yup
      .string()
      .trim('No white spaces allowed')
      .required('Field Required'),
  });

  const submit = async (
    values: Values,
    helpers: FormikHelpers<Values>,
  ): Promise<void> => {
    setIsLoading(true);
    try {
      const jsonRes = await validateTokenRequest(
        values.token,
        email,
        (validationMsg) => helpers.setFieldError('token', validationMsg),
      );
      if (jsonRes) {
        Toast.show(`Tu contraseña temporal es ${jsonRes.data.tmp_pass}`, {
          duration: 20000,
        });
        navigation.navigate('login', {
          email,
          tmp_pass: jsonRes.data.tmp_pass,
        });
      }
    } catch (ex) {
      setIsLoading(false);
      Toast.show('Error connecting to server', {
        backgroundColor: 'red',
      });
    }
    setIsLoading(false);
  };
  return (
    <ScreenContainer>
      <Loader loading={isLoading} message="Cargando..." />
      <Card title="INGRESA EL CODIGO" titleStyle={{color: secondary}}>
        <ItText
          style={{
            marginBottom: 10,
          }}>
          Verifica tu correo <ItText strong>{email}</ItText> e ingresa el código
          a continuación
        </ItText>
        <Formik
          onSubmit={submit}
          initialValues={{
            token: '',
          }}
          validationSchema={validationSchema}>
          {({
            values,
            handleSubmit,
            errors,
            handleChange,
            handleBlur,
          }): React.ReactElement => {
            return (
              <>
                <InputContainerView>
                  <ItInput
                    errorMessage={errors.token}
                    value={values.token}
                    onBlur={handleBlur('token')}
                    autoCapitalize={'none'}
                    autoFocus
                    keyboardType="number-pad"
                    placeholder="Código de recuperación"
                    leftIcon={<FaInputIcon name="keyboard" />}
                    onChangeText={handleChange('token')}
                  />
                </InputContainerView>
                <JustifyCenterContainer>
                  <ItBlockButton
                    onPress={(): void => handleSubmit()}
                    title="Continuar"
                  />
                </JustifyCenterContainer>
              </>
            );
          }}
        </Formik>
      </Card>
    </ScreenContainer>
  );
};

ForgotPasswordTokenScreen.navigationOptions = {
  title: 'Restore password',
};

export default ForgotPasswordTokenScreen;
