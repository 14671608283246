import {Field, FieldProps} from 'formik';
import * as React from 'react';
import {Input} from 'react-native-elements';

export interface ProfileLastNameFieldProps extends FieldProps {}

const ProfileLastNameField: React.FunctionComponent<ProfileLastNameFieldProps> = (
  props: ProfileLastNameFieldProps,
) => {
  return (
    <Field {...props}>
      {({field, meta, form}: FieldProps) => (
        <Input
          value={field.value}
          onChangeText={form.handleChange(field.name)}
          accessibilityLabel="last_name"
          label="Apellidos:"
          errorMessage={meta.error}
          autoCapitalize="words"
        />
      )}
    </Field>
  );
};

export default ProfileLastNameField;
