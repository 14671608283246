// @ts-nocheck
import React from 'react';
import {View, StyleSheet} from 'react-native';
import ItText from './ItText';
import {Overlay} from 'react-native-elements';
import {ItRoundedButton} from './buttons';
import {s} from 'react-native-size-matters';
import Touchable from 'react-native-platform-touchable';
import {primary, secondary} from '../theme/colors';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {Space} from '../theme';

interface OverlayConfirmationProps {
  isOverlayOpen: boolean;
  hideCancel?: boolean;
  loading?: boolean;
  noCenter?: boolean;
  title: string;
  text?: string;
  iconName?: string;
  confirmText?: string;
  onConfirm: Function;
  setIsOverlayOpen: any,
  TextElement?: React.ReactNode;
}

const OverlayConfirmation: React.FC<OverlayConfirmationProps> = ({
  isOverlayOpen,
  title,
  text,
  loading,
  setIsOverlayOpen,
  onConfirm,
  iconName = 'question',
  confirmText = 'CONFIRMAR',
  hideCancel = false,
  noCenter,
  TextElement = null,
}: OverlayConfirmationProps) => {
  return (
    <Overlay isVisible={isOverlayOpen} height="auto">
      <View style={styles.overlayContainer}>
        <Touchable
          style={styles.timesIcon}
          onPress={(): void => setIsOverlayOpen(false)}>
          <FontAwesome5Icon name="times" color="black" size={30} />
        </Touchable>
        <View
          style={{
            backgroundColor: secondary,
            width: 90,
            height: 90,
            marginBottom: Space.SMALL,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 45,
          }}>
          <FontAwesome5Icon solid name={iconName} size={50} color="white" />
        </View>
        <ItText
          style={{
            marginBottom: Space.SMALL,
            width: 138,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
            color: 'black',
          }}>
          {title}
        </ItText>
        {!!text && (
          <ItText
            style={{
              marginBottom: 30,
              fontSize: 14,
              lineHeight: 20,
              textAlign: noCenter ? undefined : 'center',
            }}>
            {text}
          </ItText>
        )}
        <View>{TextElement}</View>
        <View
          style={{
            width: s(270),
          }}>
          <ItRoundedButton
            loading={loading}
            type="solid"
            title={confirmText}
            containerStyle={{
              width: '100%',
              marginBottom: Space.SMALL,
            }}
            titleStyle={{
              fontWeight: 'bold',
              fontSize: 14,
            }}
            buttonStyle={{
              marginHorizontal: 9,
              paddingVertical: 9,
              borderRadius: 10,
              paddingHorizontal: 13,
            }}
            onPress={(): void => {
              setIsOverlayOpen(false);
              setTimeout(() => {
                onConfirm();
              }, 0);
            }}
          />
          {!hideCancel && (
            <ItRoundedButton
              loading={loading}
              containerStyle={{
                width: '100%',
                marginBottom: Space.SMALL,
              }}
              titleStyle={{
                fontWeight: 'bold',
                fontSize: 14,
                color: primary,
              }}
              buttonStyle={{
                marginHorizontal: 9,
                paddingVertical: 9,
                borderRadius: 10,
                paddingHorizontal: 13,
                backgroundColor: 'transparent',
              }}
              type="outline"
              title="CANCELAR"
              onPress={(): void => setIsOverlayOpen(false)}
            />
          )}
        </View>
      </View>
    </Overlay>
  );
};

const styles = StyleSheet.create({
  overlayContainer: {
    paddingHorizontal: 23,
    paddingVertical: 52,
    justifyContent: 'center',
    alignItems: 'center',
  },
  timesIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
});

export default OverlayConfirmation;
