import {Formik} from 'formik';
import * as React from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {ItBlockButton} from '../../../components/buttons';
import {ItInputBox} from '../../../components/input';
import {secondary, Space} from '../../../theme';
import ItText from '../../ui/ItText';
import * as yup from 'yup';
import useFetch from 'use-http';
import OverlayConfirmation from '../../../components/OverlayConfirmation';
import {useNavigation} from '@react-navigation/core';

export interface ShopperCreateClientFormScreenProps {}

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  last_name: yup.string().required(),
  phone: yup.string().required(),
});

const ShopperCreateClientFormScreen: React.FunctionComponent<ShopperCreateClientFormScreenProps> = () => {
  const [showSuccess, setSuccess] = React.useState(false);
  const navigation = useNavigation();
  const {post: preregisterClient, response} = useFetch(
    '/api/app/shopper/user/pre/register',
    {
      cacheLife: 0,
    },
  );
  const _handleSubmit = React.useCallback(
    async (values: any) => {
      await preregisterClient(values);
      if (response.ok) {
        setSuccess(true);
      }
    },
    [preregisterClient, response.ok],
  );

  return (
    <ScrollView
      contentContainerStyle={styles.contentContainer}
      style={styles.scrollView}>
      <ItText textCenter strong size={16} style={styles.title}>
        Crear Cliente
      </ItText>
      <ItText size={13} textCenter style={styles.subtitle}>
        Ingresa el número de celular del cliente para vincularlo.
      </ItText>
      <Formik
        validationSchema={validationSchema}
        validateOnMount
        initialValues={{
          phone: '',
          name: '',
          last_name: '',
        }}
        onSubmit={_handleSubmit}>
        {({
          values,
          errors,
          handleChange,
          touched,
          isSubmitting,
          handleBlur,
          handleSubmit,
        }) => (
          <>
            <ItInputBox
              value={values.phone}
              placeholder="Teléfono"
              keyboardType="number-pad"
              errorMessage={touched.phone ? errors.phone : ''}
              onChangeText={handleChange('phone')}
              onBlur={handleBlur('phone')}
              leftIcon={
                <FontAwesome5Icon
                  name="phone"
                  color={secondary}
                  size={18}
                  light
                />
              }
            />
            <ItInputBox
              value={values.name}
              placeholder="Nombre Cliente"
              errorMessage={touched.name ? errors.name : ''}
              onChangeText={handleChange('name')}
              onBlur={handleBlur('name')}
              leftIcon={
                <FontAwesome5Icon
                  name="user"
                  color={secondary}
                  size={18}
                  light
                />
              }
            />
            <ItInputBox
              value={values.last_name}
              placeholder="Apellidos"
              errorMessage={touched.last_name ? errors.last_name : ''}
              onChangeText={handleChange('last_name')}
              onBlur={handleBlur('last_name')}
              leftIcon={
                <FontAwesome5Icon
                  name="user"
                  color={secondary}
                  size={18}
                  light
                />
              }
            />
            <ItBlockButton
              title="CREAR CLIENTE"
              onPress={() => handleSubmit()}
              loading={isSubmitting}
              disabledStyle={{
                backgroundColor: '#BAB9C2',
                borderColor: '#BAB9C2',
              }}
              buttonStyle={styles.button}
            />
          </>
        )}
      </Formik>
      <OverlayConfirmation
        hideCancel
        iconName="check"
        confirmText="ACEPTAR"
        title="Hecho"
        text="Se ha hecho el registro de tu cliente pidele que descargue la aplicación 'Shopper SuperVecino'"
        isOverlayOpen={showSuccess}
        setIsOverlayOpen={setSuccess}
        onConfirm={() => {
          navigation.goBack();
        }}
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    backgroundColor: 'white',
    alignItems: 'center',
    paddingHorizontal: Space.SMALL,
  },
  scrollView: {
    flex: 1,
    margin: Space.SMALL,
  },
  title: {
    marginBottom: Space.SMALL,
  },
  subtitle: {
    maxWidth: 200,
    marginBottom: Space.xl,
  },
  button: {
    borderRadius: 7,
    marginBottom: Space.xl,
  },
});

export default ShopperCreateClientFormScreen;
