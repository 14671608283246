// @ts-nocheck
import React from 'react';
import {ScreenContainer} from '../../../components/views';
import {Card} from 'react-native-elements';

import {View} from 'react-native';
import {Formik} from 'formik';
import ItText from '../../../components/ItText';
import ItImagePickerBanner from '../../images/components/ItImagePickerBanner';
import {ItInputBox} from '../../../components/input';
import {ItButton} from '../../../components/buttons';
import {primary} from '../../../theme/colors';
import Toast from 'react-native-root-toast-updated';
import OverlayConfirmMessage from '../../../components/OverlayConfirmMessage';
import * as yup from 'yup';
import useFetch from 'use-http';
import {useSafeArea} from 'react-native-safe-area-context';
import {API_HOST} from '../../../env';
import {useNavigation} from '@react-navigation/native';

type NewCommunityPublishScreenProps = {};

const NewCommunityPublishScreen = (props: NewCommunityPublishScreenProps) => {
  const [cImage, setCImage] = React.useState();
  const [isSuccessOverlayOpen, setisSuccessOverlayOpen] = React.useState(false);
  const eventColor = primary;
  const navigation = useNavigation();

  const validationSchema = yup.object().shape({
    text: yup
      .string()
      .required('Debes especificar un texto para tu publicación')
      .max(150, '150 caracteres como máximo'),
  });
  const {post: handlePublish, loading: isLoading, response} = useFetch(
    `${API_HOST}/api/app/community`,
    {
      cacheLife: 1,
      headers: {
        Accept: 'application/json',
      },
      method: 'POST',
    },
  );
  const {bottom} = useSafeArea();

  return (
    <>
      <ScreenContainer
        style={{flex: 1, marginBottom: bottom}}
        contentContainerStyle={{
          flex: 1,
        }}>
        <Card
          title={
            <ItText
              strong
              style={{
                color: 'rgba(63, 63, 63, 0.87)',
                fontSize: 14,
                fontWeight: '500',
                marginBottom: 19,
              }}>
              Qué está pasando?
            </ItText>
          }>
          <Formik
            initialValues={{
              text: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              const fd = new FormData();
              if (cImage) {
                // @ts-ignore
		const file = cImage.uri ? await (await fetch(cImage.uri)).blob(): r;
                fd.append('photo', file);
              }
              fd.append('name', values.text);
              fd.append('text', values.text);
              // @ts-check
              /* fd.append('event_id', activeEvent.id); */
              await handlePublish(fd);
              if (response.ok) {
                /* setisSuccessOverlayOpen(true); */
                Toast.show(
                  'Tu publicación se ha enviado para ser revisada antes de ser publicada.',
                  {
                    backgroundColor: 'green',
                    duration: Toast.durations.LONG,
                  },
                );
                navigation.navigate('Community');
                /* setisSuccessOverlayOpen(true); */
              } else {
                Toast.show('Error publicando', {
                  backgroundColor: 'red',
                  duration: Toast.durations.LONG,
                });
              }
            }}>
            {({
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              errors,
              touched,
            }) => (
              <>
                <ItImagePickerBanner
                  onImageSelected={(file) => {
                    // @ts-ignore
                    setCImage(file);
                  }}
                />
                <ItInputBox
                  containerStyle={{marginTop: 9, marginBottom: 32}}
                  placeholder="Escribe aquí (150 caracteres)"
                  multiline
                  value={values.text}
                  errorMessage={touched.text ? errors.text : undefined}
                  onChangeText={handleChange('text')}
                  numberOfLines={3}
                  onBlur={handleBlur('text')}
                />
                <View
                  style={{
                    width: 148,
                    alignSelf: 'center',
                    marginBottom: 32,
                  }}>
                  <ItButton
                    disabled={isLoading}
                    loading={isLoading}
                    onPress={() => handleSubmit()}
                    buttonStyle={{backgroundColor: eventColor}}
                    title="PUBLICAR"
                  />
                </View>
              </>
            )}
          </Formik>
          <ItText style={{color: '#3F3F3F', fontSize: 12}}>
            Comparte aquí lo que está pasando con todos los participantes.
            Siéntete libre de compartir lo que quieras, se respetuoso.
          </ItText>
          <ItText style={{color: '#3F3F3F', fontSize: 12, marginTop: 12}}>
            Puedes incluir una foto y emoticons.
          </ItText>
        </Card>
      </ScreenContainer>
      <OverlayConfirmMessage
        onConfirm={() => {
          // @ts-ignore
          props.navigation.navigate('CommunityScreen');
        }}
        setIsOverlayOpen={setisSuccessOverlayOpen}
        isOverlayOpen={isSuccessOverlayOpen}
        text="Se ha realizado la publicación"
        title="Publicado"
      />
    </>
  );
};

NewCommunityPublishScreen.navigationOptions = ({
  navigation,
}: {
  navigation: any;
}) => ({
  headerStyle: {
    // @ts-ignore
    backgroundColor: navigation.getParam('eventColor', primary),
  },
  title: 'Nueva Publicación',
});

export default NewCommunityPublishScreen;
