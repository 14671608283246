import {useRoute} from '@react-navigation/native';
import * as React from 'react';
import Tab from '../../ui/modules/tabs/Tab';
import TabsContainer from '../../ui/modules/tabs/TabsContainer';
import RedemptionsContainer from '../modules/redemptions/components/RedemptionsContainer';
import LoyaltyInstructionsContainer from './LoyaltyInstructionsContainer';
import _ from 'lodash';
import LoyaltyMenu from './LoyaltyMenu';

export interface LoyaltyTabsProps {}

export enum validLoyaltyTabs {
  instructions = 1,
  redemption = 2,
}

const LoyaltyTabs: React.FunctionComponent<LoyaltyTabsProps> = () => {
  const route = useRoute();
  const [activeTab, setActiveTab] = React.useState<validLoyaltyTabs>(
    _.get(route, 'params.activeTab', validLoyaltyTabs.instructions),
  );

  React.useEffect(() => {
    setActiveTab(
      _.get(route, 'params.activeTab', validLoyaltyTabs.instructions),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params]);

  return (
    <>
      <TabsContainer>
        <Tab
          onPress={() => setActiveTab(validLoyaltyTabs.instructions)}
          isActive={activeTab === validLoyaltyTabs.instructions}>
          Ganar puntos
        </Tab>
        <Tab
          onPress={() => setActiveTab(validLoyaltyTabs.redemption)}
          isActive={activeTab === validLoyaltyTabs.redemption}>
          Redimir puntos
        </Tab>
      </TabsContainer>
      {activeTab === validLoyaltyTabs.instructions && (
        <LoyaltyInstructionsContainer />
      )}
      {activeTab === validLoyaltyTabs.redemption && <LoyaltyMenu />}
    </>
  );
};

export default LoyaltyTabs;
