import * as React from 'react';
import {Button} from 'react-native-elements';
import Toast from 'react-native-root-toast-updated';
import useFetch from 'use-http';
import {API_HOST} from '../../../env';
import {ItUser} from '../../../store';

export interface RequestMeikoCodeProps {
  values: ItUser;
}

const RequestMeikoCode: React.FunctionComponent<RequestMeikoCodeProps> = ({
  values,
}: RequestMeikoCodeProps) => {
  const {
    post: requestMeikoIdRequest,
    loading: requestMeikoLoading,
    cache: requestMeikoIdCache,
  } = useFetch(`${API_HOST}/api/oauth/pdv/request/meiko/id`);
  const requireCode = React.useCallback(async () => {
    requestMeikoIdCache.clear();
    try {
      const res = await requestMeikoIdRequest(values);

      if (!res.ok) {
        if (res.code === 409) {
          throw Error(res.message);
        }
        throw new Error('Error conectando con el servidor');
      }
      Toast.show('Se ha enviado tu solicitud pronto recibirás tu código', {
        backgroundColor: 'green',
      });
    } catch (ex) {
      Toast.show(ex.message, {
        backgroundColor: 'red',
      });
    }
  }, [requestMeikoIdCache, requestMeikoIdRequest, values]);

  return (
    <Button
      disabled={requestMeikoLoading}
      title={requestMeikoLoading ? 'Cargando...' : 'Solicitar un código'}
      onPress={requireCode}
      type="outline"
      containerStyle={{
        position: 'absolute',
        bottom: 0,
        marginHorizontal: 4,
        width: '100%',
        alignSelf: 'center',
      }}
    />
  );
};

export default RequestMeikoCode;
