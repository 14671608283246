import {Formik} from 'formik';
import * as React from 'react';
import {View} from 'react-native';
import {Divider, Input, Overlay} from 'react-native-elements';
import {BorderlessButton, ScrollView} from 'react-native-gesture-handler';
import Toast from 'react-native-root-toast-updated';
import {vs, s} from 'react-native-size-matters';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components/native';
import useFetch from 'use-http';
import {API_HOST} from '../../../../../env';
import {
  ItBlockButtonOutline,
  ItButton,
} from '../../../../../components/buttons';
import {Block} from '../../../../../components/views';
import {lightText, primary} from '../../../../../theme';
import {selectAuthenticatedUser} from '../../../../auth/redux/authRedux';
import ItText from '../../../../ui/ItText';
import TopRoundedCard from '../../../../ui/modules/cards/TopRoundedCard';
import RedemptionListItem from './RedemptionListItem';
import UserPoints from '../../../../home/components/UserPoints';
import {
  selectIsShoppingCartOpen,
  selectShoppingCartStatus,
} from '../../../../shoppingCart/state/ShoppingCartSelectors';
import {
  removeAllProducts,
  removeProduct,
  setAmount,
  toggleShoppingCart,
} from '../../../../shoppingCart/state/ShoppingCartAction';
import AmountSelector from '../../../../../components/AmountSelector';
import RedemptionOverlaySuccess from './RedemptionOverlaySuccess';

export interface RedemptionShoppingCartProps {}

const RedemptionShoppingCart: React.FunctionComponent<RedemptionShoppingCartProps> = () => {
  const {response, post} = useFetch(`${API_HOST}/api/app/redemption`, {
    method: 'post',
    cacheLife: 1,
  });
  const user = useSelector(selectAuthenticatedUser);
  const addressRef = React.useRef<any>(null);

  const dispatch = useDispatch();
  const isOpenShoppingCart = useSelector(selectIsShoppingCartOpen);
  const {total, products} = useSelector(selectShoppingCartStatus);
  const [success, setSuccess] = React.useState(false);
  return (
    <>
      <Overlay
        isVisible={isOpenShoppingCart}
        onBackdropPress={() => {
          dispatch(toggleShoppingCart());
        }}
        overlayStyle={{
          position: 'absolute',
          bottom: -10,
          borderRadius: 0,
          backgroundColor: 'transparent',
          borderWidth: 0,
          left: 0,
          right: 0,
          borderColor: 'transparent',
          elevation: 0,
        }}>
        <Container>
          <TopRoundedCard minHeight={0}>
            <Section justifySpaceBetween>
              <PointsContainer>
                <DescriptionText color={lightText}>
                  Sus puntos disponibles
                </DescriptionText>
                <PointsBanner outline>
                  <PointsText color={lightText}>
                    <UserPoints /> Pts
                  </PointsText>
                </PointsBanner>
              </PointsContainer>
              <PointsContainer>
                <DescriptionText>Puntos a redimir</DescriptionText>
                <PointsBanner>
                  <PointsText>{total} Pts</PointsText>
                </PointsBanner>
              </PointsContainer>
            </Section>
            <Divider />
            <ScrollView
              style={{
                maxHeight: 220,
              }}>
              {products.map((product) => (
                <RedemptionListItem
                  product={product.product}
                  handleRemove={() => {
                    dispatch(removeProduct(product.id));
                  }}
                  ActionElement={
                    <AmountSelector
                      amount={product.quantity}
                      onChange={(n: string) => {
                        const amount = parseInt(n);
                        if (amount > -1) {
                          if (amount == 0) {
                            dispatch(removeProduct(product.id));
                          } else {
                            dispatch(setAmount(product.id, amount));
                          }
                        }
                      }}
                    />
                  }
                />
              ))}
            </ScrollView>
            <Formik
              initialValues={{
                address: user.address,
              }}
              validate={({address}) => {
                const errors: any = {};
                if (!address) {
                  errors.address = 'Especifica una dirección';
                }
                return errors;
              }}
              onSubmit={async (values) => {
                await post({
                  address: values.address,
                  products: products.map((p) => ({
                    product_id: p.id,
                    quantity: p.quantity,
                  })),
                });
                if (!response.ok) {
                  if (response.data.errors) {
                    Toast.show(response.data.message, {
                      backgroundColor: 'red',
                    });
                    return;
                  }
                  Toast.show('Error conectando con el servidor', {
                    backgroundColor: 'red',
                  });
                  dispatch(toggleShoppingCart());
                  return;
                }
                dispatch(removeAllProducts());
                setSuccess(true);
              }}>
              {({
                values,
                handleChange,
                handleBlur,
                errors,
                handleSubmit,
                isSubmitting,
              }) => (
                <>
                  <UserInformation>
                    <Title>Sus datos</Title>
                    <DataRow>
                      <Icon name="user" />
                      <UserName>{user.name}</UserName>
                    </DataRow>
                    <DataRow
                      style={{
                        marginHorizontal: -10,
                      }}>
                      <AddressInput
                        leftIcon={<Icon name="map-marker-alt" />}
                        rightIcon={
                          <BorderlessButton
                            style={{}}
                            onPress={() => {
                              if (addressRef.current !== null) {
                                addressRef.current!.focus();
                              }
                            }}>
                            <Block>
                              <ItText primary>Cambiar Dirección</ItText>
                            </Block>
                          </BorderlessButton>
                        }
                        ref={addressRef}
                        value={values.address}
                        onChangeText={handleChange('address')}
                        onBlur={handleBlur('address')}
                        errorMessage={errors.address}
                      />
                    </DataRow>
                  </UserInformation>
                  <ItButton
                    title="CONFIRMAR"
                    loading={isSubmitting}
                    onPress={() => handleSubmit()}
                  />
                  <ItBlockButtonOutline
                    containerStyle={{
                      marginTop: vs(7),
                    }}
                    title="AGREGAR MÁS PRODUCTOS"
                    loading={isSubmitting}
                    onPress={() => dispatch(toggleShoppingCart())}
                  />
                </>
              )}
            </Formik>
          </TopRoundedCard>
        </Container>
      </Overlay>
      <RedemptionOverlaySuccess isVisible={success} setVisible={setSuccess} />
    </>
  );
};

const DataRow = styled(Block)`
  padding-vertical: ${vs(5)};
`;

const Icon = styled(FontAwesome5Icon).attrs((props) => ({
  size: s(14),
  light: true,
  color: props.color ?? '#1C1C1C',
}))``;

const UserName = styled(ItText)`
  color: #1c1c1c;
  margin-left: 16;
  font-size: ${s(14)};
`;

const AddressInput = styled(Input).attrs({
  placeholder: 'Direccion',
  containerStyle: {
    padding: 0,
  },
  inputContainerStyle: {
    borderColor: 'transparent',
    margin: 0,
    flex: 1,
    padding: 0,
  },
})`
  color: #1c1c1c;
  margin-left: ${vs(10)};
  font-size: ${s(14)};
`;

const Title = styled(ItText)`
  font-weight: bold;
  font-size: ${s(16)};
  margin-bottom: ${vs(3)};
`;

const UserInformation = styled(View)`
  margin-top: 20px;
`;

const Section = styled(Block)`
  margin-bottom: ${vs(16)};
`;

export default RedemptionShoppingCart;

const Container = styled.View`
  width: 100%;
`;

const PointsBanner = styled.View<{
  outline?: boolean;
}>`
  background: ${(props) => (props.outline ? 'white' : primary)};
  border-color: ${(props) => (props.outline ? lightText : primary)};
  border-width: 2;
  align-items: center;
  justify-content: center;
  border-radius: 17;
  height: ${s(41)};
  width: ${s(120)};
`;

const PointsText = styled(ItText)<{
  color?: string;
}>`
  color: ${(props) => props.color ?? 'white'};
  font-size: ${s(24)};
  font-weight: bold;
`;

const DescriptionText = styled(ItText)<{
  color?: string;
}>`
  font-size: 14;
  text-align: center;
  max-width: 100;
  margin-bottom: 7;
  color: ${(props) => props.color ?? primary};
`;

const PointsContainer = styled.View`
  justify-content: center;
  align-items: center;
`;
