import React from 'react';
import {View, StyleSheet} from 'react-native';
import ItSelectImageTouchable, {
  ItSelectImageTouchableProps,
} from '../../images/components/ItSelectImageTouchable';
import ItText from '../../../components/ItText';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {Image} from 'react-native-elements';

type IProps = Partial<ItSelectImageTouchableProps>;

const ItImagePickerBanner = (props: IProps) => {
  const [previewUrl, setPreviewUrl] = React.useState();

  return (
    <View>
      {!previewUrl && (
        <ItSelectImageTouchable
          {...props}
          onImageSelected={(file: any) => {
            setPreviewUrl(file.uri);
            if (props.onImageSelected) {
              props.onImageSelected(file);
            }
          }}>
          <View style={styles.container}>
            <FontAwesome5Icon
              name="camera"
              size={38}
              style={{marginBottom: 13}}
              color="white"
            />
            <ItText white size={14} strong style={{marginBottom: 9}}>
              Agrega una foto
            </ItText>
            <ItText white light size={12}>
              (Opcional)
            </ItText>
          </View>
        </ItSelectImageTouchable>
      )}
      {!!previewUrl && (
        <Image
          style={{
            width: '100%',
            aspectRatio: 1.9,
            resizeMode: 'cover',
          }}
          source={{
            uri: previewUrl,
          }}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(135, 135, 135, 0.48)',
    width: '100%',
    aspectRatio: 2.3,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
export default ItImagePickerBanner;
