import * as React from 'react';
import {
  ScreenContainer,
  JustifyCenterContainer,
  InputContainerView,
} from '../../../components/views';
import {Card} from 'react-native-elements';
import {Formik} from 'formik';
import env from 'react-native-config';
import * as yup from 'yup';
import {secondary} from '../../../theme/colors';
import {ItBlockButton} from '../../../components/buttons';
import {FaInputIcon} from '../../../components/icons';
import {ItInput} from '../../../components/input';
import Toast from 'react-native-root-toast-updated';
import Loader from '../../../components/Loader';
import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import {AuthStackParams} from '../../auth/entities/AuthStackParams';

interface Values {
  password: string;
  password_confirmation: string;
}

type NavigationProp = StackNavigationProp<
  AuthStackParams,
  'ForgotPasswordSetNewScreen'
>;
type RouteProps = RouteProp<AuthStackParams, 'ForgotPasswordSetNewScreen'>;

const ForgotPasswordSetNewScreen = () => {
  const route = useRoute<RouteProps>();
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = React.useState(false);
  const uid = route.params.uid ?? '';
  const token = route.params.token ?? '';
  const email = route.params.email ?? '';
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .trim('Remueve los espacios')
      .matches(
        /(?=.*[A-Z])(?=.*\d).{8,100}/,
        'Tu contraseÃ±a debe contener al menos 8 catacteres incluyendo una mayuscula y un nÃºmero',
      )
      .required('Campo requerido'),
    password_confirmation: yup
      .string()
      // @ts-ignore
      .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden')
      .trim('Remueve los espacios')
      .required('Campo requerido'),
  });

  const onSubmit = async (values: Values): Promise<void> => {
    setIsLoading(true);
    try {
      const res = await fetch(`${env.API_HOST_NEW}/v1/users/${uid}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
          password: values.password,
          password_confirmation: values.password_confirmation,
        }),
      });
      if (!res.ok) {
        throw new Error('Connection error');
      }
      Toast.show('Tu contraseÃ±a fue cambiada', {
        backgroundColor: 'green',
      });
      navigation.navigate('Login', {
        email: email,
        password: values.password,
      });
    } catch (ex) {
      Toast.show('Error conectando al servidor', {
        backgroundColor: 'red',
      });
    }
    setIsLoading(false);
  };
  return (
    <ScreenContainer>
      <Loader loading={isLoading} message="Cargando..." />
      <Card
        title="INGRESA UNA NUEVA CONTRASEÃ‘A"
        titleStyle={{color: secondary}}>
        {/* <ItText style={{marginBottom: 10, paddingLeft: 10}}>
          Enter a new password and confirm
        </ItText> */}
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            password: '',
            password_confirmation: '',
          }}
          validationSchema={validationSchema}>
          {({
            values,
            handleSubmit,
            errors,
            handleChange,
            handleBlur,
          }): React.ReactElement => {
            return (
              <>
                <InputContainerView>
                  <ItInput
                    errorMessage={errors.password}
                    value={values.password}
                    onBlur={handleBlur('password')}
                    autoCapitalize={'none'}
                    autoFocus
                    secureTextEntry
                    placeholder="Nueva contraseña"
                    leftIcon={<FaInputIcon name="key-skeleton" />}
                    onChangeText={handleChange('password')}
                  />
                </InputContainerView>
                <InputContainerView>
                  <ItInput
                    errorMessage={errors.password_confirmation}
                    value={values.password_confirmation}
                    secureTextEntry
                    onBlur={handleBlur('password_confirmation')}
                    autoCapitalize={'none'}
                    placeholder="Confirmar contraseÃ±a"
                    leftIcon={<FaInputIcon name="key-skeleton" />}
                    onChangeText={handleChange('password_confirmation')}
                  />
                </InputContainerView>
                <JustifyCenterContainer>
                  <ItBlockButton
                    onPress={(): void => handleSubmit()}
                    title="CONTINUAR"
                  />
                </JustifyCenterContainer>
              </>
            );
          }}
        </Formik>
      </Card>
    </ScreenContainer>
  );
};

ForgotPasswordSetNewScreen.navigationOptions = {
  title: 'Restore password',
};

export default ForgotPasswordSetNewScreen;
