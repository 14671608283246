import {File} from '../../../../entities/File';
import {
  AddCampaignImageAction,
  ADD_CAMPAIGN_IMAGE,
  RemoveCampaignImageAction,
  REMOVE_CAMPAIGN_IMAGE,
} from './campaignsImagesActionTypes';

export function addCampaignImage(
  campaignId: number,
  file: File,
): AddCampaignImageAction {
  return {
    type: ADD_CAMPAIGN_IMAGE,
    payload: {
      campaignId,
      file,
    },
  };
}

export function removeCampaignImage(
  campaignId: number,
  imageIndex: number,
): RemoveCampaignImageAction {
  console.log('pasa');

  return {
    type: REMOVE_CAMPAIGN_IMAGE,
    payload: {
      campaignId,
      imageIndex,
    },
  };
}


export function clearPhotos(campaignId: number) {
  return {
    type: 'CAMPAIGNS/CLEAR_CAMPAIGN_IMAGES',
    payload: {
      campaignId,
    }
  }
}
