/**
* @copyright: Ax Marketing 2022
* @author: Cristian Cedeño<ccedeno@inmov.com>
* @date: 2022-01-27 07:12:13-0500 
**/
import * as React from 'react';
import {ScrollView} from 'react-native-gesture-handler';
import {Card, Button} from 'react-native-elements';
import {Image} from 'react-native-elements';
import ItText from '../ui/ItText';
import {Block} from '../../components/views';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {Linking} from 'react-native';

export interface SupportScreenProps {
}

const SupportScreen: React.FunctionComponent<SupportScreenProps> = (props: SupportScreenProps) => {
  return (
    <ScrollView>
      <Card>
        <Card.Title>Escríbanos vía WhatsApp</Card.Title>
        <Card.Image
          style={{
            width: '100%',
            aspectRatio: 344/154
          }}
          source={require('./assets/whatsapp.jpg')}></Card.Image>
        <ItText>Comunícate con nosotros a través de nuestro canal de soporte y soluciona tus dudas.</ItText>
        <Card.Divider></Card.Divider>
        <Block justifySpaceBetween>
          <Button
            onPress={() => {
              Linking.openURL('https://wa.me/573204578838');
            }}
            titleStyle={{
              color: '#0083D3',
              marginLeft: 10
            }}
          buttonStyle={{
            borderColor: 'transparent'
          }} type="clear" title="Escribir" icon={<FontAwesome5Icon color='#0083D3' size={20} name="whatsapp" brand></FontAwesome5Icon>}></Button>
          <Button
            onPress={() => {
              Linking.openURL('tel:3204578838');
            }}
            titleStyle={{
              color: '#0083D3',
              marginLeft: 10
            }}
          buttonStyle={{
            borderColor: 'transparent'
          }} type="clear" title="llamar" icon={<FontAwesome5Icon color='#0083D3' size={20} name="phone" ></FontAwesome5Icon>}></Button>
        </Block>


      </Card>
      
    </ScrollView> 
  );
}

export default SupportScreen;
