import React from 'react';
import {View} from 'react-native';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {lightGrayAlt} from '../theme/colors';
import ItText from './ItText';

interface Props {
  iconName: string;
  text: string;
}
const EmptyListComponent = ({iconName, text}: Props): React.ReactElement => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        minHeight: 200,
        paddingHorizontal: 10,
      }}>
      <FontAwesome5Icon
        name={iconName}
        size={90}
        light
        style={{marginBottom: 20}}
        color={lightGrayAlt}
      />
      <ItText style={{color: lightGrayAlt, textAlign: 'center'}} size={20}>
        {text}
      </ItText>
    </View>
  );
};

export default EmptyListComponent;
