import * as React from 'react';
import {Block} from '../../../components/views';
import {View, StyleSheet} from 'react-native';
import ItText from '../../../components/ItText';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';

export interface PostsInCardCountersProps {
  likesCount: number;
  commentsCount: number;
}

const PostsInCardCounters: React.SFC<PostsInCardCountersProps> = ({
  likesCount,
  commentsCount,
}: PostsInCardCountersProps) => {
  return (
    <Block
      style={{
        top: -30,
        marginTop: -30,
        justifyContent: 'flex-end',
      }}>
      <View style={styles.countItem}>
        <ItText white strong>
          <FontAwesome5Icon name="comment-alt" solid color="white" />
          &nbsp;{commentsCount}
        </ItText>
      </View>
      <View style={styles.countItem}>
        <ItText white strong>
          <FontAwesome5Icon name="heart" solid color="white" />
          &nbsp;{likesCount}
        </ItText>
      </View>
    </Block>
  );
};

const styles = StyleSheet.create({
  countItem: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    padding: 5,
    borderRadius: 5,
    marginRight: 5,
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
  },
});

export default PostsInCardCounters;
