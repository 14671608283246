import * as React from 'react';
import {InputProps} from 'react-native-elements';
import useFetch from 'use-http';
import Dropdown from '../../ui/modules/inputs/Dropdown';

export interface OfferMakerInputProps extends InputProps {}

const OfferMakerInput: React.FunctionComponent<OfferMakerInputProps> = (
  props: OfferMakerInputProps,
) => {
  const {data = {}, loading, error} = useFetch<any>(
    '/api/app/redemption/makers?page=1&pageSize=2000&name=',
    {},
    [],
  );

  const items: any = React.useMemo(() => {
    if (loading) {
      return [
        {
          label: 'Cargando...',
          value: '',
        },
      ];
    }
    if (error) {
      return [
        {
          label: 'Error conectando con el servidor',
          value: '',
        },
      ];
    }
    if (!Array.isArray(data.data)) {
      return [];
    }
    return data.data.map((d: any) => ({
      label: d.name,
      value: d.id,
    }));
  }, [data.data, error, loading]);

  return <Dropdown items={items} {...props} />;
};

export default OfferMakerInput;
