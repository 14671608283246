import * as React from 'react';
import {View} from 'react-native';
import {Avatar, ListItem} from 'react-native-elements';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {primary} from '../../../theme/colors';
import ItText from '../../ui/ItText';
import {useSelector} from 'react-redux';
import UserPointsBanner from './UserPointsBanner';

const HomeUserCard: React.SFC = () => {
  const user = useSelector((state: any) => state.auth.user || {});
  return (
    <>
      <View style={{paddingHorizontal: 10}}>
        <ListItem>
          <Avatar
            rounded
            size="medium"
            source={{
              uri: user?.avatar,
            }}
          />
          <ListItem.Content>
            <ListItem.Title
              style={{
                color: primary,
                fontWeight: 'bold',
                marginBottom: 7,
              }}>
              {user?.name}
            </ListItem.Title>
            <ListItem.Subtitle style={{
              maxWidth: 120
              }}>
              <FontAwesome5Icon
                style={{paddingRight: 10}}
                light={true}
                name="map-marker-alt"
                size={14}
              />
              {user?.address} 
            </ListItem.Subtitle>
          </ListItem.Content>
        </ListItem>
      </View>
      <UserPointsBanner />
    </>
  );
};

export default HomeUserCard;
