import _ from 'lodash';
import {audit_status} from '../entities/audits';
import {
  Campaign,
  Challenge,
  CommercialAction,
} from '../entities/commercialAction';
import {primary} from './../../../theme/colors';
import {wasExecutedCampaign, wasExecutedChallenge} from './wasExecuted';

export const getCampaignLabelColor = (item: Campaign) => {
  return wasExecutedCampaign(item) ? '#aaa' : primary;
};

export const getChallengeLabelColor = (item: Challenge) => {
  if (!wasExecutedChallenge(item)) {
    return primary;
  } else {
    const lastExecution = _.last(item.executions);
    const firstAuditResult = _.get(lastExecution, 'audit_result.id');
    switch (firstAuditResult) {
      case audit_status.APROVED:
        return '#008000';
      case audit_status.PENDING:
        return '#DAA520';
      case audit_status.REJECTED:
        return '#ff801f';
      default:
        return '#000000';
    }
  }
};

const getLabelColor = (item: CommercialAction) => {
  const isChallenge = item.challenges.length > 0;
  if (!isChallenge) {
    return getCampaignLabelColor(item.campaigns[0]);
  }
  return getChallengeLabelColor(item.challenges[0]);
};

export default getLabelColor;
