import * as React from 'react';
import {Provider as HttpProvider} from 'use-http';
import {useDispatch} from 'react-redux';
import AsyncStorage from '@callstack/async-storage';
import {API_HOST} from './env';
import {actionTypes} from './modules/auth/redux/authRedux';

export interface ApiWrapperProps {
  children: React.ReactNode;
}

const ApiWrapper: React.SFC<ApiWrapperProps> = ({
  children,
}: ApiWrapperProps) => {
  const dispatch = useDispatch();
  const httpOptions = React.useMemo(
    () => ({
      interceptors: {
        request: async ({options}: any) => {
          const token = await AsyncStorage.getItem('token');

          if (token) {
            options.headers.Authorization = `Bearer ${token}`;
          }
          return options;
        },
        response: async ({response}: any) => {
          if (response.status == 401) {
            dispatch({
              type: actionTypes.USER_LOGOUT,
            });
          }
          return response;
        },
      },
    }),
    [dispatch],
  );

  return (
    <HttpProvider url={API_HOST} options={httpOptions}>
      {children}
    </HttpProvider>
  );
};

export default React.memo(ApiWrapper);
