import {RouteProp, useRoute} from '@react-navigation/core';
import _ from 'lodash';
import * as React from 'react';
import {FlatList} from 'react-native';
import {Card} from 'react-native-elements';
import {RootStackParamsList} from '../../../Navigator';
import CampaignHeader from '../components/CampaignHeader';
import CampaignQuestion from '../components/CampaignQuestion';
import {Question} from '../entities/commercialAction';

export interface CampaignExecutedScreenProps {}

type CampaignExecuteRouteProp = RouteProp<
  RootStackParamsList,
  'CampaignExecutedScreen'
>;

const CampaignExecutedScreen: React.FunctionComponent<CampaignExecutedScreenProps> = () => {
  const route = useRoute<CampaignExecuteRouteProp>();
  const {item: campaign} = route.params;

  return (
    <div style={{
    height: 'calc(100vh - 70px)',
    overflowY: 'scroll',
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    }}>
      <CampaignHeader item={campaign} />
      <Card
        containerStyle={{
          flex: 1,
        }}>
        <FlatList<Question>
          style={{
            flexGrow: 1,
          }}
          contentContainerStyle={{}}
          data={campaign.questions}
          keyExtractor={_.property('id')}
          renderItem={({item}) => <CampaignQuestion question={item} />}
        />
      </Card>
    </div>
  );
};

export default CampaignExecutedScreen;
