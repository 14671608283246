// @ts-nocheck
import * as React from 'react';
import {Image, ImageBackground, StyleSheet, View} from 'react-native';
import {Badge, ListItem} from 'react-native-elements';
import {primary} from '../../../theme';
import {RectButton} from 'react-native-gesture-handler';
import {useNavigation} from '@react-navigation/core';
import ItText from '../../ui/ItText';
import invertColor from '../../../utils/invertColor';
import useCampaignInfoForType from '../hooks/useCampaignInfoForType';

export interface CampaignListItemProps {
  item: any;
}

const CampaignListItem = ({item}: CampaignListItemProps) => {
  const navigation = useNavigation();
  const {
    isCampaign,
    isChallenge,
    photo,
    name,
    dateExpire,
    shortDescription,
    executionLabel,
    labelColor,
  } = useCampaignInfoForType(item);

  if (!isChallenge && !isCampaign) {
    return null;
  }

  return (
    <View style={styles.itemContainer}>
      <ListItem
        Component={RectButton}
        underlayColor={primary}
        ViewComponent={(props: any): any => (
          <View style={styles.itemView}>
            <ImageBackground
              resizeMode="cover"
              source={require('../../pedidos/assets/bg_card.png')}
              {...props}
            />
          </View>
        )}
        onPress={() => {
          if (isCampaign) {
            navigation.navigate('CampaignsDetail', {
              id: item.campaigns[0].id,
            });
            return;
          }
          navigation.navigate('ChallengeDetail', {
            id: item.challenges[0].id,
          });
        }}>
        <Image
          style={styles.campaignImage}
          source={{
            uri: photo,
          }}
        />
        <ListItem.Content
          style={{
            maxWidth: 150,
          }}>
          <ListItem.Title style={styles.title}>
            Vence: {dateExpire}
          </ListItem.Title>
          <ListItem.Subtitle
            style={{
              maxWidth: 130,
            }}>
            <>
              <View
                style={{
                  width: 150,
                }}>
                <ItText size={14}>{name}</ItText>
                <ItText size={12} light style={styles.description}>
                  {shortDescription}
                </ItText>
                <Badge
                  value={
                    <ItText white style={styles.badgeText}>
                      {executionLabel}
                    </ItText>
                  }
                  badgeStyle={[
                    styles.badge,
                    {
                      borderRadius: 7,
                      backgroundColor: labelColor,
                    },
                  ]}
                  textStyle={[
                    styles.badgeText,
                    {
                      color: invertColor(labelColor, true),
                    },
                  ]}
                />
              </View>
            </>
          </ListItem.Subtitle>
        </ListItem.Content>
      </ListItem>
      <View style={styles.imageView}>
        <ImageBackground
          style={styles.image}
          source={require('../../pedidos/assets/badge.png')}>
          <ItText style={styles.points}>{item.points.points}</ItText>
          <ItText style={styles.pointsLabel}>Puntos</ItText>
        </ImageBackground>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    backgroundColor: 'white',
    marginVertical: 7,
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  itemView: {
    overflow: 'hidden',
  },
  title: {
    color: 'red',
    fontSize: 10,
    width: 100,
  },
  imageView: {
    position: 'absolute',
    top: 15,
    right: 0,
  },
  image: {
    width: 89,
    height: 67,
    top: -10,
  },
  points: {
    fontSize: 30,
    alignSelf: 'center',
    color: 'white',
    fontFamily: 'roboto',
    fontWeight: 'bold',
  },
  pointsLabel: {
    alignSelf: 'center',
    color: 'white',
    fontSize: 14,
    marginTop: -5,
  },
  description: {
    marginBottom: 4,
  },
  badge: {
    borderRadius: 0,
    paddingHorizontal: 10,
    paddingVertical: 10,
    marginTop: 10,
    alignSelf: 'flex-start',
  },
  badgeText: {
    fontSize: 16,
  },
  campaignImage: {
    width: 60,
    height: 60,
    alignSelf: 'flex-start',
    borderRadius: 30,
  },
});

export default React.memo(CampaignListItem);
