import * as React from 'react';
import FAPro, {
  FontAwesome5ProIconProps,
} from 'react-native-vector-icons/FontAwesome5Pro';
import {primary} from '../theme/colors';

interface Props {
  withLabel?: boolean;
}

export const FaInputIcon = ({
  style,
  withLabel,
  ...props
}: Props & FontAwesome5ProIconProps): React.ReactElement => (
  <FAPro
    {...props}
    style={[
      style,
      {fontSize: 16, color: primary, marginRight: 10},
      withLabel && {
        marginLeft: -13,
      },
    ]}
  />
);
