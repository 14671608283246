import React from 'react';
import {RectButton} from 'react-native-gesture-handler';
import {Card, Avatar} from 'react-native-elements';
import {Block} from '../../../components/views';
import ItText from '../../../components/ItText';
import {connect} from 'react-redux';
import {useNavigation} from '@react-navigation/native';
import {primary} from '../../../theme';
import {selectAuthenticatedUser} from '../../auth/redux/authRedux';

const CommunityCreate = ({user}: {user: any}) => {
  const navigation = useNavigation();
  const eventColor = primary;
  return (
    <RectButton
      onPress={() =>
        navigation.navigate('NewCommunityPublishScreen', {
          eventColor: eventColor,
        })
      }>
      <Card
        containerStyle={{
          height: 52,
          padding: 0,
          justifyContent: 'center',
          paddingVertical: 20,
          paddingHorizontal: 10,
          marginBottom: 0,
        }}>
        <Block style={{alignItems: 'center'}}>
          <Avatar
            title={user.name[0]}
            source={{uri: `${user.avatar}`}}
            size={35}
            rounded
            containerStyle={{marginRight: 15}}
          />
          <ItText size={12} style={{color: '#95989A'}}>
            ¿Qué está pensando?
          </ItText>
        </Block>
      </Card>
    </RectButton>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectAuthenticatedUser(state),
  };
};

export default connect(mapStateToProps)(CommunityCreate);
