import * as React from 'react';
import {View, TouchableOpacity} from 'react-native';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {useDispatch} from 'react-redux';
import styled from 'styled-components/native';
import ItImageAutoHeight from '../../../components/ItImageAutoHeight';
import {removeCampaignImage} from '../state/campaignImages/campaignImagesActions';

export function CampaignsPreviewImages(props: {
  previewUri: string[];
  campaignId: number;
}) {
  const dispatch = useDispatch();
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
      }}>
      {props.previewUri.map((p, index) => (
        <PreviewItem key={index}>
          <RemoveButton
            onPress={() => {
              dispatch(removeCampaignImage(props.campaignId, index));
            }}>
            <FontAwesome5Icon light size={18} name="times" color="red" />
          </RemoveButton>
          <ItImageAutoHeight
            source={{
              uri: p,
            }}
          />
        </PreviewItem>
      ))}
    </View>
  );
}

const PreviewItem = styled.View`
  width: 48%;
  margin: 1%;
`;

const RemoveButton = styled(TouchableOpacity)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  z-index: 999;
  elevation: 10;
`;
