import dayjs from 'dayjs';
import * as React from 'react';
import {StyleSheet} from 'react-native';
import {Badge} from 'react-native-elements';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5';
import {Block} from '../../../components/views';
import {secondary} from '../../../theme';
import ItText from '../../ui/ItText';

export interface CommercialActionHeaderProps {
  title: string;
  end: string;
  label: string;
  badgeColor: string;
}

const CommercialActionHeader: React.FunctionComponent<CommercialActionHeaderProps> = ({
  title,
  end,
  label,
  badgeColor,
}: CommercialActionHeaderProps) => {
  return (
    <>
      <ItText style={styles.title}>{title}</ItText>
      <ItText secondary style={styles.expire}>
        <FontAwesome5Icon name="clock" color={secondary} />
        &nbsp;Vence: {dayjs(end).format('dddd DD MMMM')}
      </ItText>
      <Block style={styles.campaignStatus}>
        <ItText strong style={styles.expire}>
          Estado:
        </ItText>
        <Badge
          value={label}
          containerStyle={styles.badge}
          badgeStyle={{
            backgroundColor: badgeColor,
          }}
        />
      </Block>
    </>
  );
};

const styles = StyleSheet.create({
  campaignStatus: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: 32,
  },
  expire: {
    textAlign: 'center',
    marginTop: 10,
  },
  badge: {
    marginLeft: 10,
  },
});

export default React.memo(CommercialActionHeader);
