import env from 'react-native-config';

export async function validateTokenRequest(
  token: string,
  email: string,
  onErrorValidationError: (validationMsg: string) => void,
): Promise<any | undefined> {
  const res = await fetch(`${env.API_HOST_NEW}/api/oauth/pdv/validate/token`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: token,
      item: email,
    }),
  });
  if (!res.ok) {
    if (res.status == 401) {
      onErrorValidationError('Código invalido');
      return;
    }
    throw new Error('Connection error');
  }
  const jsonRes = await res.json();
  return jsonRes;
}
