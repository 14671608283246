import * as React from 'react';
import {ScrollView} from 'react-native-gesture-handler';
import Toast from 'react-native-root-toast-updated';
import {verticalScale} from 'react-native-size-matters';
import {useSelector} from 'react-redux';
import useFetch from 'use-http';
import {API_HOST} from '../../../env';
import {selectAuthenticatedUser} from '../../auth/redux/authRedux';
import ItText from '../../ui/ItText';
import EditableAvatar from '../components/EditableAvatar';
import ProfileUpdateForm from '../components/ProfileUpdateForm';
import profileSections from '../configs/profileSections';

export interface ProfileUpdateScreenProps {}

const ProfileUpdateScreen: React.FunctionComponent<ProfileUpdateScreenProps> = () => {
  const user = useSelector(selectAuthenticatedUser);
  const {put, response} = useFetch(`${API_HOST}/api/app/pdv/${user.id}`, {}, [
    user,
  ]);

  return (
    <ScrollView
      contentContainerStyle={{
        paddingVertical: verticalScale(20),
      }}>
      <ItText
        strong
        size={23}
        style={{
          textAlign: 'center',
          marginBottom: verticalScale(20),
        }}>
        Completar perfil
      </ItText>
      <EditableAvatar />
      <ProfileUpdateForm
        handleSubmit={async (values) => {
          await put(values);
          if (response.ok) {
            Toast.show('La información ha sido actualizada', {
              backgroundColor: 'green',
              duration: Toast.durations.LONG,
            });
          } else {
            Toast.show('Error conectando con el servidor', {
              backgroundColor: 'red',
              duration: Toast.durations.LONG,
            });
          }
        }}
        sections={profileSections}
      />
    </ScrollView>
  );
};

export default ProfileUpdateScreen;
