import {Formik} from 'formik';
import * as React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {ItButton} from '../../../components/buttons';
import {ItInputBox} from '../../../components/input';
import {Block} from '../../../components/views';
import {secondary, Space} from '../../../theme';
import ItText from '../../ui/ItText';
import * as yup from 'yup';
import {selectAuthenticatedUser, setUserInfo} from '../../auth/redux/authRedux';
import {useDispatch, useSelector} from 'react-redux';
import useFetch from 'use-http';
import Toast from 'react-native-root-toast-updated';
import TakePhotoInput from '../../ui/modules/inputs/TakePhotoInput';
import ImagePreview from '../../ui/modules/inputs/ImagePreview';

export interface DeliveryActivateScreenProps {}

const MSG = 'Debes indicar al menos un número de contacto';

const validationSchema = yup.object().shape({
  fijo: yup.string().nullable().when('whatsapp', {
    is: '',
    then: yup.string().required(MSG),
  }),
  open_from: yup
    .string()
    .nullable()
    .matches(/\d?\d:\d\d/, 'Debe tener el formato hora:minutos'),
  photo: yup.string().nullable().required(),
  open_until: yup
    .string()
    .matches(/\d?\d:\d\d/, 'Debe tener el formato hora:minutos'),
});

const DeliveryActivateScreen: React.FunctionComponent<DeliveryActivateScreenProps> = () => {
  const {post: saveInfo, response, cache} = useFetch(
    '/api/app/pdv/enable/delivery',
    {
      cacheLife: 1,
    },
  );
  const {post: disablePdv, response: responseDisable, loading} = useFetch(
    '/api/app/pdv/disable/delivery',
    {
      cacheLife: 1,
    },
  );
  const userInfo = useSelector(selectAuthenticatedUser);
  const dispatch = useDispatch();

  return (
    <ScrollView style={styles.container}>
      <ItText strong textCenter size={16} style={styles.title}>
        Activar Domicilios
      </ItText>
      <ItText textCenter size={14}>
        Crea una promoción en tu PDV para aumentar tus ventas
      </ItText>
      <Formik
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          cache.clear();
          const {data} = await saveInfo(values);
          if (response.ok) {
            dispatch(setUserInfo(data));
            Toast.show('Información actualizada', {
              duration: Toast.durations.LONG,
              backgroundColor: 'green',
            });
          } else {
            Toast.show('Error conectando con el servidor', {
              duration: Toast.durations.LONG,
              backgroundColor: 'red',
            });
          }
        }}
        enableReinitialize
        initialValues={{
          fijo: userInfo.fijo,
          whatsapp: userInfo.whatsapp,
          conditions: userInfo.home_delivery_conditions,
          open_from: userInfo.open_from,
          open_until: userInfo.open_until,
          photo: userInfo.avatar,
        }}>
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          errors,
          touched,
          setFieldValue,
        }) => (
          <View
            style={{
              marginHorizontal: Space.SMALL,
              marginTop: Space.xl,
            }}>
            {values.photo ? (
              <ImagePreview
                uri={values.photo}
                onRemove={() => {
                  setFieldValue('photo', '');
                }}
              />
            ) : (
              <ItInputBox
                value={values.photo}
                onChangeText={handleChange('photo')}
                onBlur={handleBlur('photo')}
                errorMessage={touched.photo ? errors.photo : ''}
                placeholder="Foto del establecimiento"
                InputComponent={TakePhotoInput}
                leftIcon={
                  <FontAwesome5Icon
                    name="camera"
                    color={secondary}
                    size={18}
                    light
                  />
                }
              />
            )}
            <ItInputBox
              value={values.fijo}
              onChangeText={handleChange('fijo')}
              onBlur={handleBlur('fijo')}
              errorMessage={touched.fijo ? errors.fijo : ''}
              placeholder="Celular / fijo"
              leftIcon={
                <FontAwesome5Icon
                  name="phone"
                  color={secondary}
                  size={18}
                  light
                />
              }
            />
            <ItInputBox
              value={values.whatsapp}
              onChangeText={handleChange('whatsapp')}
              onBlur={handleBlur('whatsapp')}
              errorMessage={touched.whatsapp ? errors.whatsapp : ''}
              placeholder="Celular / Whatsapp"
              leftIcon={
                <FontAwesome5Icon
                  name="mobile-alt"
                  color={secondary}
                  size={18}
                  light
                />
              }
            />
            <ItInputBox
              value={values.conditions}
              onChangeText={handleChange('conditions')}
              onBlur={handleBlur('conditions')}
              placeholder="Condiciones"
              multiline
              numberOfLines={3}
            />
            <Block>
              <ItInputBox
                value={values.open_from}
                onChangeText={handleChange('open_from')}
                onBlur={handleBlur('open_from')}
                containerStyle={{
                  flex: 1,
                  marginRight: Space.SMALL,
                }}
                placeholder="hh:mm"
                errorMessage={touched.open_from ? errors.open_from : ''}
                label="Hora apertura"
                leftIcon={
                  <FontAwesome5Icon name="clock" color={secondary} size={18} />
                }
              />
              <ItInputBox
                label="Hora cierre"
                placeholder="hh:mm"
                value={values.open_until}
                errorMessage={touched.open_until ? errors.open_until : ''}
                onChangeText={handleChange('open_until')}
                onBlur={handleBlur('open_until')}
                containerStyle={{
                  flex: 1,
                }}
                leftIcon={
                  <FontAwesome5Icon name="clock" color={secondary} size={18} />
                }
              />
            </Block>
            <ItButton
              title={
                userInfo.home_delivery
                  ? 'GUARDAR INFORMACIÓN'
                  : 'ACTIVAR DOMICILIOS'
              }
              onPress={() => handleSubmit()}
              loading={isSubmitting}
            />
            {!!userInfo.home_delivery && (
              <ItButton
                title="DESACTIVAR DOMICILIOS"
                buttonStyle={{
                  marginTop: Space.SMALL,
                  backgroundColor: secondary,
                  borderColor: secondary,
                }}
                onPress={async () => {
                  const {data} = await disablePdv();
                  if (responseDisable) {
                    dispatch(setUserInfo(data));
                    Toast.show('Establecimiento desactivado', {
                      duration: Toast.durations.LONG,
                      backgroundColor: 'green',
                    });
                  } else {
                    Toast.show('Error conectando con el servidor', {
                      duration: Toast.durations.LONG,
                      backgroundColor: 'red',
                    });
                  }
                }}
                loading={loading}
              />
            )}
          </View>
        )}
      </Formik>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    marginHorizontal: 20,
    marginVertical: 10,
  },
  title: {
    marginBottom: Space.SMALL,
    marginTop: Space.xl,
  },
});

export default DeliveryActivateScreen;
