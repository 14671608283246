import * as React from 'react';
import {StyleSheet, View, Dimensions, Alert} from 'react-native';
import {Button, Card} from 'react-native-elements';
import ItText from '../../ui/ItText';
import dayjs from 'dayjs';
import {StackScreenProps} from '@react-navigation/stack';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import HtmlPreview from '../../../components/HtmlPreview';
import {primary} from '../../../theme/colors';
import {Block} from '../../../components/views';
import {API_HOST} from '../../../env';
import useFetch from 'use-http';
import {useSelector} from 'react-redux';
import PedidoConfirmationOverlay from '../../pedidos/components/PedidoConfirmationOverlay';
import {currencyFormat} from '../../../helpers';
import {ScrollView} from 'react-native-gesture-handler';
import ItImageAutoHeight from '../../../components/ItImageAutoHeight';

const {width} = Dimensions.get('screen');

const PromoDetailScreen: React.SFC<any> = (props: StackScreenProps<any>) => {
  const item = props.route?.params?.item;
  const {post, cache} = useFetch(`${API_HOST}/api/app/promotion/pdv/order`, {
    method: 'POST',
    cacheLife: 0,
  });
  const user = useSelector((state: any) => state.auth.user);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Card>
        <ItText strong style={styles.title}>
          {item.name}
        </ItText>
        <ItText strong style={styles.expiration}>
          <FontAwesome5Icon name="clock" />
          <ItText>&nbsp;Vence: {dayjs(item.end).format('dddd DD MMMM')}</ItText>
        </ItText>
        <ItImageAutoHeight
          style={styles.image}
          source={{
            uri: item.image,
          }}
        />
        <ItText strong size={16}>
          {item.name}
        </ItText>
        <HtmlPreview htmlContent={item.detail} />
        <View style={styles.dataContainer}>
          <Block style={styles.dataItem}>
            <ItText strong style={styles.label}>
              Categoria:
            </ItText>
            <ItText>{item.redemption_type.name}</ItText>
          </Block>
          <Block style={styles.dataItem}>
            <ItText strong style={styles.label}>
              Cantidad:
            </ItText>
            <ItText>{item.sales_unity}</ItText>
          </Block>
        </View>
        {!!item.price && (
          <>
            <ItText style={styles.labelValue}>Valor</ItText>
            <ItText style={styles.value}>{currencyFormat(item.price)}</ItText>
          </>
        )}
        <Button
          title="QUIERO ESTA PROMO"
          style={styles.button}
          onPress={async () => {
            setShowConfirmation(true);
          }}
        />
      </Card>
      <PedidoConfirmationOverlay
        isVisible={showConfirmation}
        items={[item]}
        onHide={() => setShowConfirmation(false)}
        onConfirm={async (successCallback: Function) => {
          cache.clear();
          const response = await post('', {
            promotion_id: item.id,
            quantity: 1,
            delivery_address: user.address,
          });

          if (response.code === 422) {
            setShowConfirmation(false);
            setTimeout(() => {
              Alert.alert(response.data);
            }, 0);
          }
          if (response.code === 200) {
            successCallback(response.data);
          }
        }}
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 16,
  },
  image: {
    alignSelf: 'center',
    marginTop: 20,
    marginBottom: 10,
    borderRadius: 14,
  },
  expiration: {
    color: '#636363',
    fontSize: 13,
    textAlign: 'center',
    alignItems: 'center',
    marginTop: 15,
  },
  labelValue: {
    color: '#1C1C1C',
    fontSize: 14,
  },
  value: {
    color: primary,
    fontWeight: 'bold',
    fontSize: 20,
    marginTop: 5,
    marginBottom: 50,
  },
  dataContainer: {
    marginVertical: 20,
  },
  label: {
    paddingRight: 10,
  },
  dataItem: {
    marginBottom: 10,
  },
  button: {
    width: '100%',
  },
  container: {
    paddingBottom: 40,
  },
});

export default PromoDetailScreen;
