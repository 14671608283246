import {useNavigation} from '@react-navigation/native';
import * as React from 'react';
import {Image, RefreshControl, StyleSheet, View} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';
import _ from 'lodash';
import useFetch from 'use-http';
import {API_HOST} from '../../env';
import {News} from '../home/entities/News';
import Loading from '../../components/Loading';
import ItText from '../ui/ItText';
import { ListItem } from 'react-native-elements';
import htmlToText from '../../utils/htmlToText';

export interface NewsListProps {}

const NewsList: React.FunctionComponent<NewsListProps> = () => {
  const [refreshing, setRefreshing] = React.useState(false);
  const navigation = useNavigation();
  const {data = {}, loading, get, cache} = useFetch(
    `${API_HOST}/api/app/promotional/messages?page=1&pageSize=20`,
    {},
    [],
  );
  const {data: news = []} = data;

  /* console.count('pasa'); */

  const makeNavigateToDetail = React.useCallback(
    _.memoize((item: News) => () => {
      navigation.navigate('NewsDetail', {
        item,
      });
    }),
    [navigation],
  );

  return (
    <div id="news" style={{
    flex: 1,
    height: '100%',
    paddingBottom: 70
    }}>
      <View style={{
        alignSelf: 'center'
      }}>
        {loading && <Loading size={20} />}
      </View>
      <FlatList
        data={news}
        keyboardShouldPersistTaps="always"
        contentContainerStyle={{
          paddingBottom: 50,
        }}
        style={{
	  height: '100%'
        }}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={async () => {
              cache.clear();
              setRefreshing(true);
              await get();
              setRefreshing(false);
            }}
          />
        }
        renderItem={({item}) => (
          <View
            style={{
              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 2,
              },
              backgroundColor: 'white',
              marginVertical: 7,
              shadowOpacity: 0.25,
              shadowRadius: 3.84,
              elevation: 5,
            }}>
            <ListItem onPress={makeNavigateToDetail(item)}>
              <Image
                style={styles.avatar}
                resizeMode="cover"
                source={{
                  uri: item.promotional_image,
                }}
              />
              <ListItem.Content>
                <ListItem.Title>{item.name}</ListItem.Title>
                <ListItem.Subtitle>
                  <ItText>
                    {htmlToText(item.description).substring(0, 80)}
                  </ItText>
                </ListItem.Subtitle>
              </ListItem.Content>
            </ListItem>
          </View>
        )}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  avatar: {
    width: 120,
    height: 120,
  },
});

export default NewsList;
