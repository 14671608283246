import {useNavigation} from '@react-navigation/native';
import * as React from 'react';
import {Button, Overlay} from 'react-native-elements';
import ItText from '../../ui/ItText';
import {
  OverlayBody,
  OverlayContent,
  OverlayTitle,
} from '../../ui/modules/overlays/OverlayHelpers';
import OverlayIcon from '../../ui/modules/overlays/OverlayIcon';

export function CampaignExecutedOverlay(props: {
  completed: boolean;
  setCompleted: (completed: boolean) => void;
}) {
  const navigation = useNavigation();
  return (
    <Overlay isVisible={props.completed}>
      <OverlayBody>
        <OverlayIcon name="check" />
        <OverlayTitle>Enviado</OverlayTitle>
        <OverlayContent>
          <ItText>
            Una vez tu foto haya sido verificada y validada, recibirás
            confirmación de que eres ganador.
          </ItText>
        </OverlayContent>
        <OverlayContent>
          <ItText>
            Si no cumple tu foto se te habilitara para que la corrijas y envíes
            nuevamente!
          </ItText>
        </OverlayContent>
        <Button
          onPress={() => {
            props.setCompleted(false);
            navigation.navigate('main');
          }}
          title="CONFIRMAR"
        />
      </OverlayBody>
    </Overlay>
  );
}
