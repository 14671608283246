import * as React from 'react';
import {ScrollView} from 'react-native-gesture-handler';
import LoyaltyInstructions from './LoyaltyInstruction';

export interface LoyaltyInstructionsProps {}

const INSTRUCTIONS = [
  `¡Ganar puntos es muy fácil!
Por cada encuesta y reto que complete,
acumulará puntos que podrá canjear por plata.`,
`Encontrará diferentes encuestas y retos.
Complételos de manera correcta para ganar puntos.`, 
`Podrá acumular los puntos que desee como un ahorro
 y canjearlos por plata en cualquier momento.`,
];

const LoyaltyInstructionsContainer: React.FunctionComponent<LoyaltyInstructionsProps> = () => {
  return (
    <ScrollView keyboardShouldPersistTaps="always">
      {INSTRUCTIONS.map((step, index) => (
        <LoyaltyInstructions text={step} key={String(index)} />
      ))}
    </ScrollView>
  );
};

export default LoyaltyInstructionsContainer;
