import * as React from 'react';
import {View} from 'react-native';
import {InjectableFormSection} from '../../../../entities/InjectableFormSection';

export interface ProfileSectionBodyProps {
  section: InjectableFormSection;
}

const ProfileSectionBody: React.FunctionComponent<ProfileSectionBodyProps> = ({
  section,
}) => {
  return (
    <View
      style={{
        paddingVertical: 20,
        paddingHorizontal: 10,
      }}>
      {section.fields.map(({FieldComponent, name, fieldProps = {}}) => (
        <FieldComponent name={name} {...fieldProps} />
      ))}
    </View>
  );
};

export default ProfileSectionBody;
