import * as React from 'react';
import {InputProps} from 'react-native-elements';
import Toast from 'react-native-root-toast-updated';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import AsyncStorage from '@callstack/async-storage';
import {API_HOST} from '../../../../env';
import {Block} from '../../../../components/views';
import ItText from '../../ItText';
import {primary} from '../../../../theme';
import {StyleSheet} from 'react-native';
import ItSelectImageTouchable, {
  ImageFile,
} from '../../../images/components/ItSelectImageTouchable';

export interface ImageInputProps extends InputProps {}


function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], {type: mimeString});
  return blob;

}

const TakePhotoInput: React.FunctionComponent<ImageInputProps> = ({
  onChangeText,
}: ImageInputProps) => {
  const _imageSelected = React.useCallback(
    async (image: ImageFile) => {
      const data = new FormData();


      // @ts-ignore
      data.append('file', image.uri ? dataURItoBlob(image.uri) : image);
      const res = await fetch(`${API_HOST}/api/app/upload/image`, {
        method: 'post',
        body: data,
        headers: {
          //'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
          Authorization: `Bearer ${await AsyncStorage.getItem('token')}`,
        },
      });
      if (!res.ok) {
        Toast.show('Error conectando con el servidor', {
          duration: Toast.durations.LONG,
        });
        return;
      }
      const {data: uploadResponse} = await res.json();

      if (onChangeText) {
        onChangeText(uploadResponse.file);
      }
    },
    [onChangeText],
  );

  const _error = React.useCallback(() => {
    Toast.show('Error conectando seleccionando');
  }, []);
  return (
    <Block justifySpaceBetween style={{flex: 1}}>
      <ItText
        style={{
          color: 'rgb(59, 58, 62, 0.15)',
          fontSize: 18,
          marginLeft: 10,
        }}>
        Subir Foto
      </ItText>
      <ItSelectImageTouchable
        onImageSelected={_imageSelected}
        containerStyle={styles.button}
        onError={_error}>
        <ItText strong white>
          TOMAR{'  '}
          <FontAwesome5Icon name="camera" size={18} solid />
        </ItText>
      </ItSelectImageTouchable>
    </Block>
  );
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: primary,
    paddingVertical: 5,
    paddingHorizontal: 15,
    height: 50,
    marginRight: -10,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: -13,
  },
});

export default TakePhotoInput;
