import * as React from 'react';
import {useNavigation} from '@react-navigation/native';
import {RectButton} from 'react-native-gesture-handler';
import {Image} from 'react-native';
import ItText from './components/ItText';

export const MenuTabButton = ({style, ...props}: any) => {
  const navigation = useNavigation();

  return (
    <RectButton
      {...props}
      style={style}
      onPress={() => {
        // @ts-ignore
        navigation.toggleDrawer();
      }}>
      <img
        style={{
          top: 0,
        }}
        src={require('./modules/tabs/assets/menu.png')}
      />
      <ItText> </ItText>
    </RectButton>
  );
};
