import * as React from 'react';
import {Input} from 'react-native-elements';
import {QuestionComponentProps} from './QuestionRenderer';

const TextResponse = (props: QuestionComponentProps) => {
  return (
    <Input
      inputContainerStyle={{
        borderColor: '#ccc',
        borderWidth: 1,
        paddingHorizontal: 10,
        paddingVertical: 10,
      }}
      placeholder="Escribe tu respuesta (200 caracteres)"
      multiline
      numberOfLines={5}
      value={props.answers[0]?.text}
      onChangeText={(t) =>
        props.setAnswers([
          {
            campaign_question_option_id: '',
            other_which_one: '',
            text: t,
          },
        ])
      }
    />
  );
};

export default TextResponse;
