import * as React from 'react';
import useFetch from 'use-http';
import {API_HOST} from '../../../env';
import DropdownMultiple from '../../ui/modules/inputs/DropdownMultiple';
import {Maker} from '../entities/Maker';
import {toggleMakerValue} from '../modules/redemptions/state/redemptionList/redemptionListActions';
import {RedemptionContext} from '../modules/redemptions/state/redemptionList/redemptionsListState';

export interface MakerDropdownProps {}

const MakerDropdown: React.FunctionComponent<MakerDropdownProps> = () => {
  const {data = {}} = useFetch(
    `${API_HOST}/api/app/redemption/makers?page=1&pageSize=200`,
    {},
    [],
  );
  const {state, dispatch} = React.useContext(RedemptionContext);
  const makers = React.useMemo(() => {
    const {data: result = []} = data;

    return result.map((m: Maker) => ({
      label: m.name,
      value: m.id,
    }));
  }, [data]);

  return (
    <DropdownMultiple
      placeholder="Fabricante"
      items={makers}
      onToggle={(value: number) => dispatch(toggleMakerValue(value))}
      value={state.makers}
    />
  );
};

export default React.memo(MakerDropdown);
