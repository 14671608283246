import * as React from 'react';
import {Linking, StyleSheet} from 'react-native';
import {RectButton, TextInput} from 'react-native-gesture-handler';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {Block} from '../../../components/views';
import {primary, secondary, Space} from '../../../theme';
import SendIcon from '../../shopper/icons/SendIcon';
import ItText from '../../ui/ItText';
import {generateWhatsappLink} from '../utils/generateWhatsappLink';

export interface WhatsappSendTextProps {
  text: string;
}

const MIN_PHONE_CHARS = 10;

const WhatsappSendText: React.FunctionComponent<WhatsappSendTextProps> = (
  props: WhatsappSendTextProps,
) => {
  const [phone, setPhone] = React.useState('');

  const enabled = React.useMemo(() => {
    return phone.length >= MIN_PHONE_CHARS;
  }, [phone]);

  const handleSend = React.useCallback(() => {
    if (!enabled) {
      return;
    }
    const phoneWithPrefix =
      phone.length === MIN_PHONE_CHARS ? `57${phone}` : phone;
    Linking.openURL(generateWhatsappLink(phoneWithPrefix, props.text));
  }, [enabled, phone, props.text]);

  return (
    <Block style={styles.wrapper}>
      <FontAwesome5Icon
        name="phone"
        color={secondary}
        size={18}
        light
        style={styles.icon}
      />
      <TextInput
        value={phone}
        onChangeText={(nextPhone) => setPhone(nextPhone.replace(/\D/g, ''))}
        style={styles.input}
        placeholder="Número Celular"
        placeholderTextColor="#3B3A3E"
        keyboardType="number-pad"
      />
      <RectButton
        onPress={handleSend}
        style={[
          styles.button,
          {backgroundColor: enabled ? primary : '#BAB9C2'},
        ]}>
        <ItText strong white>
          ENVIAR <SendIcon />
        </ItText>
      </RectButton>
    </Block>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
  },
  input: {
    flex: 1,
    fontSize: 13,
    paddingLeft: 40,
    borderWidth: 1,
    height: 35,
    paddingVertical: 0,
    borderColor: '#EBEDF2',
  },
  icon: {
    position: 'absolute',
    top: 7,
    left: 10,
  },
  button: {
    height: 35,
    alignItems: 'center',
    justifyContent: 'center',
    width: 104,
    borderRadius: 3,
    marginBottom: Space.xl,
  },
});

export default WhatsappSendText;
