import React from 'react';
import { View, ViewStyle } from 'react-native';

const HtmlPreview = ({
  htmlContent,
  containerStyle,
}: {
  htmlContent: string;
  containerStyle?: ViewStyle;
}) => {
  return (
    <View style={containerStyle}>
      <div dangerouslySetInnerHTML={{
        __html: htmlContent
      }}></div>
    </View>
  );
};

export default HtmlPreview;
