import * as React from 'react';
import {StyleSheet, View} from 'react-native';

import {Overlay, Button} from 'react-native-elements';
import ItText from '../../ui/ItText';
import {
  OverlayBody,
  OverlayCode,
  OverlayContent,
  OverlayTitle,
} from '../../ui/modules/overlays/OverlayHelpers';
import OverlayIcon from '../../ui/modules/overlays/OverlayIcon';

export interface PedidoConfirmationOverlayProps {
  isVisible: boolean;
  onConfirm: (onSuccess: Function) => void | Promise<any>;
  onHide: Function;
  items: any[];
}

const PedidoConfirmationOverlay: React.SFC<PedidoConfirmationOverlayProps> = (
  props: PedidoConfirmationOverlayProps,
) => {
  const [wasConfirmed, setConfirmed] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [data, setData] = React.useState<any>({});

  if (wasConfirmed) {
    return (
      <Overlay isVisible={props.isVisible}>
        <OverlayBody>
          <OverlayIcon name="check" />
          <OverlayTitle>Tu solicitud ha sido enviada exitosamente</OverlayTitle>
          <OverlayContent>
            Presenta el siguiente código al vendedor, para hacer efectivo el
            descuento
          </OverlayContent>
          <OverlayCode>{data?.code}</OverlayCode>
          <Button
            title="ACEPTAR"
            buttonStyle={styles.acceptBtn}
            onPress={async () => {
              setConfirmed(false);
              props.onHide();
            }}
          />
        </OverlayBody>
      </Overlay>
    );
  }
  return (
    <Overlay isVisible={props.isVisible}>
      <OverlayBody>
        <OverlayIcon name="thumbs-up" />
        <OverlayTitle>Confirmación Compra</OverlayTitle>
        <OverlayContent>
          Deseas realizar la compra de:{'\n'}
          {props.items.map((item) => (
            <View
              style={{
                /* backgroundColor: 'red', */
                maxWidth: 200,
                overflow: 'hidden',
              }}>
              <ItText style={styles.item}>
                {item.cant || 1} Unds, {item.name}({item.sales_unity}) por valor
                de <ItText secondary>${item.price}</ItText>
              </ItText>
            </View>
          ))}
        </OverlayContent>
        <Button
          title={sending ? 'ENVIANDO...' : 'CONFIRMAR'}
          loading={sending}
          onPress={async () => {
            setSending(true);
            await props.onConfirm((nextData: any) => setData(nextData));
            setSending(false);
            setConfirmed(true);
          }}
        />
        <Button
          title="CANCELAR"
          type="outline"
          style={styles.cancel}
          onPress={() => {
            props.onHide();
          }}
        />
      </OverlayBody>
    </Overlay>
  );
};

const styles = StyleSheet.create({
  item: {
    textAlign: 'center',
    marginVertical: 20,
  },
  overlayContainer: {
    paddingHorizontal: 20,
    maxWidth: 270,
    marginVertical: 20,
  },
  overlay: {
    borderRadius: 10,
  },
  cancel: {
    marginTop: 10,
  },
  acceptBtn: {
    marginTop: 30,
  },
});
export default PedidoConfirmationOverlay;
