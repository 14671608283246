import React from 'react';

import CommunityCreate from '../components/CommunityCreate';
import CommunityPostList from '../components/CommunityPostList';
import {primary, pageBackground} from '../../../theme/colors';
import {View} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';

const CommunityScreen = () => {
  return (
      <div id="comunity_post" style={{backgroundColor: pageBackground, flex: 1, height: 'calc(100vh - 90px)'}}>
        <CommunityCreate />
        <CommunityPostList />
      </div>
  );
};

CommunityScreen.navigationOptions = ({navigation}: {navigation: any}) => ({
  headerStyle: {
    // @ts-ignore
    backgroundColor: navigation.getParam('eventColor', primary),
  },
  title: 'Comunidad',
  /* headerRight: (navigation: NavigationStackProp) => (
    <View style={{ paddingRight: 10, flexDirection: "row" }}>
      <ItButtonClear
        type="clear"
        onPressIn={() => navigation.navigate("WelcomeScreen")}
        icon={
          <FontAwesome5Icon
            name="search"
            color="white"
            size={20}
          ></FontAwesome5Icon>
        }
      ></ItButtonClear>
    </View>
  ) */
});

export default CommunityScreen;
