import * as React from 'react';
import {InputProps} from 'react-native-elements';
import {GooglePlacesAutocomplete} from 'react-native-google-places-autocomplete';
import _ from 'lodash';

export interface AddressAutocompleteProps extends InputProps {
  onAddressSelected: (address: {
    address: string;
    lat: number;
    lng: number;
  }) => void;
}

const AddressAutocomplete: React.SFC<AddressAutocompleteProps> = (props) => {
  return (
    <GooglePlacesAutocomplete
      placeholder=""
      textInputProps={{
        testID: props.testID,
      }}
      fetchDetails
      onPress={(data, details) => {
        if (props.onAddressSelected) {
          props.onAddressSelected({
            address: data.description,
            lat: _.get(details, 'geometry.location.lat'),
            lng: _.get(details, 'geometry.location.lng'),
          });
        }
      }}
      query={{
        components: 'country:co',
        key: 'AIzaSyBRdm5w4YtvLwAMuxukGZp6AZvqpxDifgE',
        language: 'es',
        types: 'address',
      }}
    />
  );
};

export default AddressAutocomplete;
