import React from 'react';
import {View} from 'react-native';
import ItText from './ItText';
import {ItRoundedButton} from './buttons';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';

interface NetworkErrorViewProps {
  retryCallback?: Function;
}
const NetworkErrorView: React.FC<NetworkErrorViewProps> = ({
  retryCallback,
}: NetworkErrorViewProps) => {
  return (
    <View
      style={{
        minHeight: 200,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
      }}>
      <FontAwesome5Icon name="danger" size={100} />
      <View style={{marginBottom: 10}}>
        <ItText
          size={18}
          lightgray
          style={{fontWeight: '300', textAlign: 'center', fontSize: 14}}>
          Error conectando al servidor, por favor revisa tu conexión a internet`
          e intenta de nuevo
        </ItText>
      </View>
      <ItRoundedButton
        onPress={(): void => retryCallback && retryCallback()}
        title="INTENTAR DE NUEVO"
        icon={
          <FontAwesome5Icon
            size={16}
            style={{marginRight: 10}}
            name="redo"
            color="white"
          />
        }
        style={{marginTop: 20}}
      />
    </View>
  );
};

export default NetworkErrorView;
