import * as React from 'react';
import {Dimensions} from 'react-native';
import {Image, ImageBackground, View} from 'react-native';
import {Overlay} from 'react-native-elements';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import ItImageAutoHeight from '../../../../components/ItImageAutoHeight';
import {Space} from '../../../../theme';
import ButtonsBar from '../buttons/ButtonsBar';

export interface ImagePreviewProps {
  uri: string;
  onRemove: Function;
}

const ImagePreview: React.FunctionComponent<ImagePreviewProps> = (
  props: ImagePreviewProps,
) => {
  const [zoomEnabled, setZoomEnabled] = React.useState(false);
  return (
    <>
      <View
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          marginVertical: Space.LARGE,
        }}>
        <ImageBackground
          source={{uri: props.uri}}
          style={{
            width: 160,
            height: 120,
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,

            elevation: 5,
            backgroundColor: 'white',
          }}>
          <ButtonsBar
            style={{
              position: 'absolute',
              bottom: 0,
            }}
            buttons={[
              {
                iconName: 'search-plus',
                title: 'Acercar',
                id: '1',
                onPress: () => {
                  setZoomEnabled(true);
                },
              },
              {
                iconName: 'trash-alt',
                title: 'Eliminar',
                id: '2',
                onPress: () => {
                  props.onRemove();
                },
              },
            ]}
          />
        </ImageBackground>
      </View>
      <Overlay
        overlayStyle={{
          padding: 0,
        }}
        isVisible={zoomEnabled}
        onBackdropPress={() => setZoomEnabled(false)}
        onDismiss={() => setZoomEnabled(false)}>
        <View>
          <FontAwesome5Icon
            name="times"
            color="white"
            onPress={() => setZoomEnabled(false)}
            size={24}
            style={{
              right: 15,
              position: 'absolute',
              zIndex: 999,
              top: 15,
            }}
          />
	  <Image style={{
	    minWidth: Dimensions.get('screen').width * 0.9,
	     aspectRatio: 16/9 }} source={{uri: props.uri}} />
        </View>
      </Overlay>
    </>
  );
};

export default React.memo(ImagePreview);
