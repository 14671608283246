import * as React from 'react';
import {Dimensions, View} from 'react-native';
import {BarCodeReadEvent} from 'react-native-camera';
import {primary} from '../../../theme';
const {width} = Dimensions.get('screen');

export interface MeikoQrScanProps {
  onScan: (e: BarCodeReadEvent) => void;
}

const ASPECT_RATIO = 1;

const MeikoQrScan: React.FunctionComponent<MeikoQrScanProps> = ({
  onScan,
}: MeikoQrScanProps) => {
  const [componentWidth, setComponentWidth] = React.useState(width - 60);
  const componentHeight = React.useMemo(() => componentWidth * ASPECT_RATIO, [
    componentWidth,
  ]);
  return (
    <View
      style={{
        width: '100%',
        height: componentHeight,
        alignItems: 'center',
        marginVertical: 30,
        backgroundColor: 'white',
      }}
      onLayout={(event) => {
        setComponentWidth(event.nativeEvent.layout.width);
      }}>
    </View>
  );
};

export default React.memo(MeikoQrScan);
