import * as React from 'react';
import {InputProps, Input} from 'react-native-elements';
import {s} from 'react-native-size-matters';
import env from 'react-native-config';

export const ItInput: React.FC<InputProps> = (props: InputProps) => {
  return (
    <Input
      testID={props.testID}
      labelStyle={[
        {
          fontSize: s(12),
          color: '#3F3F3F',
          fontWeight: '400',
          fontFamily: env.MAIN_FONT,
        },
        props.labelStyle,
      ]}
      inputStyle={[
        {
          fontFamily: env.MAIN_FONT,
        },
        props.inputStyle,
      ]}
      placeholderTextColor="#ccc"
      {...props}
    />
  );
};

export const ItInputBox: React.FC<any> = ({
  inputContainerStyle,
  ...props
}: InputProps) => {
  return (
    <ItInput
      labelStyle={{
        fontSize: s(14),
        color: '#3F3F3F',
        marginBottom: 7,
        fontWeight: 'bold',
      }}
      inputContainerStyle={[
        {
          borderWidth: 1,
          borderColor: 'rgba(112, 112, 112, 0.47)',
          paddingVertical: 0,
          paddingHorizontal: 10,
          marginHorizontal: -10,
        },
        inputContainerStyle,
      ]}
      {...props}
    />
  );
};
