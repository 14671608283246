import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

export interface shopperIconProps {}

const ShopperIcon: React.FunctionComponent<shopperIconProps> = () => {
  return (
    <Svg width="30.686" height="27.277" viewBox="0 0 30.686 27.277">
      <Path
        id="bags-shopping-light"
        d="M28.981,13.638H11.934a1.7,1.7,0,0,0-1.7,1.7V25.572a1.7,1.7,0,0,0,1.7,1.7H28.981a1.7,1.7,0,0,0,1.7-1.7V15.343A1.7,1.7,0,0,0,28.981,13.638Zm0,11.934H11.934V15.343H28.981Zm-9.2-1.748a5.121,5.121,0,0,0,5.787-5.071V17.474a.426.426,0,0,0-.426-.426h-.852a.426.426,0,0,0-.426.426v1.279a3.413,3.413,0,0,1-3.749,3.393,3.512,3.512,0,0,1-3.07-3.544V17.474a.426.426,0,0,0-.426-.426h-.852a.426.426,0,0,0-.426.426v1.1A5.234,5.234,0,0,0,19.785,23.824ZM1.7,10.229H22.162v1.7h1.7v-1.7a1.7,1.7,0,0,0-1.7-1.7H17.048V5.114a5.114,5.114,0,1,0-10.229,0v3.41H1.7a1.7,1.7,0,0,0-1.7,1.7V23.867a1.7,1.7,0,0,0,1.7,1.7H8.524v-1.7H1.7ZM8.524,5.114a3.41,3.41,0,1,1,6.819,0v3.41H8.524Z"
        fill="#eb3810"
      />
    </Svg>
  );
};

export default ShopperIcon;
