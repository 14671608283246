import * as React from 'react';
import {useNavigation, useRoute} from '@react-navigation/native';
import {useFetch} from 'use-http';
import {API_HOST} from '../../../env';
import {Dimensions, Image, ScrollView, StyleSheet, useWindowDimensions, View} from 'react-native';
import ItText from '../../ui/ItText';
import {Button} from 'react-native-elements';
import CampaignHeader from '../components/CampaignHeader';
import BottomHeader from '../../ui/modules/cards/BottomHeader';
import HtmlPreview from '../../../components/HtmlPreview';
import useImageDimensions from '../../images/hooks/useImageDimensions';
import {Space} from '../../../theme';
import {wasExecutedCampaign} from '../utils/wasExecuted';

export interface CampaignDetailProps {}

const { width } = Dimensions.get("screen");
const CampaignDetail: React.FunctionComponent<CampaignDetailProps> = () => {
  const route = useRoute<any>();
  const navigation = useNavigation();
  const {data = {}, loading} = useFetch(
    `${API_HOST}/api/app/campaign/${route.params?.id}`,
    {},
    [],
  );
  const {data: item = {}} = data;

  
  const {aspectRatio} = useImageDimensions(item.photo);
  const wasExecuted = wasExecutedCampaign(item);
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CampaignHeader item={item} />
      {!!item.photo && (
        <Image
          style={[
            styles.image,
            {
              aspectRatio,
              width,
            },
          ]}
          source={{uri: item.photo}}
        />
      )}
      <BottomHeader>
        <ItText strong>Descripción Encuesta</ItText>
        <View style={styles.description}>
          <HtmlPreview htmlContent={item.description} />
        </View>
        <Button
          title={wasExecuted ? 'VER RESPUESTAS' : 'REALIZAR ENCUESTA'}
          style={styles.button}
          loading={loading}
          onPress={() => {
            const nextRoute = wasExecuted
              ? 'CampaignExecutedScreen'
              : 'CampaignExe';
            navigation.navigate(nextRoute, {
              item,
            });
          }}
        />
      </BottomHeader>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: Space.MEDIUM,
  },
  image: {
    marginTop: Space.MEDIUM,
  },
  container: {
    flex: 1,
    paddingBottom: 100
  },
  description: {
    marginBottom: Space.MEDIUM,
  },
});

export default CampaignDetail;
