import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import htmlToText from '../../../utils/htmlToText';
import getLabelColor from '../utils/getLabelColor';
import getLabelExecution from '../utils/getLabelExecution';
import {CommercialAction} from './../entities/commercialAction';

export default function useCampaignInfoForType(item: CommercialAction) {
  const dateExpire = _.cond([
    [
      (i: any) => i.campaigns.length > 0,
      (i: any) => dayjs(i.campaigns[0].end).format('ddd D MMM'),
    ],
    [
      (i: any) => i.challenges.length > 0,
      (i: any) => dayjs(i.challenges[0].end).format('ddd D MMM'),
    ],
  ])(item);

  const name = _.cond([
    [(i: any) => i.campaigns.length > 0, (i) => i.campaigns[0].name],
    [(i: any) => i.challenges.length > 0, (i) => i.challenges[0].name],
  ])(item);

  const photo = _.cond([
    [(i: any) => i.campaigns.length > 0, (i) => i.campaigns[0].photo],
    [
      (i: any) => i.challenges.length > 0,
      (i: any) => i.challenges[0].promotional_image,
    ],
  ])(item);

  const description = htmlToText(
    _.cond([
      [(i: any) => i.campaigns.length > 0, (i) => i.campaigns[0].description],
      [(i: any) => i.challenges.length > 0, (i) => i.challenges[0].description],
    ])(item),
  );

  const executionLabel = getLabelExecution(item);
  const labelColor = getLabelColor(item);

  const isCampaign = item.campaigns?.length > 0;
  const isChallenge = item.challenges?.length > 0;
  const shortDescription =
    description.length > 90 ? `${description.substr(1, 90)}...` : description;

  return React.useMemo(
    () => ({
      isCampaign,
      isChallenge,
      executionLabel,
      description,
      photo,
      name,
      dateExpire,
      labelColor,
      shortDescription,
    }),
    [
      isCampaign,
      isChallenge,
      description,
      photo,
      name,
      dateExpire,
      labelColor,
      executionLabel,
      shortDescription,
    ],
  );
}
