import * as React from 'react';
import {View} from 'react-native';
import {BaseButton} from 'react-native-gesture-handler';
import {s, vs} from 'react-native-size-matters';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import styled from 'styled-components/native';
import {Block, SeparatorList} from '../../../components/views';
import UserPoints from '../../home/components/UserPoints';
import ItText from '../../ui/ItText';

export interface LoyaltyResumeCardProps {
  linkLabel: string;
  linkIconName: string;
  onPressLabel: () => void;
}

const LoyaltyResumeCard: React.FunctionComponent<LoyaltyResumeCardProps> = (
  props: LoyaltyResumeCardProps,
) => {
  return (
    <BgImage style={{
      marginHorizontal: 40
      }} source={require('../assets/bg_ptr.png')}>
      <Block style={{
      }} justifySpaceBetween>
        <View>
          <ItText strong size={16} white>
            Sus Puntos acumulados
          </ItText>
          <Subtitle white>Úselos como el efectivo</Subtitle>
        </View>
        <StyledUserPoints adjustsFontSizeToFit />
      </Block>
      <StyledSeparator />
      <PointStatusTouchable
        rippleColor="rgba(0,0,0,0.2)"
        onPress={props.onPressLabel}>
        <PointStatusLink white>{props.linkLabel}</PointStatusLink>
        <FontAwesome5Icon
          size={vs(19)}
          color="white"
          light
          name={props.linkIconName}
        />
      </PointStatusTouchable>
    </BgImage>
  );
};

export default LoyaltyResumeCard;

const BgImage = styled.ImageBackground`
  width: 97%;
  height: ${s(140)}px;
  margin-top: 30px;
  padding-horizontal: ${s(20)}px
  justify-content: center;
  align-self: center;
`;

const Subtitle = styled(ItText)`
  font-size: ${s(14)}px;
  font-weight: 300;
`;

const PointStatusLink = styled(ItText)`
  underline-color: white;
  font-size: ${s(16)};
  font-weight: bold;
  padding-right: ${7}px;
`;

const PointStatusTouchable = styled(BaseButton)`
  flex-direction: row;
  align-items: center;
`;

const StyledUserPoints = styled(UserPoints)`
  color: white;
  margin-top: ${10};
  margin-bottom: ${-15};
  font-size: ${s(40)}px;
  font-weight: bold;
`;

const StyledSeparator = styled(SeparatorList)`
  margin-bottom: ${s(10)};
  margin-top: ${s(23)};
`;
