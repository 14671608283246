import * as React from 'react';
import {StyleSheet, ViewStyle} from 'react-native';
import {RectButton} from 'react-native-gesture-handler';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import ItText from '../../../../components/ItText';
import {Block} from '../../../../components/views';
import {secondary} from '../../../../theme';

interface Button {
  id: string;
  title: string;
  iconName: string;
  onPress: Function;
}

export interface ButtonsBarProps {
  buttons: Button[];
  style: ViewStyle;
}

const ButtonsBar: React.FunctionComponent<ButtonsBarProps> = ({
  buttons,
  style,
}: ButtonsBarProps) => {
  return (
    <Block style={[styles.container, style]}>
      {buttons.map((button: Button, index: number) => (
        <RectButton
          onPress={() => button.onPress()}
          style={[
            styles.item,
            index + 1 == buttons.length && {borderRightWidth: 0},
          ]}>
          <FontAwesome5Icon
            color={secondary}
            size={12}
            name={button.iconName}
          />
          <ItText>
            {'  '}
            {button.title}
          </ItText>
        </RectButton>
      ))}
    </Block>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    width: '100%',
  },
  item: {
    flexDirection: 'row',
    flex: 1,
    marginVertical: 4,
    borderRightWidth: 1,
    borderRightColor: '#BAB9C1',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemTitle: {
    color: '#787681',
    fontSize: 12,
  },
});

export default ButtonsBar;
