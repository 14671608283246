// @ts-nocheck
import {persistStore, persistReducer} from 'redux-persist';
import AsyncStorage from '@callstack/async-storage';
import thunk, {ThunkAction} from 'redux-thunk';
import reducers from './reducers';
import {createStore, applyMiddleware, Action} from 'redux';
import {State as ShoppingCartState} from './modules/shoppingCart/state/ShoppingCartState';

// Generated by https://quicktype.io

export interface ItAppState {
  auth: Auth;
  shoppingCart: ShoppingCartState<Product>;
  campaingsImages: campaingImagesState;
  _persist: Persist;
}

export interface Persist {
  version: number;
  rehydrated: boolean;
}

export interface Auth {
  user: ItUser;
}

export interface ItUser {
  id: number;
  meiko_id: null;
  terms_accepted: boolean;
  terms: {
    terms: string;
  };
  name: string;
  photo: string;
  whatsapp: string;
  open_from: string;
  open_until: string;
  fijo: string;
  home_delivery_conditions: string;
  last_name: string;
  url: string;
  document_type_id: number;
  document: string;
  prefix: null;
  phone: string;
  home_delivery: boolean;
  avatar: string;
  email: string;
  uuid: string;
  country_id: number;
  city_id: number;
  store_channel_id: number;
  store_name: string;
  address: string;
  aditional_info: string;
  ip: string;
  canal: string;
  signature: null;
  email_verified_at: string;
  status: number;
  first_login: number;
  recovery_code: string;
  date_password_change: string;
  miq: number;
  test_pdv: number;
  inmov_agent_id: null;
  lat: null;
  lon: null;
  created_at: string;
  updated_at: string;
  deleted_at: null;
}

export type ItThunkAction = ThunkAction<
  Promise<void>,
  ItAppState,
  undefined,
  Action
>;

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  version: 2,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const middlewares = [thunk];



export const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares),
);

const persistor = persistStore(store);
export default {persistor, store};
