import React from "react";
import useFetch from "use-http";
import {API_HOST} from "../../../env";

const isUnreaded = (item: any) => {
  const isCampaign = item.campaigns.length > 0;
  if (isCampaign) {
    return item?.campaigns?.[0]?.questions?.[0]?.answers?.length == 0;
  }
  return item?.challenges?.[0]?.executions?.length == 0;
}

export default function usePendingCamapaigns() {
  const {
    data = {
      data: [],
    },
  } = useFetch(`${API_HOST}/api/app/comercial/action?page=1&pageSize=2000`, {}, [])
  const unreaded = React.useMemo(() => {
    return data?.data?.filter?.(isUnreaded).length
  }, [data]);

  if (unreaded == 0) {
    return null;
  }
  return unreaded;
}
