import * as React from 'react';
import {produce} from 'immer';
import _ from 'lodash';
import {Product} from '../../entities/Product';
import {
  ActionTypes,
  SET_CATEGORIES,
  SET_LAST_PAGE,
  SET_MAKERS,
  SET_NAME,
  SET_PAGE,
  TOGGLE_CATEGORY_VALUE,
  TOGGLE_MAKER_VALUE,
} from './redemptionListActionTypes';

interface State {
  page: number;
  lastPage: number;
  name: string;
  categories: number[];
  makers: number[];
  shoppingCart: {
    product: Product;
    quantity: number;
  }[];
}

export const initialState: State = {
  page: 1,
  lastPage: 1,
  name: '',
  categories: [],
  makers: [],
  shoppingCart: [],
};

export function redemptionReducer(state: State, action: ActionTypes) {
  if (action.type === SET_PAGE) {
    return produce(state, (draft) => {
      draft.page = action.payload.page;
    });
  }
  if (action.type === SET_LAST_PAGE) {
    return produce(state, (draft) => {
      draft.lastPage = action.payload.lastPage;
    });
  }
  if (action.type === SET_NAME) {
    return produce(state, (draft) => {
      draft.name = action.payload.name;
    });
  }
  if (action.type === SET_MAKERS) {
    return produce(state, (draft) => {
      draft.makers = action.payload.values;
    });
  }
  if (action.type === SET_CATEGORIES) {
    return produce(state, (draft) => {
      draft.makers = action.payload.values;
    });
  }
  if (action.type === TOGGLE_MAKER_VALUE) {
    return produce(state, (draft) => {
      draft.makers = _.xor(draft.makers, [action.payload.value]);

      // Resetear paginación
      draft.page = 1;
    });
  }
  if (action.type === TOGGLE_CATEGORY_VALUE) {
    return produce(state, (draft) => {
      draft.categories = _.xor(draft.categories, [action.payload.value]);
      // Resetear paginación
      draft.page = 1;
    });
  }
  return state;
}

export const RedemptionContext = React.createContext<{
  state: State;
  dispatch: any;
}>({
  state: initialState,
  dispatch: () => {},
});
