import * as React from 'react';
import styled from 'styled-components/native';
import LoyaltyResumeCard from '../components/LoyaltyResumeCard';
import LoyaltyTabs from '../components/LoyaltyTabs';
import StyledCard from '../../../components/StyledCard';
import {useNavigation} from '@react-navigation/native';

export interface LoyaltyResumeScreenProps {}

const LoyaltyResumeScreen: React.FunctionComponent<LoyaltyResumeScreenProps> = () => {
  const navigation = useNavigation();

  return (
    <PageContainer keyboardShouldPersistTaps="always">
      <LoyaltyResumeCard
        linkLabel="Estado Puntos"
        linkIconName="gem"
        onPressLabel={() => {
          navigation.navigate('LoyaltyPointsDetailScreen');
        }}
      />
      <StyledCard
        containerStyle={{
          minHeight: 200,
        }}>
        <LoyaltyTabs />
      </StyledCard>
    </PageContainer>
  );
};

const PageContainer = styled.ScrollView`
  min-height: 100;
`;

export default LoyaltyResumeScreen;
