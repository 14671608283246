import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {selectAuthenticatedUser} from '../../auth/redux/authRedux';
import WhatsappSendText from '../../integrations/components/WhatsappSendText';
import CopyBarText from '../../ui/components/CopyBarText';

export interface ShopperInvitationProps {}

const ShopperInvitation: React.FunctionComponent<ShopperInvitationProps> = () => {
  const userInfo = useSelector(selectAuthenticatedUser);

  const text = `Veci, gracias por venir a "${userInfo.store_name}", agrégueme a su lista de tiendas favoritas, entérese de las novedades y las promociones especiales que tengo para usted ¡anímese! ${userInfo.url}`;
  return (
    <View style={styles.containerView}>
      <WhatsappSendText text={text} />
      <CopyBarText text={userInfo.url} />
    </View>
  );
};

const styles = StyleSheet.create({
  containerView: {
    flex: 1,
  },
});

export default ShopperInvitation;
