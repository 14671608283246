export const ADD_ITEM = 'SHOPPING_CART/ADD_ITEM';
export const TOGGLE_SHOPPING_CART = 'SHOPPING_CART/TOGGLE_SHOPPING_CART';
export const SET_QUANTITY = 'SHOPPING_CART/SET_QUANTITY_ACTION';
export const REMOVE_PRODUCT = 'SHOPPING_CART/REMOVE_PRODUCT_ACTION';
export const REMOVE_ALL_PRODUCTS = 'SHOPPING_CART/REMOVE_ALL_PRODUCTS_ACTION';
export const HIDE_SHOPPING_CART = 'SHOPPING_CART/HIDE_SHOPPING_CART_ACTION';

export interface AddItemAction<T> {
  type: typeof ADD_ITEM;
  payload: {
    id: number;
    product: T;
  };
}

export interface ToggleShoppingCartAction {
  type: typeof TOGGLE_SHOPPING_CART;
}

export interface SetQuantityAction {
  type: typeof SET_QUANTITY;
  payload: {
    quantity: number;
    id: number;
  };
}

export interface RemoveAllProductsAction {
  type: typeof REMOVE_ALL_PRODUCTS;
}

export interface RemoveProductAction {
  type: typeof REMOVE_PRODUCT;
  payload: {
    id: number;
  };
}

export interface HideShoppingCartAction {
  type: typeof HIDE_SHOPPING_CART;
}

export type shoppingCartActionTypes<T> =
  | AddItemAction<T>
  | ToggleShoppingCartAction
  | SetQuantityAction
  | RemoveAllProductsAction
  | HideShoppingCartAction
  | RemoveProductAction;
