// @ts-nocheck
import {useNavigation, useRoute} from '@react-navigation/native';
import * as React from 'react';
import {Card} from 'react-native-elements';
import ItText, {SectionTitle} from '../../../components/ItText';
import Toast from 'react-native-root-toast-updated';
import {API_HOST} from '../../../env';
import useFetch from 'use-http';
import {Image} from 'react-native';
import RequestMeikoCode from '../components/RequestMeikoCode';
import MeikoQrScan from '../components/MeikoQrScan';
import _ from 'lodash';
import {ScrollView} from 'react-native-gesture-handler';

export interface AuthMeikoCodeProps {}

const AuthMeikoCode: React.FunctionComponent<AuthMeikoCodeProps> = () => {
  const {post: signupRequest, cache} = useFetch(
    `${API_HOST}/api/oauth/pdv/register`,
  );
  const route = useRoute<any>();
  const {values} = route.params;
  const navigation = useNavigation();

  const registrateUser = React.useCallback(
    async (e: {data: string}) => {
      try {
        cache.clear();
        const res = await signupRequest({
          ...values,
          meiko_id: e.data,
        });
        if (res.errors) {
          const error: string =
            _.first(_.first(_.values(res.errors))) ||
            'Error conectando con el servidor';
          Toast.show(error, {
            backgroundColor: 'red',
          });
          return;
        }
        Toast.show('Usuario creado correctamente', {
          backgroundColor: 'green',
        });
        navigation.navigate('auth.login');
      } catch (ex) {
        Toast.show('Error completando la petición', {
          backgroundColor: 'red',
        });
      }
    },
    [cache, navigation, signupRequest, values],
  );

  return (
    <ScrollView>
      <Card>
        <SectionTitle>4 - Encuentre su sticker Meiko</SectionTitle>
        <ItText
          style={{
            color: 'black',
            textAlign: 'center',
          }}>
          Deberá estar ubicado en el marco de la puerta, junto al contador de
          luz o ventanas.
        </ItText>
        <Image
          style={{width: '100%', resizeMode: 'contain'}}
          source={require('../assets/qr_example.jpg')}
        />
        <SectionTitle>5 - Escanee el código QR</SectionTitle>
        <ItText
          style={{
            color: 'black',
            marginBottom: 30,
            textAlign: 'center',
          }}>
          Utilice este escaner para enfocar con la cámara únicamente el código.
        </ItText>
        <MeikoQrScan onScan={registrateUser} />
        <SectionTitle>6 - Solicite un nuevo sticker</SectionTitle>
        <ItText
          style={{
            color: 'black',
            marginBottom: 50,
            textAlign: 'center',
          }}>
          En caso de no tener el sticker/adhesivo de Meiko o que este no sea
          legible, solicite uno nuevo activando la siguiente casilla.
        </ItText>
        <RequestMeikoCode values={values} />
      </Card>
    </ScrollView>
  );
};

export default AuthMeikoCode;
