import {StackNavigationState} from '@react-navigation/native';
import {StackScreenProps} from '@react-navigation/stack';
import React from 'react';
import RedemptionsContainer from '../modules/redemptions/components/RedemptionsContainer';

const RedeemCatalogScreen = () =>{
  return (
    <RedemptionsContainer />
  );
}

// @ts-ignore
RedemptionsContainer.navigationOptions = {
  title: 'Redimir',
};

export default RedeemCatalogScreen;

