import * as React from 'react';
import {
  Image,
  ImageBackground,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import {Divider, ListItem} from 'react-native-elements';
import Community from '../assets/community.svg';
import ProfileSvg from '../assets/profile.svg';
import UniversitySVG from '../assets/university.svg';
import NewsSvg from '../assets/NEWS.svg';
import {
  actionTypes,
  getMe,
  selectAuthenticatedUser,
} from '../../auth/redux/authRedux';
import {useDispatch} from 'react-redux';
import AuthUserInfoView from '../../auth/components/AuthUserInfoView';
import {useSelector} from 'react-redux';
import UserPoints from '../../home/components/UserPoints';
import {primary, secondary, Space} from '../../../theme/index';
import ItText from '../../ui/ItText';
import {Block} from '../../../components/views';
import DrawerMenuView from './DrawerMenuView';
import {useNavigation} from '@react-navigation/native';
import OverlayConfirmation from '../../../components/OverlayConfirmation';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';

export interface DrawerProps {}

const Drawer: React.FunctionComponent<DrawerProps> = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectAuthenticatedUser);
  const navigation = useNavigation();
  const [isOpenConfirm, setConfirm] = React.useState(false);

  React.useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  return (
    <ScrollView style={{flex: 1}}>
      <img style={{ background: '#FF801F'}} src="/logo_drawer.svg" />
      <Image
        style={{
          alignSelf: 'center',
        }}
        source={require('../../auth/assets/logo.png')}
      />
      <View
        style={[
          {
            backgroundColor: 'white',
            alignSelf: 'center',
            flex: 1,
            width: '100%',
          },
        ]}>
        <AuthUserInfoView
          profile={userInfo}
          subtitleElement={
            <Block
              style={{
                backgroundColor: '#FF801F',
                marginTop: Space.SMALL,
                borderRadius: Space.SMALL,
                justifyContent: 'center',
                width: 122,
              }}>
              <UserPoints
                adjustsFontSizeToFit
                style={{
                  fontSize: 24,
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              />
              <ItText adjustsFontSizeToFit white size={24} strong>
                {' '}
                Pts
              </ItText>
            </Block>
          }
        />
        <ImageBackground
          source={require('../assets/bg.png')}
          style={{
            width: '100%',
            flex: 1,
            backgroundColor: 'white',
            marginBottom: 53,
          }}>
          <View
            style={{
              width: '100%',
              flex: 1,
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}>
            <DrawerMenuView
              onPress={() => {
                navigation.navigate('AuthCompleteProfileScreen');
              }}
              title="MI PERFIL"
              subtitle="Edita y actualiza"
              iconElement={<ProfileSvg height={40} />}
            />
            <DrawerMenuView
              onPress={() => {
                navigation.navigate('ComingSoonScreen');
              }}
              title="UNIVERSIDAD SUPERVECINO"
              subtitle=""
              iconElement={<UniversitySVG height={40} />}
            />
            <DrawerMenuView
              onPress={() => {
                navigation.navigate('ComingSoonScreen');
              }}
              title="HACER CRECER MI NEGOCIO"
              subtitle=""
              iconElement={
                <FontAwesome5Icon
                  name="chart-line"
                  size={40}
                  light
                  color={primary}
                />
              }
            />
            <DrawerMenuView
              onPress={() => {
                navigation.navigate('tabs', {
                  screen: 'Retos',
                });
              }}
              title="GANA CON TUS FABRICANTES"
              subtitle=""
              iconElement={
                <FontAwesome5Icon
                  name="star"
                  size={40}
                  light
                  color={primary}
                />
              }
            />
            <DrawerMenuView
              onPress={() => {
                navigation.navigate('News');
              }}
              title="NOTICIAS"
              subtitle="Actualízate"
              iconElement={<NewsSvg height={40} />}
            />
            <DrawerMenuView
              onPress={() => {
                //navigation.navigate('ShoppersMenu');
                navigation.navigate('ComingSoonScreen');
              }}
              title="SÚPER CLIENTES"
              subtitle=""
              iconElement={
                <FontAwesome5Icon
                  name="users"
                  size={40}
                  light
                  color={primary}
                />
              }
            />
            <DrawerMenuView
              onPress={() => {
                navigation.navigate('ComingSoonScreen');
              }}
              title="Notificaciones"
              subtitle="Notificaciones"
              iconElement={
                <FontAwesome5Icon
                  name="bell"
                  size={40}
                  light
                  color={primary}
                />
              }
            />
            <DrawerMenuView
              onPress={() => {
                navigation.navigate('Community');
              }}
              title="COMUNIDAD"
              subtitle="Participa y gana"
              iconElement={<Community height={40} />}
            />
	    <DrawerMenuView
              onPress={() => {
                navigation.navigate('SupportScreen');
              }}
              title="AYUDA/SOPORTE"
              subtitle="Contactanos"
	  iconElement={<FontAwesome5Icon color={primary} name="comment" size={40} />}
            />
          </View>
        </ImageBackground>
      </View>
      <OverlayConfirmation
        onConfirm={() => {
          dispatch({
            type: actionTypes.USER_LOGOUT,
          });
        }}
        title="Confirmar"
        isOverlayOpen={isOpenConfirm}
        setIsOverlayOpen={setConfirm}
        text="Esta seguro que desea salir"
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  item: {
    backgroundColor: '#67AD2B',
  },
  name: {
    color: 'white',
    fontSize: 21,
    fontWeight: 'bold',
    marginVertical: 20,
    marginHorizontal: 15,
  },
  title: {
    color: 'white',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
});

export default Drawer;
