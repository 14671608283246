import React from 'react';
import {Card} from 'react-native-elements';
import {View, Alert, Image} from 'react-native';
import ItText from '../../../components/ItText';
import {connect, useSelector} from 'react-redux';

import {Block} from '../../../components/views';
import dayjs from 'dayjs';
import Menu, {MenuItem} from 'react-native-material-menu';

import PostsInCardCounters from '../../posts/components/PostsInCardCounters';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {communityDelete} from '../api/communityApi';
import Toast from 'react-native-root-toast-updated';
import {RectButton} from 'react-native-gesture-handler';
import CardWithInteractionsBar from '../../../components/CardWithInteractionsBar';
import useFetch from 'use-http';
import {selectAuthenticatedUser} from '../../auth/redux/authRedux';
import {primary} from '../../../theme';
import {useNavigation} from '@react-navigation/native';
import ImageFromNetwork from '../../images/components/ImageFromNetwork';

interface Props {
  item: any;
  user: any;
  onRemove: Function;
}

const CommunityPostCard = ({item, onRemove}: Props) => {
  const [likesCount, setLikesCount] = React.useState(item.likes);
  const menuRef = React.useRef<typeof Menu>(null);
  /* const [commentsCount, setCommentsCount] = React.useState(item.comments_count); */
  const [meLike, setMeLike] = React.useState(item.i_like_it);
  const eventColor = primary;
  const userInfo = useSelector(selectAuthenticatedUser);
  const navigation = useNavigation();

  const {
    post: handleLike,
    loading: sendingLike,
    response: responseLike,
  } = useFetch(`/api/app/community/${item.id}`, {
    method: 'post',
  });

  return (
    <Card
      containerStyle={{flex: 1}}
      /* imageProps={{
        ImageComponent: ItImageAutoHeight
      }} */
    >
      <>
        <>
          <Block
            style={{
              paddingHorizontal: 10,
              paddingVertical: 10,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Block alignCenter>
              <View style={{width: 36, marginRight: 10}}>
                <Image
                  source={{
                    uri: item.pdv.avatar,
                  }}
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: 16,
                  }}
                  resizeMode="cover"
                />
              </View>
              <View style={{flex: 1}}>
                <ItText primary strong style={{fontSize: 14}}>
                  {item.pdv.name}
                </ItText>
                <ItText lightgray size={10} style={{color: '#878787'}}>
                  {/* @ts-ignore*/}
                  {dayjs(item.created_at).fromNow()}
                </ItText>
              </View>
            </Block>
            <View
              style={{
                position: 'absolute',
                top: 10,
                zIndex: 1,
                right: 5,
              }}>
              {item.pdv.id == userInfo.id && (
                <Menu
                  ref={menuRef}
                  button={
                    <div
                      style={{
                        padding: 10,
                      }}
                      onClick={() => {
                        if (menuRef.current !== null) {
                          menuRef.current.show();
                        }
                      }}>
                      <FontAwesome5Icon
                        color="black"
                        size={20}
                        name="ellipsis-v"
                      />
                    </div>
                  }>
                  <MenuItem
                    onPress={async () => {
		     console.log('Pasa');
		     const con = window.confirm('Esta a punto de borrar una publicación')
  		     if (con) {
		     try {
                                await communityDelete(item.id);
                                onRemove();
                                Toast.show('Publicación removida', {
                                  backgroundColor: 'green',
                                });
                                menuRef.current?.hide();
                              } catch (ex) {
                                Toast.show(ex.message, {
                                  backgroundColor: 'red',
                                });
                              }
		     } else {
                              menuRef.current?.hide();
		     }
                                          }}>
                    Borrar
                  </MenuItem>
                </Menu>
              )}
            </View>
          </Block>
        </>
        {!item.photo ? (
          <View style={{marginTop: 50, height: 30, width: '100%'}} />
        ) : (
          <img src={item.photo} />
        )}
        <PostsInCardCounters
          commentsCount={item.comments.length}
          likesCount={likesCount}
        />
        <View
          style={{
            padding: 10,
            marginBottom: 20,
          }}>
          <ItText>{item.description}</ItText>
        </View>
        <CardWithInteractionsBar
          handleLike={async () => {
            await handleLike();
            if (!responseLike.ok) {
              Alert.alert('error');
            }
          }}
          handleComment={() => {
            navigation.navigate('CommunityCommentsScreen', {
              eventColor: eventColor,
              postId: item.id,
            });
          }}
          likesCount={likesCount}
          isLiked={meLike}
          setIsLiked={setMeLike}
          isSendingLike={sendingLike}
          setLikesCount={setLikesCount}
        />
      </>
    </Card>
  );
};
const mapStateToProps = (state: any) => {
  return {
    user: selectAuthenticatedUser(state),
  };
};

export default connect(mapStateToProps)(React.memo(CommunityPostCard));
