import * as React from 'react';
import {QuestionComponentProps} from './QuestionRenderer';
import {CheckBox, Input} from 'react-native-elements';
import concat from 'lodash/concat';
import cond from 'lodash/cond';
import some from 'lodash/some';
import stubTrue from 'lodash/stubTrue';
import reject from 'lodash/reject';
import {View} from 'react-native';
import _ from 'lodash';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import { primary } from '../../../theme';

const MultipleResponse = (props: QuestionComponentProps) => {
  const isCheckedByOptionId = React.useCallback(
    (id: string | number) => {
      return props.answers.some((a) => a.campaign_question_option_id == id);
    },
    [props.answers],
  );

  return (
    <>
      {props.question.options.map((op: any) => (
        <>
          <CheckBox
            title={op.name}
            checked={isCheckedByOptionId(op.id)}
            checkedIcon={<FontAwesome5Icon color={primary} size={20} name="check-square"></FontAwesome5Icon>}
            uncheckedIcon={<FontAwesome5Icon color="#ccc" size={20} name="square"></FontAwesome5Icon>}
            onPress={() => {
              const answers = cond([
                // Si existe cambiar estado
                [
                  () =>
                    some(props.answers, ['campaign_question_option_id', op.id]),
                  () => {
                    return reject(props.answers, [
                      'campaign_question_option_id',
                      op.id,
                    ]);
                  },
                ],
                // Si no existe añadir al arreglo de respuestas
                [
                  stubTrue,
                  () =>
                    concat(props.answers, {
                      campaign_question_option_id: op.id,
                      other_which_one: '',
                      text: '',
                    }),
                ],
              ])(props.answers);

              props.setAnswers(answers);
            }}
          />
          {op.other_which_one == 1 && (
            <View
              style={{
                backgroundColor: 'rgb(250, 250, 250)',
                marginHorizontal: 10,
                marginTop: -10,
                borderColor: '#f4f4f4',
                borderWidth: 1,
              }}>
              <Input
                onChangeText={(t: string) => {
                  const nextAnswers = [...props.answers];
                  const itemIndex = nextAnswers.findIndex(
                    (a) => a.campaign_question_option_id == op.id,
                  );
                  nextAnswers[itemIndex] = {
                    ...nextAnswers[itemIndex],
                    other_which_one: t,
                  };

                  props.setAnswers(nextAnswers);
                }}
                value={
                  _.find(props.answers, {
                    campaign_question_option_id: op.id,
                  })?.other_which_one
                }
                disabled={!isCheckedByOptionId(op.id)}
                placeholder="Cual?"
              />
            </View>
          )}
        </>
      ))}
    </>
  );
};

export default MultipleResponse;
