// @ts-nocheck
import * as React from 'react';
import AsyncStorage from '@callstack/async-storage';
import {StyleSheet, View, Alert, Platform, ScrollView} from 'react-native';
import {Button, Image, Input} from 'react-native-elements';
import ItText from '../../ui/ItText';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {primary} from '../../../theme/colors';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {API_HOST} from '../../../env';
import {getMe, userInfoLoaded} from '../redux/authRedux';
import {useDispatch} from 'react-redux';
import {useNavigation, useRoute} from '@react-navigation/native';

import {StackNavigationProp} from '@react-navigation/stack';
import {AuthStackParams} from '../entities/AuthStackParams';

export interface LoginScreenProps {}


const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required('Campo requerido')
    .email('Debe ser un correo'),
  password: Yup.string().required('Campo requerido'),
});

type NavigationProp = StackNavigationProp<AuthStackParams, 'login'>;


const LoginScreen: React.SFC<LoginScreenProps> = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation<NavigationProp>();
  const route = useRoute<any>();
  const initialPassword = route.params?.tmp_pass ?? '';
  const email = route.params?.email ?? '';

  const getParam = (name: string) => {
    const url = new URL(window.location.href);
    return url.searchParams.get(name);
  };
  React.useEffect(() => {
    const param = getParam('token');
    (async () => {
        if (param) {
          await AsyncStorage.setItem('token', param);
          dispatch(getMe());
        } 
    })();
  }, []);

  return (
    <ScrollView
      testID="container"
      style={{flex: 1, backgroundColor: 'white'}}
    >
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={async (values, helpers) => {
          try {
            const res = await fetch(`${API_HOST}/api/oauth/pdv/login`, {
              method: 'POST',
              body: JSON.stringify({
                ...values,
                grant_type: 'password',
                client_id: 2,
                client_secret: 'lzcmiGDHN004aP5CVK2BPuvH4XygdNDULky744MQ',
                scope: '*',
              }),
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            });

            if (!res.ok) {
              if (res.status === 401) {
                helpers.setErrors({
                  username: 'Usuario o contraseña incorrecto',
                });
                return;
              }
              throw new Error();
            }

            const {data} = await res.json();
            dispatch(userInfoLoaded(data, navigation));
          } catch (ex) {
            Alert.alert('Error conectando con el servidor');
          }
        }}
        initialValues={{
          username: email,
          password: initialPassword,
        }}>
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
          isSubmitting,
        }) => (
          <View style={styles.container}>
            
              <Image
                source={require('../assets/logo.png')}
                style={styles.image}
              />
            <ItText style={styles.label}>Por favor, ingresa tus datos</ItText>
            <Input
              value={values.username}
              testID="username"
              onChangeText={handleChange('username')}
              onBlur={handleBlur('username')}
              errorMessage={
                (!touched.username ? '' : errors.username) as string
              }
              label="Correo Electrónico"
              inputContainerStyle={styles.inputContainer}
              keyboardType="email-address"
              autoCapitalize="none"
              rightIcon={
                <FontAwesome5Icon
                  color={primary}
                  name="envelope"
                  light
                  size={17}
                />
              }
            />
            <Input
              label="Contraseña"
              testID="password"
              value={values.password}
              errorMessage={
                (!touched.password ? '' : errors.password) as string
              }
              onChangeText={handleChange('password')}
              onBlur={handleBlur('password')}
              inputContainerStyle={styles.inputContainer}
              secureTextEntry
              containerStyle={styles.lastfield}
              autoCapitalize="none"
              passwordRules="required:digit;minlength:8"
              rightIcon={
                <FontAwesome5Icon color={primary} name="lock" light size={17} />
              }
            />
            <Button
              onPress={() => {
                navigation.navigate('EmailRecovery', {
                  email: values.username,
                });
              }}
              type="clear"
              title="Recuperar contraseña"
              buttonStyle={styles.btnForgotPassword}
            />
            <Button
              loading={isSubmitting}
              onPress={handleSubmit}
              title="INGRESAR"
              testID="submit_btn"
              buttonStyle={styles.button}
            />
            <Button
              title="CREAR CUENTA"
              testID="create_account_btn"
              type="outline"
              buttonStyle={[styles.button, {marginTop: 10}]}
              onPress={() => navigation.navigate('SignupInstructions')}
            />
          </View>
        )}
      </Formik>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    borderColor: '#D1D2D4',
    borderWidth: 1,
    paddingHorizontal: 15,
  },
  container: {
    backgroundColor: 'white',
    paddingHorizontal: 20,
    flex: 1,
  },
  image: {
    marginHorizontal: 'auto',
    marginTop: 50,
    marginBottom: 20,
    width: 170,
    height: 113
  },
  label: {
    textAlign: 'center',
    color: '#787681',
    marginBottom: 42,
  },
  button: {
    marginHorizontal: 10,
  },
  lastfield: {},
  btnForgotPassword: {
    alignSelf: 'flex-start',
    marginBottom: 30,
  },
});
export default LoginScreen;
