import * as React from 'react';
import ItText from '../../ui/ItText';
import {Challenge} from '../entities/commercialAction';
import {getChallengeLabelColor} from '../utils/getLabelColor';
import {getLabelExecutionChallenge} from '../utils/getLabelExecution';
import CommercialActionHeader from './CommercialActionHeader';
import _ from 'lodash';
import {StyleSheet, View} from 'react-native';

export interface ChallengeHeaderProps {
  item: Challenge;
}

const ChallengeHeader: React.SFC<ChallengeHeaderProps> = ({
  item,
}: ChallengeHeaderProps) => {
  const lastAudit = _.last(item.executions);
  const auditComments = _.get(lastAudit, 'audit_comments');
  const statusColor = getChallengeLabelColor(item);
  return (
    <>
      <CommercialActionHeader
        title={item.name}
        end={item.end}
        badgeColor={statusColor}
        label={getLabelExecutionChallenge(item)}
      />
      {!!auditComments && (
        <View
          style={[
            styles.auditCommentContainer,
            {backgroundColor: statusColor},
          ]}>
          <ItText white strong size={18} style={styles.auditCommentText}>
            Comentarios:
          </ItText>
          <ItText white size={16} style={styles.auditCommentText}>
            {auditComments}
          </ItText>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  auditCommentContainer: {
    paddingVertical: 10,
    marginTop: 20,
    paddingHorizontal: 20,
  },
  auditCommentText: {},
});

export default React.memo(ChallengeHeader);
