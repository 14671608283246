// @ts-nocheck
import {Theme} from 'react-native-elements';
import {primary, secondary} from './theme/colors';

export const theme: Theme = {
  colors: {
    primary: '#FF801F',
    platform: {
      web: {
        error: "#f44336",
        grey: "rgba(0, 0, 0, 0.54)",
        primary: '#FF801F',
        secondary: secondary,
        success: "#4caf50",
        warning: "#ffeb3b"
      }
    }
  },
  Overlay: {
    overlayStyle: {
      borderRadius: 10,
    },
  },
  Button: {
    buttonStyle: {
      /* backgroundColor: primary, */
      borderRadius: 7,
    },
    titleStyle: {
      fontWeight: 'bold',
      fontSize: 14,
    },
  },
};
