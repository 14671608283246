import {useNavigation} from '@react-navigation/native';
import * as React from 'react';
import {ActivityIndicator} from 'react-native';
import {Divider} from 'react-native-elements';
import {ScrollView} from 'react-native-gesture-handler';
import styled from 'styled-components/native';
import useFetch from 'use-http';
import {API_HOST} from '../../../env';
import {ItButton} from '../../../components/buttons';
import {Title} from '../../../components/ItText';
import {Block} from '../../../components/views';
import TopRoundedCard from '../../ui/modules/cards/TopRoundedCard';
import LoyaltyResumeCard from '../components/LoyaltyResumeCard';
import {validLoyaltyTabs} from '../components/LoyaltyTabs';
import RedemptionPointStatus from '../modules/redemptions/components/RedemptionPointStatus';

export interface LoyaltyPointsDetailScreenProps {}

const LoyaltyPointsDetailScreen: React.FunctionComponent<LoyaltyPointsDetailScreenProps> = () => {
  const {data = {}} = useFetch(
    `${API_HOST}/api/app/my/current/points`,
    {
      cacheLife: 1,
    },
    [],
  );

  const {data: result = {}} = data;
  const navigation = useNavigation();

  if (!result || !result.total) {
    return (
      <ActivityIndicator
        size="large"
        color="black"
        style={{
          marginTop: 20,
        }}
      />
    );
  }

  return (
    <ScrollView keyboardShouldPersistTaps="always">
      <LoyaltyResumeCard
        linkLabel="Historial"
        linkIconName="history"
        onPressLabel={() => {
          navigation.navigate('RedemptionHistoryScreen');
        }}
      />
      <TopRoundedCard minHeight={400}>
        <Title>Estado de Puntos</Title>
        <PointsWrapper>
          <RedemptionPointStatus
            label="Disponibles"
            value={result.total}
            explanation="Puntos que has ganado usando el app"
          />
          <RedemptionPointStatus
            label="Redimidos"
            value={result.redeemed}
            explanation="Puntos que han sido redimidos por productos"
          />
        </PointsWrapper>
        <Divider />
        <PointsWrapper>
          <RedemptionPointStatus
            label="Histórico"
            value={result.redeemed}
            explanation="El total de puntos que has ganado en la plataforma."
          />
        </PointsWrapper>
        <ButtonContainer>
          <ItButton
            title="REDIMIR PUNTOS"
            onPress={() => {
              navigation.navigate('Logros', {
                activeTab: validLoyaltyTabs.redemption,
              });
            }}
          />
        </ButtonContainer>
      </TopRoundedCard>
    </ScrollView>
  );
};

export default LoyaltyPointsDetailScreen;

const ButtonContainer = styled.View`
  margin-top: 80;
`;

const PointsWrapper = styled(Block)`
  justify-content: space-around;
`;
