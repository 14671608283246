import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import * as React from 'react';
import {Image, LayoutChangeEvent, StyleSheet, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';

import ItText from '../../ui/ItText';
import dayjs from 'dayjs';
import useImageDimensions from '../../images/hooks/useImageDimensions';
import {secondary} from '../../../theme';
import HtmlPreview from '../../../components/HtmlPreview';
import {Card} from 'react-native-elements';
import CardWithInteractionsBar from '../../../components/CardWithInteractionsBar';
import useFetch from 'use-http';

export interface NewsDetailScreenProps {}

const NewsDetailScreen: React.FunctionComponent<NewsDetailScreenProps> = () => {
  const route = useRoute<any>();
  const {item} = route.params;
  const {aspectRatio} = useImageDimensions(item?.promotional_image);
  const {post: handleLike, loading: sendingLike} = useFetch(
    `/api/app/promotional/messages/like/${item.id}`,
  );
  const [isMyLike, setIsLiked] = React.useState(item.i_like_it);
  const [likes, setLikes] = React.useState(item.likes);
  const navigation = useNavigation();

  const [width, setWidth] = React.useState(0);
  const _onLayout = React.useCallback(
    (ev: LayoutChangeEvent) => {
      setWidth(ev.nativeEvent.layout.width);
    },
    [setWidth],
  );
  return (
    <div style={{ 
    height: 'calc(100vh - 70px)',
    overflow: 'scroll',
    }}>
      <Card containerStyle={{marginBottom: 50}}>
        <View style={styles.cardContent} onLayout={_onLayout}>
          {width > 0 && (
            <Image
              style={{
                width: width,
                aspectRatio: aspectRatio,
              }}
              source={{
                uri: item.promotional_image,
              }}
            />
          )}
          <View style={styles.content}>
            <ItText style={styles.date}>
              {dayjs(item.updated_at).format('dddd D [de] MMMM | hh:mma')}
            </ItText>
            <ItText style={styles.title}>{item.name}</ItText>
            <HtmlPreview htmlContent={item.description} />
          </View>
          <CardWithInteractionsBar
            handleLike={handleLike}
            handleComment={() => {
              navigation.navigate('NewsCommentsScreen', {
                postId: item.id,
              });
            }}
            likesCount={likes}
            isLiked={isMyLike}
            setIsLiked={setIsLiked}
            isSendingLike={sendingLike}
            setLikesCount={setLikes}
          />
        </View>
      </Card>
    </div>
  );
};

const styles = StyleSheet.create({
  content: {
    paddingVertical: 20,
  },
  title: {
    color: secondary,
    fontWeight: 'bold',
    fontSize: 16,
  },
  date: {
    color: '#313131',
    fontSize: 12,
    fontWeight: '300',
    marginBottom: 5,
  },
  cardContent: {
    width: '100%',
    minHeight: 50,
  },
});
export default NewsDetailScreen;
