// @ts-nocheck
import * as React from 'react';
import {View} from 'react-native';
import {Badge, ListItem, Overlay} from 'react-native-elements';
import {BorderlessButton} from 'react-native-gesture-handler';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import styled from 'styled-components/native';
import ItText from '../../../../components/ItText';

interface Item<T> {
  label: string;
  value: any;
}

interface AdditionalOverlayProps {
  x: number;
  y: number;
}

export interface DropdownMultipleProps<T> {
  placeholder: string;
  onToggle: (value: any) => void;
  items: Item<T>[];
  value: string[] | number[];
}

const BTN_HIT_SLOP = {
  top: 10,
  left: 10,
  right: 10,
  bottom: 10,
};

function DropdownMultiple<T = any>(props: DropdownMultipleProps<T>) {
  const [isOpen, setOpen] = React.useState(false);
  const [dropdownCoords, setDropdownCoords] = React.useState({
    x: 0,
    y: 0,
  });

  const dropdownContainerRef = React.useRef<View>(null);

  const _toggleOpen = React.useCallback(() => {
    if (!isOpen) {
      if (dropdownContainerRef.current !== null) {
        requestAnimationFrame(() => {
          if (dropdownContainerRef.current !== null) {
            dropdownContainerRef.current.measure(
              (
                _x: number,
                _y: number,
                _width: number,
                _height: number,
                pageX: number,
                pageY: number,
              ) => {
                if (pageX) {
                  setDropdownCoords({
                    x: pageX - 18,
                    y: pageY + 40,
                  });
                }
              },
            );
          }
        });
      }
    }

    // Para evitar efecto sube y baja
    setTimeout(() => {
      setOpen(!isOpen);
    }, 14);
  }, [isOpen]);

  const _toggleValueMaker = React.useCallback(
    (value) => {
      return () => {
        props.onToggle(value);
      };
    },
    [props],
  );

  const _renderItem = React.useCallback(
    ({item}: {item: any}) => (
      <ListItem>
        <ListItem.CheckBox
          checked={(props.value as any[]).indexOf(item.value) > -1}
          onPress={_toggleValueMaker(item.value)}
        />
        <ListItem.Title>{item.label}</ListItem.Title>
      </ListItem>
    ),
    [_toggleValueMaker, props.value],
  );

  return (
    <Container>
      <View ref={dropdownContainerRef} renderToHardwareTextureAndroid>
        <TouchableLabel
          onPress={_toggleOpen}
          rippleColor="rgba(0,0,0,0.2)"
          hitSlop={BTN_HIT_SLOP}>
          <Placeholder>{props.placeholder}</Placeholder>
          {props.value.length > 0 ? (
            <Badge value={props.value.length} />
          ) : (
            <Chevron name="chevron-down" />
          )}
        </TouchableLabel>
      </View>
      <StyledOverlay
        x={dropdownCoords.x}
        y={dropdownCoords.y}
        onBackdropPress={_toggleOpen}
        isVisible={isOpen}
        focusable>
        <StyledFlatList data={props.items} renderItem={_renderItem} />
      </StyledOverlay>
    </Container>
  );
}

const StyledFlatList = styled.FlatList`
  flex: 1;
  height: 200;
`;

const StyledOverlay = styled(Overlay).attrs<AdditionalOverlayProps>(
  (props) => ({
    overlayStyle: {
      position: 'absolute',
      top: props.y,
      left: props.x,
      zIndex: 999,
      elevation: 2,
    },
    backdropStyle: {
      backgroundColor: 'transparent',
    },
  }),
)<AdditionalOverlayProps>``;

const Container = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Chevron = styled(FontAwesome5Icon)`
  align-self: center;
  position: absolute;
  right: 0;
`;

const TouchableLabel = styled(BorderlessButton)`
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  flex-direction: row;
  min-width: 85;
`;

const Placeholder = styled(ItText)`
  font-size: 14;
  font-weight: 300;
  color: #313131;
`;

export default React.memo(DropdownMultiple);
