// @ts-nocheck
import dayjs from 'dayjs';
import * as React from 'react';
import {View, TouchableWithoutFeedback, Image} from 'react-native';
import {Tooltip} from 'react-native-elements';
import {s, vs} from 'react-native-size-matters';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import styled from 'styled-components/native';
import {Block} from '../../../../../components/views';
import {secondary} from '../../../../../theme';
import ItText from '../../../../ui/ItText';
import {Product} from '../entities/Product';
import {DeliveryDetails} from '../entities/Redemption';
import RedemptionDeliveryButton from './RedemptionDeliveryButton';

export interface RedemptionProps {
  product: Product;
  ActionElement?: React.ReactElement;
  showPointsInColumn?: boolean;
  deliveryDetails?: DeliveryDetails;
  handleRemove?: () => void;
}

const DELIVERED_ID = 115;
const REJECTED_ID = 113;
const PENDING = 114;

const RedemptionListItem: React.FunctionComponent<RedemptionProps> = ({
  product,
  ActionElement,
  showPointsInColumn,
  deliveryDetails,
  handleRemove,
}: RedemptionProps) => {
  const _getDeliveryLabel = React.useCallback(() => {
    if (deliveryDetails?.status.id == DELIVERED_ID) {
      return (
        <Tooltip
          skipAndroidStatusBar
          height={100}
          popover={
            <ItText white>
              El producto fue entregado el{' '}
              {dayjs(String(deliveryDetails?.delivery_date)).format(
                'DD MMM YYYY',
              )}
            </ItText>
          }>
          <Block alignCenter>
            <StatusText color="green">Entregado</StatusText>
            <FontAwesome5Icon size={14} color="green" name="info-circle" />
          </Block>
        </Tooltip>
      );
    }
    if (deliveryDetails?.status.id == REJECTED_ID) {
      return (
        <Tooltip
          height={100}
          skipAndroidStatusBar
          popover={
            <ItText white>
              Razón: {deliveryDetails?.reason_for_rejection}
            </ItText>
          }>
          <Block alignCenter>
            <StatusText color="red">Rechazado</StatusText>
            <FontAwesome5Icon color="red" name="info-circle" size={14} />
          </Block>
        </Tooltip>
      );
    }
    if (deliveryDetails?.status.id == PENDING) {
      return (
        <Tooltip
          height={100}
          skipAndroidStatusBar
          popover={
            <ItText white>
              Razón: {deliveryDetails?.reason_for_rejection}
            </ItText>
          }>
          <Block alignCenter>
            <StatusText>Pendiente de entrega</StatusText>
          </Block>
        </Tooltip>
      );
    }

    return <StatusText>Estado: {deliveryDetails?.status.name}</StatusText>;
  }, [deliveryDetails]);

  return (
    <Container>
      {typeof handleRemove !== 'undefined' && (
        <View
          style={{
            position: 'absolute',
            top: 7,
            right: 0,
          }}>
          <TouchableWithoutFeedback
            hitSlop={{
              top: 10,
              bottom: 10,
              left: 10,
              right: 10,
            }}
            onPress={handleRemove}>
            <FontAwesome5Icon name="times" color="#656565" light size={16} />
          </TouchableWithoutFeedback>
        </View>
      )}
      <ProductImage
        source={{
          uri: product.photo,
        }}
        resizeMode="contain"
        fallback={require('../assets/product-placeholder.jpg')}
      />
      <View
        style={{
          flex: 1,
        }}>
        {deliveryDetails && _getDeliveryLabel()}
        <Title>{product.name}</Title>
        <Presentation>X {product.presentation}</Presentation>
        {!showPointsInColumn && <Points>{product.points_unity} Puntos</Points>}
        {deliveryDetails && deliveryDetails?.status.id == PENDING && (
          <View style={{marginTop: 10}}>
            <RedemptionDeliveryButton id={deliveryDetails.id} />
          </View>
        )}
        {ActionElement}
      </View>
      {showPointsInColumn && (
        <ColumnPointsContainer>
          <ColumnPoints>{product.points_unity}</ColumnPoints>
          <ColumnPoints>Puntos</ColumnPoints>
        </ColumnPointsContainer>
      )}
    </Container>
  );
};

const Container = styled(Block)`
  border-bottom-width: 0.5px;
  padding-vertical: ${vs(20)};
  align-items: center;
  padding-horizontal: 10;
  border-bottom-color: #000000;
`;

const ProductImage = styled(Image)`
  width: ${s(56)};
  height: ${s(56)};
  margin-right: ${s(20)};
`;

const Title = styled(ItText)`
  font-weight: bold;
  font-size: ${s(14)};
  margin-bottom: ${vs(6)};
`;

const Presentation = styled(ItText)`
  font-size: ${s(12)};
  margin-bottom: ${vs(4)};
`;

const Points = styled(ItText)`
  color: ${secondary};
  font-weight: bold;
  margin-bottom: ${vs(8)}
  font-size: ${s(14)};
`;

const ColumnPoints = styled(ItText)`
  color: ${secondary};
  font-weight: bold;
  margin-bottom: ${vs(8)}
  font-size: ${s(14)};
  line-height: ${s(14)};
`;

const ColumnPointsContainer = styled.View`
  justify-content: center;
  align-items: center;
  padding-horizontal: 10;
`;

const StatusText = styled(ItText)<{
  color: string;
}>`
  font-size: ${s(14)};
  margin-right: 7;
  color: ${(props) => props.color ?? 'black'};
`;

export default React.memo(RedemptionListItem);
