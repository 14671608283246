import * as React from 'react';
import NewsList from '../NewsList';

export interface NewsListScreenProps {}

const NewsListScreen: React.FunctionComponent<NewsListScreenProps> = () => {
  return <NewsList />;
};

export default NewsListScreen;
