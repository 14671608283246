import {useNavigation, useRoute} from '@react-navigation/native';
import * as React from 'react';
import {Image, RefreshControl, ScrollView, StyleSheet, View} from 'react-native';
import useFetch from 'use-http';
import Lightbox from 'react-native-lightbox';
import {API_HOST} from '../../../env';
import {Card, Divider, Button} from 'react-native-elements';
import {SectionTitle} from '../../../components/ItText';
import HtmlPreview from '../../../components/HtmlPreview';
import useImageDimensions from '../../images/hooks/useImageDimensions';
import {Space} from '../../../theme';
import ChallengeHeader from '../components/ChallengeHeader';
import Loading from '../../../components/Loading';
import {wasExecutedChallenge, wasRejectedChallenge} from '../utils/wasExecuted';

export interface ChallengeDetailProps {}

const ChallengeDetail: React.FunctionComponent<ChallengeDetailProps> = () => {
  const route = useRoute<any>();
  const [refreshing, setRefreshing] = React.useState(false);
  const navigation = useNavigation();
  const {data = {}, loading, get: fetchChallenge} = useFetch(
    `${API_HOST}/api/app/challenge/${route.params?.id}`,
    {
      persist: false,
      cacheLife: 1,
      loading: true,
    },
    [route.params],
  );
  const {data: item = {}} = data;
  const {aspectRatio: aspectRatioPromotional} = useImageDimensions(
    item.promotional_image,
  );
  const {aspectRatio: aspectRatioReference} = useImageDimensions(
    item.reference_image,
  );

  const executed = !loading && wasExecutedChallenge(item);
  const rejected = wasRejectedChallenge(item);

  console.log(rejected);
  if (loading) {
    return <Loading size={20} show />;
  }


  return (
    <ScrollView
      style={{
        maxHeight: 'calc(var(--app-height) - 70)',
	paddingBottom: 20
      }}
      contentContainerStyle={styles.container}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={async () => {
            setRefreshing(true);
            await fetchChallenge();
            setRefreshing(false);
          }}
        />
      }>
      <ChallengeHeader item={item} />
      <Card
	containerStyle={{
	  height: 200
	}}
      >
        <View style={styles.description}>
          <SectionTitle>Descripción</SectionTitle>
          <View style={styles.imageContainer}>
            <Lightbox>
              <Image
                source={{
                  uri: item.promotional_image,
                }}
                style={[
                  styles.promotionalImage,
                  {aspectRatio: aspectRatioPromotional},
                ]}
              />
            </Lightbox>
          </View>
          <View style={styles.descriptionContainer}>
            <HtmlPreview htmlContent={item.description} />
          </View>
          <Divider style={styles.divider} />
          <SectionTitle>¿Qué debes hacer?</SectionTitle>
          <HtmlPreview htmlContent={item.instructions} />
          <Divider style={styles.divider} />
          <SectionTitle>Imagen de referencia</SectionTitle>
          <View style={styles.imageContainer}>
            <Lightbox>
              <Image
                source={{
                  uri: item.reference_image,
                }}
                style={[
                  styles.referenceImage,
                  {aspectRatio: aspectRatioReference},
                ]}
              />
            </Lightbox>
          </View>
        </View>
        <View style={styles.buttonContainer}>
          {executed && (
            <Button
              style={styles.button}
              onPress={() => {
                const nextRoute = 'ChallengeExecutedScreen';
                navigation.navigate(nextRoute, {
                  item,
                });
              }}
              title={'VER FOTOS'}
            />
          )}
        </View>
        {(rejected || !executed || item.challenge_type_id == 39) && (
          <View style={styles.buttonContainer}>
            <Button
              type="outline"
              style={styles.button}
              onPress={() => {
                const nextRoute = 'ChallengeExe';
                navigation.navigate(nextRoute, {
                  item,
                });
              }}
              title={rejected ? 'VOLVER A EJECUTAR' : 'EJECUTAR'}
            />
          </View>
        )}
      </Card>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  promotionalImage: {
    width: '100%',
  },
  referenceImage: {
    width: '100%',
  },
  divider: {
    marginVertical: Space.MEDIUM,
    width: '100%',
  },
  container: {
    paddingBottom: 40,
  },
  description: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  descriptionContainer: {marginTop: Space.SMALL},
  buttonContainer: {
    marginTop: Space.MEDIUM,
  },
  button: {
    marginTop: Space.LARGE,
  },
  imageContainer: {
    width: 200,
  },
});

export default ChallengeDetail;
