import * as React from 'react';
import {Image, View} from 'react-native';
import { ImageProps } from 'react-native-elements';

import ImagePlaceholder from './ImagePlaceholder';

export interface ItimageProps extends Omit<ImageProps, 'source'> {
  uri: string;
}

const ImageFromNetwork: React.FunctionComponent<ItimageProps> = ({
  uri,
  ...props
}: ItimageProps) => {
  const [aspect, setAspect] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    Image.getSize(uri, (width, height) => {
      setAspect(width / height);
    });
  });

  return (
    <View>
      {loading && <ImagePlaceholder />}
      <Image
        {...props}
        onLoadEnd={() => {
          setLoading(false);
        }}
        onLoadStart={() => setLoading(true)}
        source={{uri: uri}}
        style={[
          {
            aspectRatio: aspect,
            width: '100%',
          },
          props.style,
        ]}
      />
    </View>
  );
};

export default React.memo(ImageFromNetwork);
