import * as React from 'react';
import {NavigationContainer} from '@react-navigation/native';
import {StatusBar} from 'react-native';
import AuthStackScreen from './screens/AuthStackScreen';
import {secondary} from './theme/colors';
import DrawerNavigator from './screens/DrawerNavigator';
import {useSelector} from 'react-redux';
import {register} from 'react-native-bundle-splitter';
import {Campaign} from './modules/campaings/entities/commercialAction';
import {Stack} from './App';

export type RootStackParamsList = {
  CampaignExecutedScreen: {
    item: Campaign;
  };
};

export const Navigator = () => {
  const user = useSelector((state: any) => state.auth.user);

  return (
    <>
      <StatusBar backgroundColor={secondary} />
      <NavigationContainer>
        <Stack.Navigator
          screenOptions={{
            headerStyle: {
              backgroundColor: '#012128',
            },
            headerTintColor: '#fff',
          }}>
          {!user ? (
            <Stack.Screen
              component={AuthStackScreen}
              name="auth"
              options={{
                header: () => null,
              }}
            />
          ) : (
            <>
              <Stack.Screen
                component={DrawerNavigator}
                name="main"
                options={{
                  headerShown: false,
                }} />
              <Stack.Screen
                component={register({
                  require: () =>
                    require('./modules/ui/screens/ComingSoonScreen'),
                })}
                name="ComingSoonScreen"
                options={{
                  title: 'En Construcción',
                }}
              />
	      <Stack.Screen
                component={register({
                  require: () =>
                    require('./modules/support/SupportScreen'),
                })}
                name="SupportScreen"
                options={{
                 title: 'Ayuda/Soporte',
                }}
              />
              <Stack.Screen
                name="pedidos"
                component={register({
                  require: () =>
                    require('./modules/pedidos/screens/PedidosScreen'),
                })}
              />
	      <Stack.Screen
                name="RedemptionCatalogScreen"
		options={{
		  title: 'Catalogo'
		}}
                component={register({
                  require: () =>
                    require('./modules/loyalty/screens/RedemptionCatalogScreen'),
                })}
              />
	      <Stack.Screen
                name="RedemptionFormScreen"
		options={{
		  title: 'Redimir'
		}}
                component={register({
                  require: () =>
                    require('./modules/loyalty/screens/RedemptionFormScreen'),
                })}
              />
              <Stack.Screen
                name="NewsDetail"
                options={{
                  title: 'Noticias',
                }}
                component={register({
                  require: () =>
                    require('./modules/home/screens/NewsDetailScreen'),
                })}
              />
              <Stack.Screen
                name="News"
                options={{
                  title: 'Noticias',
                }}
                component={register({
                  require: () =>
                    require('./modules/news/screens/NewsListScreen'),
                })}
              />
              <Stack.Screen
                name="NewsCommentsScreen"
                options={{
                  title: 'Noticias',
                }}
                component={register({
                  require: () =>
                    require('./modules/news/screens/NewsCommentsScreen'),
                })}
              />
              <Stack.Screen
                name="LoyaltyPointsDetailScreen"
                component={register({
                  require: () =>
                    require('./modules/loyalty/screens/LoyaltyPointsDetailScreen'),
                })}
                options={{
                  title: 'Estado',
                }}
              />
              <Stack.Screen
                name="RedemptionHistoryScreen"
                component={register({
                  require: () =>
                    require('./modules/loyalty/screens/RedemptionHistoryScreen'),
                })}
                options={{
                  title: 'Historial',
                }}
              />
              <Stack.Screen
                name="Community"
                component={register({
                  require: () =>
                    require('./modules/community/screens/CommunityScreen'),
                })}
                options={{
                  title: 'Comunidad',
                }}
              />
              <Stack.Screen
                component={register({
                  require: () =>
                    require('./modules/profile/screens/ProfileUpdateScreen'),
                })}
                options={{
                  title: 'Actualizar',
                }}
                name="AuthCompleteProfileScreen"
              />
              <Stack.Screen
                name="CommunityCommentsScreen"
                component={register({
                  require: () =>
                    require('./modules/community/screens/CommunityCommentsScreen'),
                })}
                options={{
                  title: 'Comentarios',
                }}
              />
              <Stack.Screen
                name="NewCommunityPublishScreen"
                component={register({
                  require: () =>
                    require('./modules/community/screens/NewCommunityPublishScreen'),
                })}
                options={{
                  title: 'Nueva publicación',
                }}
              />
              <Stack.Screen
                name="CampaignsDetail"
                options={{
                  title: 'Retos',
                }}
                component={register({
                  require: () =>
                    require('./modules/campaings/screens/CampaignDetail'),
                })}
              />
              <Stack.Screen
                name="ChallengeDetail"
                options={{
                  title: 'Retos',
                }}
                component={register({
                  require: () =>
                    require('./modules/campaings/screens/ChallengeDetail'),
                })}
              />
              <Stack.Screen
                name="CampaignExe"
                options={{
                  title: 'Retos',
                }}
                component={register({
                  require: () =>
                    require('./modules/campaings/screens/CampaignExe'),
                })}
              />

              <Stack.Screen
                name="promoDetail"
                component={register({
                  require: () =>
                    require('./modules/offers/screens/PromoDetailScreen'),
                })}
              />
              <Stack.Screen
                name="ChallengeExe"
                options={{
                  title: 'Retos',
                }}
                component={register({
                  require: () =>
                    require('./modules/campaings/screens/ChallengeExe'),
                })}
              />
              <Stack.Screen
                name="ShoppersMenu"
                options={{
                  title: 'Súper Clientes',
                }}
                component={register({
                  require: () =>
                    require('./modules/shopper/screens/ShoppersMenuScreen'),
                })}
              />
              <Stack.Screen
                name="ShopperCreateClient"
                options={{
                  title: 'Súper Clientes',
                }}
                component={register({
                  require: () =>
                    require('./modules/shopper/screens/ShopperCreateClient'),
                })}
              />
              <Stack.Screen
                name="ShopperCreateClientFormScreen"
                options={{
                  title: 'Súper Clientes',
                }}
                component={register({
                  require: () =>
                    require('./modules/shopper/screens/ShopperCreateClientFormScreen'),
                })}
              />
              <Stack.Screen
                name="OfferCreateFormScreen"
                options={{
                  title: 'Súper Clientes',
                }}
                component={register({
                  require: () =>
                    require('./modules/offers/screens/OfferCreateFormScreen'),
                })}
              />
              <Stack.Screen
                name="DeliveryActivateScreen"
                options={{
                  title: 'Súper Clientes',
                }}
                component={register({
                  require: () =>
                    require('./modules/delivery/screens/DeliveryActivateScreen'),
                })}
              />
              <Stack.Screen
                name="ChallengeExecutedScreen"
                options={{
                  title: 'Ejecutado',
                }}
                component={register({
                  require: () =>
                    require('./modules/campaings/screens/ChallengeExecutedScreen'),
                })}
              />
              <Stack.Screen
                name="CampaignExecutedScreen"
                options={{
                  title: 'Ejecutado',
                }}
                component={register({
                  require: () =>
                    require('./modules/campaings/screens/CampaignExecutedScreen'),
                })}
              />
            </>
          )}
        </Stack.Navigator>
      </NavigationContainer>
    </>
  );
};
