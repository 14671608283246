import React from 'react';
import {ImageProps, View, ViewStyle} from 'react-native';


interface ItImageAutoHeightProps extends Omit<ImageProps, 'width'> {
  containerStyle?: ViewStyle;
}

const ItImageAutoHeight = (
  props: ItImageAutoHeightProps,
): React.ReactElement => {
  const [imageWidth, setImageWidth] = React.useState(0);
  // @ts-ignore
  const src = props.source.uri;
  return (
    <View
      style={[{width: '100%', height: 'auto'}, props.containerStyle]}
      onLayout={(event): void => {
        setImageWidth(event.nativeEvent.layout.width);
      }}>
      <img src={src} width={imageWidth} />
    </View>
  );
};

export default ItImageAutoHeight;
