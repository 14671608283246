import {useNavigation} from '@react-navigation/native';
import * as React from 'react';
import {View} from 'react-native';
import styled from 'styled-components/native';
import OverlayConfirmMessage from '../../../../../components/OverlayConfirmMessage';
import ItText from '../../../../ui/ItText';

export interface RedemptionOverlaySuccessProps {
  isVisible: boolean;
  setVisible: (next: any) => void;
}

const RedemptionOverlaySuccess: React.FunctionComponent<RedemptionOverlaySuccessProps> = ({
  isVisible,
  setVisible,
}: RedemptionOverlaySuccessProps) => {
  const navigation = useNavigation();
  return (
    <OverlayConfirmMessage
      title="Redención exitosa"
      isOverlayOpen={isVisible}
      setIsOverlayOpen={setVisible}
      text={
        <View
          style={{
            width: 250,
            justifyContent: 'center',
          }}>
          <CenteredText>
            Hemos redimido los puntos por los productos seleccionados
          </CenteredText>
          <CenteredText>
            Pronto, recibirás los productos que solicitaste en la dirección
            registrada.
          </CenteredText>
        </View>
      }
      onConfirm={() => {
        navigation.goBack();
      }}
    />
  );
};

const CenteredText = styled(ItText)`
  text-align: center;
  color: black;
`;

export default RedemptionOverlaySuccess;
