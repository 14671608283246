// @ts-nocheck
import {useNavigation} from '@react-navigation/native';
import {Formik} from 'formik';
import * as React from 'react';
import {Alert} from 'react-native';
import {Button, Card, Divider, Input} from 'react-native-elements';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5';
import {API_HOST} from '../../../env';
import {SectionTitle} from '../../../components/ItText';
import {primary} from '../../../theme/colors';

export interface SignupValidateProps {}

const SignupValidate: React.SFC<SignupValidateProps> = () => {
  const navigation = useNavigation();
  return (
    <Formik
      initialValues={{
        phone: '',
      }}
      onSubmit={async (values) => {
        const res = await fetch(
          `${API_HOST}/api/oauth/pdv/phone/verification/request`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              prefix: '+57',
              phone: values.phone,
            }),
          },
        );
        if (!res.ok) {
          Alert.alert('Error completando la petición');
          console.error(await res.text());
          return;
        }
        const {data} = await res.json();
        navigation.navigate('SignupComplete', {
          data: data,
        });
      }}>
      {({values, handleChange, handleSubmit, isSubmitting}) => (
        <Card>
          <SectionTitle>Paso 1 - Identifícate</SectionTitle>
          <Divider style={{marginVertical: 20}} />
          <Input
            label="Número de Celular"
            value={values.phone}
            testID="phone"
            onChangeText={handleChange('phone')}
            keyboardType="phone-pad"
            leftIcon={
              <FontAwesome5Icon name="phone" color={primary} light size={20} />
            }
          />
          <Button
            loading={isSubmitting}
            title="Validar número"
            testID="validate_phone_btn"
            onPress={() => handleSubmit()}
          />
        </Card>
      )}
    </Formik>
  );
};

export default SignupValidate;
