import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';
import ItText from '../../../../components/ItText';
import {primary} from '../../../../theme';

export interface TabProps {
  onPress: any;
  children: React.ReactNode;
  isActive: boolean;
}

const Tab: React.FunctionComponent<TabProps> = (props: TabProps) => {
  return (
    <View style={[styles.tabContainer, props.isActive && styles.activeTab]}>
      <TouchableOpacity onPress={props.onPress} containerStyle={styles.tab}>
        <ItText size={18} allowFontScaling={false}>
          {props.children}
        </ItText>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  tabContainer: {
    flex: 1,
    height: 60,
  },
  tab: {
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeTab: {
    borderBottomColor: primary,
    borderBottomWidth: 4,
  },
});

export default Tab;
