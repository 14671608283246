import {createStackNavigator} from '@react-navigation/stack';
import * as React from 'react';
import {AuthStackParams} from '../modules/auth/entities/AuthStackParams';
import SplashScreen from './SplashScreen';
import {register} from 'react-native-bundle-splitter';

export interface AuthStackScreenProps {}

const AuthStack = createStackNavigator<AuthStackParams>();

export const AuthStackScreen: React.SFC<AuthStackScreenProps> = () => {
  return (
    <AuthStack.Navigator>
      <AuthStack.Screen component={SplashScreen} name="splash" />
      <AuthStack.Screen
        component={register({
          require: () => require('../modules/auth/screens/LoginScreen'),
        })}
        name="login"
      />
      <AuthStack.Screen
        component={register({
          require: () => require('../modules/auth/screens/SignupValidate'),
        })}
        name="signupValidatePhone"
      />
      <AuthStack.Screen
        component={register({
          require: () => require('../modules/auth/screens/SignupForm'),
        })}
        name="SignupVerification"
      />
      <AuthStack.Screen
        component={register({
          require: () => require('../modules/auth/screens/SignupComplete'),
        })}
        name="SignupComplete"
      />
      <AuthStack.Screen
        component={register({
          require: () => require('../modules/auth/screens/SignupInstructions'),
        })}
        name="SignupInstructions"
      />
      <AuthStack.Screen
        component={register({
          require: () =>
            require('../modules/passwordRecovery/screens/EmailRecoveryScreen'),
        })}
        name="EmailRecovery"
      />
      <AuthStack.Screen
        component={register({
          require: () => require('../modules/auth/screens/AuthMeikoCode'),
        })}
        name="AuthMeikoCode"
      />
      <AuthStack.Screen
        component={register({
          require: () =>
            require('../modules/passwordRecovery/screens/ForgotPasswordTokenScreen'),
        })}
        name="ForgotPasswordTokenScreen"
      />
      <AuthStack.Screen
        component={register({
          require: () =>
            require('../modules/passwordRecovery/screens/ForgotPasswordSetNewScreen'),
        })}
        name="ForgotPasswordSetNewScreen"
      />
    </AuthStack.Navigator>
  );
};

export default AuthStackScreen;
