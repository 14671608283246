import {Formik} from 'formik';
import * as React from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {ItButton} from '../../../components/buttons';
import {ItInputBox} from '../../../components/input';
import {secondary, Space} from '../../../theme';
import ItText from '../../ui/ItText';
import DatePickerInput from '../../ui/modules/inputs/DatePickerInput';
import ImagePreview from '../../ui/modules/inputs/ImagePreview';
import TakePhotoInput from '../../ui/modules/inputs/TakePhotoInput';
import OfferCategoryInput from '../components/OfferCategoryInput';
import OfferMakerInput from '../components/OfferMakerInput';
import OfferConfirmOverlay from './OfferConfirmOverlay';
import * as yup from 'yup';
import dayjs from 'dayjs';

export interface OfferCreateFormScreenProps {}

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  start: yup
    .date()
    .required()
    .min(dayjs().startOf('day').toISOString(), 'Debe ser mayor a hoy'),
  end: yup
    .date()
    .required()
    .min(yup.ref('start'), 'Debe ser mayor a la fecha de inicio'),
  image: yup.string().required(),
  price: yup.string().required(),
  sales_unity: yup.string().required(),
  maximum_amount: yup.string().required(),
  detail: yup.string().required(),
  brand_id: yup.string().required(),
  shopper_promotion_category_id: yup.string().required(),
});

const OfferCreateFormScreen: React.FunctionComponent<OfferCreateFormScreenProps> = () => {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const _handleSubmit = React.useCallback((values, helpers) => {
    console.log(dayjs(values.end).diff(values.start, 'day'), 'day');

    if (dayjs(values.end).diff(values.start, 'day') > 31) {
      helpers.setFieldError('end', 'La promoción no puede durar mas de un mes');
    } else {
      setShowConfirm(true);
    }
  }, []);

  return (
    <ScrollView contentContainerStyle={styles.content}>
      <ItText strong size={16} textCenter style={styles.title}>
        Crear Promoción
      </ItText>
      <ItText textCenter style={styles.subtitle}>
        Crea una promoción en tu PDV para aumentar las compras
      </ItText>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: '',
          description: '',
          start: '',
          end: '',
          image: '',
          price: '',
          sales_unity: '',
          maximum_amount: '',
          detail: '',
          brand_id: '',
          shopper_promotion_category_id: '',
        }}
        onSubmit={_handleSubmit}>
        {({
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          setFieldValue,
        }) => (
          <>
            {values.image ? (
              <ImagePreview
                uri={values.image}
                onRemove={() => {
                  setFieldValue('image', '');
                }}
              />
            ) : (
              <ItInputBox
                placeholder="Subir foto"
                value={values.image}
                onChangeText={handleChange('image')}
                onBlur={handleBlur('image')}
                InputComponent={TakePhotoInput}
                errorMessage={touched.image ? errors.image : ''}
                leftIcon={
                  <FontAwesome5Icon
                    name="camera"
                    color={secondary}
                    size={18}
                    light
                  />
                }
              />
            )}
            <ItInputBox
              placeholder="Título Promoción"
              value={values.name}
              onChangeText={handleChange('name')}
              onBlur={handleBlur('name')}
              errorMessage={touched.name ? errors.name : ''}
              leftIcon={
                <FontAwesome5Icon
                  name="heading"
                  color={secondary}
                  size={18}
                  light
                />
              }
            />
            <ItInputBox
              placeholder="Descripción promoción"
              onChangeText={handleChange('description')}
              onBlur={handleBlur('description')}
              errorMessage={touched.description ? errors.description : ''}
              value={values.description}
              multiline
              numberOfLines={3}
            />
            <ItInputBox
              placeholder="Información adicional"
              onChangeText={handleChange('detail')}
              onBlur={handleBlur('detail')}
              errorMessage={touched.detail ? errors.detail : ''}
              value={values.detail}
              multiline
              numberOfLines={3}
            />
            <ItInputBox
              placeholder="Precio"
              onChangeText={handleChange('price')}
              onBlur={handleBlur('price')}
              errorMessage={touched.price ? errors.price : ''}
              value={values.price}
              keyboardType="number-pad"
              leftIcon={
                <FontAwesome5Icon
                  name="usd-circle"
                  color={secondary}
                  size={18}
                  light
                />
              }
            />
            <ItInputBox
              placeholder="Unidades disponibles"
              keyboardType="number-pad"
              value={values.sales_unity}
              onChangeText={handleChange('sales_unity')}
              onBlur={handleBlur('sales_unity')}
              errorMessage={touched.sales_unity ? errors.sales_unity : ''}
              leftIcon={
                <FontAwesome5Icon
                  name="list-ol"
                  color={secondary}
                  size={18}
                  light
                />
              }
            />
            <ItInputBox
              placeholder="Unidades por comprador"
              keyboardType="number-pad"
              value={values.maximum_amount}
              onChangeText={handleChange('maximum_amount')}
              onBlur={handleBlur('maximum_amount')}
              errorMessage={touched.maximum_amount ? errors.maximum_amount : ''}
              leftIcon={
                <FontAwesome5Icon
                  name="list-ol"
                  color={secondary}
                  size={18}
                  light
                />
              }
            />
            <ItInputBox
              label="Marca"
              placeholder="Seleccionar"
              keyboardType="number-pad"
              value={values.brand_id}
              InputComponent={OfferMakerInput}
              onChangeText={handleChange('brand_id')}
              onBlur={handleBlur('brand_id')}
              errorMessage={touched.brand_id ? errors.brand_id : ''}
              rightIcon={
                <FontAwesome5Icon
                  name="angle-down"
                  color={secondary}
                  size={20}
                  light
                />
              }
            />
            {!!values.brand_id && (
              <ItInputBox
                label="Categoría"
                placeholder="Seleccionar"
                keyboardType="number-pad"
                value={values.shopper_promotion_category_id}
                InputComponent={OfferCategoryInput}
                makersId={values.brand_id}
                onChangeText={handleChange('shopper_promotion_category_id')}
                onBlur={handleBlur('shopper_promotion_category_id')}
                errorMessage={
                  touched.shopper_promotion_category_id
                    ? errors.shopper_promotion_category_id
                    : ''
                }
                rightIcon={
                  <FontAwesome5Icon
                    name="angle-down"
                    color={secondary}
                    size={20}
                    light
                  />
                }
              />
            )}
            <ItInputBox
              label="Fecha Inicio"
              placeholder="Seleccionar"
              value={values.start}
              onChangeText={handleChange('start')}
              onBlur={handleBlur('start')}
              errorMessage={touched.start ? errors.start : ''}
              InputComponent={DatePickerInput}
              rightIcon={
                <FontAwesome5Icon
                  name="calendar-check"
                  color={secondary}
                  size={18}
                  light
                />
              }
            />
            <ItInputBox
              label="Fecha Finalización"
              placeholder="Seleccionar"
              value={values.end}
              onChangeText={handleChange('end')}
              onBlur={handleBlur('end')}
              errorMessage={touched.end ? errors.end : ''}
              InputComponent={DatePickerInput}
              rightIcon={
                <FontAwesome5Icon
                  name="calendar-check"
                  color={secondary}
                  size={18}
                  light
                />
              }
            />
            <ItButton onPress={() => handleSubmit()} title="CREAR PROMOCIÓN" />
            <OfferConfirmOverlay
              setShowConfirm={setShowConfirm}
              values={values}
              showConfirm={showConfirm}
            />
          </>
        )}
      </Formik>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  subtitle: {
    marginBottom: Space.LARGE,
  },
  title: {
    marginBottom: Space.SMALL,
  },
});

export default OfferCreateFormScreen;
