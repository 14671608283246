import {useRoute} from '@react-navigation/core';
import * as React from 'react';
import {Card} from 'react-native-elements';
import Lightbox from 'react-native-lightbox';
import {SectionTitle} from '../../../components/ItText';
import {Image, ScrollView, StyleSheet, View} from 'react-native';
import {Block} from '../../../components/views';
import ChallengeHeader from '../components/ChallengeHeader';
import {Space} from '../../../theme';
import _ from 'lodash';
import {Execution} from '../entities/commercialAction';

export interface CampaignExecutedScreenProps {}

const ChallengeExecutedScreen: React.FunctionComponent<CampaignExecutedScreenProps> = () => {
  const route = useRoute<any>();
  const {item: challenge} = route.params;
  const lastExecution: Execution = _.last(challenge.executions)!;

  return (
    <ScrollView style={{
      paddingBottom: 30
      }}>
      <ChallengeHeader item={challenge} />
      <Card>
        <SectionTitle>Fotografías enviadas</SectionTitle>
        <Block justifySpaceBetween style={styles.imageGroup}>
          {lastExecution.photos.map(({photo}) => (
            <View style={styles.image}>
              <Lightbox>
                <Image
                  style={{
                    width: '100%',
                    aspectRatio: 1,
                  }}
                  source={{uri: photo}}
                />
              </Lightbox>
            </View>
          ))}
        </Block>
      </Card>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  image: {
    width: '47%',
  },
  imageGroup: {
    width: '100%',
  },
  content: {
    marginBottom: Space.SMALL,
  },
});

export default ChallengeExecutedScreen;
