import * as React from 'react';
import {View} from 'react-native';
import {Block} from '../../../../components/views';
import {InjectableFormSection} from '../../../../entities/InjectableFormSection';
import ItText from '../../../ui/ItText';

export interface ProfileSectionHeaderProps {
  section: InjectableFormSection;
}

const ProfileSectionHeader: React.FunctionComponent<ProfileSectionHeaderProps> = ({
  section,
}) => {
  return (
    <Block
      style={{
        paddingHorizontal: 20,
        paddingVertical: 15,
        shadowColor: '#000',
        alignItems: 'center',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        backgroundColor: 'white',
      }}>
      {section.IconElement}
      <View
        style={{
          marginLeft: 10,
        }}>
        <ItText size={16}> {section.title}</ItText>
      </View>
    </Block>
  );
};

export default ProfileSectionHeader;
