// @ts-nocheck
import * as React from 'react';
import {
  BottomTabBar,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import { ImageBackground, StyleSheet, View} from 'react-native';
import FontAwesomePro from 'react-native-vector-icons/FontAwesome5Pro';
import {BorderlessButton} from 'react-native-gesture-handler';
import {MenuTabButton} from './MenuTabButton';
import {register} from 'react-native-bundle-splitter';
import { Image } from 'react-native-elements';
import usePendingCamapaigns from './modules/campaings/hooks/usePendingCampaigns';

export const Tab = createBottomTabNavigator();

const tabBarButton = (props: any) => (
  <div id="demo" style={{
  flex: 1, 
  margin: 'auto',
  }}>
    <BorderlessButton
      hitSlop={{
        top: 20,
        left: 20,
        right: 20,
        bottom: 20,
      }}
      rippleColor="rgba(0,0,0,0.1)"
      {...props}
      style={styles.tabBarButton}>
      <img style={{ width: 'calc(90%)', marginLeft: '5%', marginBottom: 20 }} src={require('./modules/tabs/assets/diamond.png')} />
      <div>
            {props.children}
      </div>
    </BorderlessButton>
  </div>
);

const titleWebMaker = (text:string) => ({color}) => <div style={{color: color, fontSize: 12}}>{text}</div>;
const TabBarWeb = (p) => <div onClick={p.onPress} style={{ flex: 1, minHeight: 50, paddingTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center'}}>
	   {p.children}
	  </div>
export const MenuTabs = React.memo(() => {
  const pendingCampaignsCount = usePendingCamapaigns() 

  return (
    <Tab.Navigator
      tabBarOptions={{
        activeBackgroundColor: 'rgba(255,255,255,0.1)',
        inactiveBackgroundColor: 'transparent',
        activeTintColor: 'white',
        inactiveTintColor: 'rgba(255,255,255,.8)',
        tabStyle: {
          backgroundColor: 'transparent',
        },
        style: {
          backgroundColor: 'transparent',
          elevation: 0,
        },
      }}
      tabBar={(props) => (
        <View style={styles.tabbar}>
          <ImageBackground
            style={{
              width: '100%',
              height: 80,
            }}
            source={require('./modules/tabs/assets/menu_alt.jpg')}>
            <BottomTabBar {...props} />
          </ImageBackground>
        </View>
      )}>
      <Tab.Screen
        options={{
          tabBarIcon: ({color}) => (
            <FontAwesomePro name="home" size={24} color={color} />
          ),
          tabBarBadge: pendingCampaignsCount,
          title: titleWebMaker('Inicio'),
          tabBarButton: TabBarWeb,	
        }}
        name="Pedidos"
        component={register({
          require: () => require('./modules/home/screens/HomeScreen'),
        })}
      />
      <Tab.Screen
        options={{
          tabBarIcon: ({color}) => (
            <FontAwesomePro name="check-circle" size={24} color={color} />
          ),
	  title: titleWebMaker('Retos'),
	  tabBarButton: TabBarWeb,
        }}
	title={titleWebMaker('Retos')}
        name="Retos"
        component={register({
          require: () =>
            require('./modules/campaings/components/CampaignListTabs'),
        })}
      />
      <Tab.Screen
        name="Logros"
        component={register({
          require: () =>
            require('./modules/loyalty/screens/LoyaltyResumeScreen'),
        })}
        options={{
          title: ({ color }) => <div style={{ fontSize: 12, marginTop: -5, color, textAlign: 'center'}}>Logros</div>,
          tabBarButton,

        }}
      />
      <Tab.Screen
        options={{
          tabBarIcon: ({color}) => (
            <FontAwesomePro name="newspaper" size={24} color={color} />
          ),
	  title: titleWebMaker('Noticias'),
	  tabBarButton: TabBarWeb,
        }}
        name="News"

        component={register({
          require: () =>
            require('./modules/news/screens/NewsListScreen'),
        })}
      />
      <Tab.Screen
        name="t5"
        options={{
          title: '',
          tabBarIcon: () => (
              <img
                /* style={styles.menu} */
                style={{
                  width: 10
                }}
                src={require('./modules/tabs/assets/menu.png')}
              />
          ),
          tabBarButton: MenuTabButton,
        }}
        component={() => null}
      />
    </Tab.Navigator>
  );
});

const styles = StyleSheet.create({
  tabbar: {
    /* backgroundColor: secondary, */
  },
  menu: {
    elevation: 1,
    /* marginTop: 5, */
  },
  tabBarButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    elevation: 9,
    transform: [
      {
        translateY: -40,
      },
    ],
  },
});
