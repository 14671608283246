// @ts-nocheck
import React from 'react';
import {View, ActivityIndicator} from 'react-native';
import ItText from './ItText';
import {Overlay} from 'react-native-elements';

interface Props {
  loading: boolean;
  message?: string;
}

const Loader = (props: Props): React.ReactElement => {
  const {loading, message} = props;
  return (
    <Overlay
      isVisible={loading}
      windowBackgroundColor="rgba(255, 255, 255, .5)"
      overlayBackgroundColor="rgba(0,0,0,0.60)"
      width="auto"
      height="auto"
      fullScreen
      animationType="none"
      hardwareAccelerated
      overlayStyle={{
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <View
        style={{
          backgroundColor: 'white',
          alignSelf: 'center',
          paddingHorizontal: 50,
          paddingVertical: 20,
          borderRadius: 15,
        }}
        testID="ActivityIndicatorTxt">
        <View>
          <ItText
            style={{fontSize: 17, marginBottom: 10}}
            accessibilityLabel="loader">
            {message}
          </ItText>
        </View>
        <View>
          <ActivityIndicator size="large" color="#000000" />
        </View>
      </View>
    </Overlay>
  );
};

export default Loader;
