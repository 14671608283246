import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import {Space} from '../../../../theme';

export interface TabsContainerProps {
  children: React.ReactNode;
}

const TabsContainer: React.FunctionComponent<TabsContainerProps> = ({
  children,
}: TabsContainerProps) => {
  return <View style={styles.tabs}>{children}</View>;
};

const styles = StyleSheet.create({
  tabs: {
    paddingHorizontal: Space.MEDIUM,
    width: '100%',
    flexDirection: 'row',
    height: 60,
    shadowColor: '#000',
    backgroundColor: 'white',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
});

export default TabsContainer;
