import env from 'react-native-config';

export async function recoverPasswordRequest(email: string) {
  const res = await fetch(`${env.API_HOST_NEW}/api/oauth/pdv/recovery/pass`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      item: email,
    }),
  });
  if (!res.ok) {
    throw new Error('Connection error');
  }
}
