import {Field, FieldProps} from 'formik';
import * as React from 'react';
import {Input} from 'react-native-elements';

export interface ProfileNameFieldProps extends FieldProps {}

const ProfileNameField: React.FunctionComponent<ProfileNameFieldProps> = (
  props: ProfileNameFieldProps,
) => {
  return (
    <Field {...props}>
      {({field, meta, form}: FieldProps) => (
        <Input
          value={field.value}
          onChangeText={form.handleChange(field.name)}
          accessibilityLabel="name"
          label="Nombres:"
          autoCapitalize="words"
          errorMessage={meta.error}
        />
      )}
    </Field>
  );
};

export default ProfileNameField;
