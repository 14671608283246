import * as React from 'react';
import {GiftedChat, Bubble, Send} from 'react-native-gifted-chat';
import {useSelector} from 'react-redux';
import {Alert} from 'react-native';
import {primary} from '../../../theme/colors';
import AsyncStorage from '@callstack/async-storage';
import Loader from '../../../components/Loader';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import Toast from 'react-native-root-toast-updated';
import {selectAuthenticatedUser} from '../../auth/redux/authRedux';
import {useRoute} from '@react-navigation/native';
import {API_HOST} from '../../../env';

const CommunityCommentsScreen = () => {
  const route = useRoute<any>();
  const user = useSelector(selectAuthenticatedUser);
  const fullname = `${user.name} ${user.last_name}`;
  const postId = route.params.postId;
  const eventColor = primary;
  const [isLoading, setIsLoading] = React.useState(false);
  const [messages, setMessages] = React.useState([]);

  const fetchData = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const token = await AsyncStorage.getItem('token');
      const res = await fetch(
        `${API_HOST}/api/app/community/comment/list/${postId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
            Accept: 'application/json',
          },
        },
      );
      if (!res.ok) {
        throw new Error();
      }

      const {comments: data} = await res.json();

      const formated = data.map((item: any) => ({
        _id: item.id,
        text: item.comment,
        createdAt: new Date(item.created_at.replace(' ', 'T')),
        user: {
          _id: item.pdv.id,
          name: item.pdv.name,
          avatar: item.pdv.avatar,
        },
      }));
      setMessages(formated);
    } catch (ex) {
      setTimeout(() => {
        Toast.show('Error conectando con el servidor', {
          backgroundColor: 'red',
        });
      }, 0);
    }
    setIsLoading(false);
  }, [postId]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSend = React.useCallback(
    async (newMessages = []) => {
      try {
        const res = await fetch(`${API_HOST}/api/app/community/new/comment`, {
          method: 'post',
          body: JSON.stringify({
            comment: newMessages[0].text,
            community_id: postId,
          }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await AsyncStorage.getItem('token')}`,
          },
        });
        if (!res.ok) {
          throw new Error();
        }
        fetchData();
      } catch (ex) {
        Alert.alert('Error conectando con el servidor');
      }
    },
    [fetchData, postId],
  );

  return (
    <>
      <Loader loading={isLoading} message="Cargando..." />
      <GiftedChat
        renderSend={(props) => (
          <Send
            {...props}
            containerStyle={{
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              marginRight: 15,
            }}>
            <FontAwesome5Icon
              name="paper-plane"
              solid
              size={20}
              color={eventColor}
            />
          </Send>
        )}
        messages={messages}
        onSend={onSend}
        renderUsernameOnMessage
        renderBubble={(props: any) => {
          return (
            <Bubble
              {...props}
              // @ts-ignore
              /* textStyle={{
                right: {
                  backgroundColor: 'red',
                },
              }} */
              // @ts-ignore
              wrapperStyle={{
                right: {
                  backgroundColor: eventColor,
                },
              }}
            />
          );
        }}
        locale={'es'}
        placeholder="Escribe un comentario"
        user={{
          _id: user.id,
          name: fullname,
        }}
      />
    </>
  );
};

export default CommunityCommentsScreen;
