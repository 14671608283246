import * as React from 'react';
import {View} from 'react-native';
import {TouchableHighlight} from 'react-native-gesture-handler';
import ItText from '../../ui/ItText';

export interface DrawerMenuViewProps {
  iconElement: React.ReactElement;
  title: string;
  subtitle: string;
  onPress: () => void | Promise<any>;
}

const DrawerMenuView: React.FunctionComponent<DrawerMenuViewProps> = ({
  iconElement,
  title,
  subtitle,
  onPress,
}: DrawerMenuViewProps) => {
  return (
    <TouchableHighlight
      underlayColor="rgba(0,0,0,0.1)"
      onPress={onPress}
      containerStyle={{
        width: '49.97%',
        height: 155,
      }}
      style={{
        paddingHorizontal: 20,
        paddingVertical: 20,
        borderWidth: 0.5,
        borderColor: '#707070',
        height: 155,
      }}>
      <>
        <View
          style={{
            marginBottom: 10,
          }}>
          {iconElement}
        </View>
        <ItText style={{color: '#464646'}} strong size={13}>
          {title}
        </ItText>
        <ItText size={12} style={{color: '#787681'}}>
          {subtitle}
        </ItText>
      </>
    </TouchableHighlight>
  );
};

export default DrawerMenuView;
