import * as React from 'react';
import {Input} from 'react-native-elements';
import {QuestionComponentProps} from './QuestionRenderer';
import {CheckBox} from 'react-native-elements';
import cond from 'lodash/cond';
import some from 'lodash/some';
import stubTrue from 'lodash/stubTrue';
import reject from 'lodash/reject';
import {View} from 'react-native';
import _ from 'lodash';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import { primary } from '../../../theme';

const SingleResponse = (props: QuestionComponentProps) => {
  return (
    <>
      {props.question.options.map((op: any) => (
        <>
          <CheckBox
            title={op.name}
            checked={props.answers.some(
              (a) => a.campaign_question_option_id == op.id,
            )}
            checkedIcon={<FontAwesome5Icon color={primary} size={20} name="dot-circle"></FontAwesome5Icon>}
            uncheckedIcon={<FontAwesome5Icon color="#ccc" size={20} name="circle"></FontAwesome5Icon>}
            onPress={() => {
              props.setAnswers(
                cond([
                  [
                    () =>
                      some(props.answers, [
                        'campaign_question_option_id',
                        op.id,
                      ]),
                    () => {
                      return reject(props.answers, [
                        'campaign_question_option_id',
                        op.id,
                      ]);
                    },
                  ],
                  [
                    stubTrue,
                    () => [
                      {
                        campaign_question_option_id: op.id,
                        other_which_one: '',
                        text: '',
                      },
                    ],
                  ],
                ])(props.answers),
              );
            }}
          />
          {op.other_which_one == 1 && (
            <View
              style={{
                backgroundColor: 'rgb(250, 250, 250)',
                marginHorizontal: 10,
                marginTop: -10,
                borderColor: '#f4f4f4',
                borderWidth: 1,
              }}>
              <Input
                onChangeText={(t: string) => {
                  const nextAnswers = [...props.answers];
                  const itemIndex = nextAnswers.findIndex(
                    (a) => a.campaign_question_option_id == op.id,
                  );
                  nextAnswers[itemIndex] = {
                    ...nextAnswers[itemIndex],
                    other_which_one: t,
                  };
                  props.setAnswers(nextAnswers);
                }}
                value={
                  _.find(props.answers, {
                    campaign_question_option_id: op.id,
                  })?.other_which_one
                }
                /* value={otherWhich} */
                disabled={
                  !props.answers.some(
                    (a) => a.campaign_question_option_id == op.id,
                  )
                }
                placeholder="Cual?"
              />
            </View>
          )}
        </>
      ))}
    </>
  );
};

export default SingleResponse;
