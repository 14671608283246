import {useNavigation, useRoute} from '@react-navigation/core'
import {Formik} from 'formik'
import React from 'react'
import {ScrollView, View} from 'react-native'
import {Input} from 'react-native-elements'
import Toast from 'react-native-root-toast-updated'
import useFetch from 'use-http'
import {ItBlockButton} from '../../../components/buttons'
import OverlayConfirmMessage from '../../../components/OverlayConfirmMessage'
import UserPointsBanner from '../../home/components/UserPointsBanner'
import ItText from '../../ui/ItText'


const RedemptionFormScreen = () => {
  const navigation = useNavigation()
  const [showConfirm, setShowConfirm] = React.useState(false)
  const {post} = useFetch('/api/app/redemption/money/save', {
    cacheLife: 1
  })
  const route = useRoute<any>()

  const {item, config} = route.params;

  
  return (
    <ScrollView
      style={{
	paddingHorizontal: 20,
	paddingVertical: 10
      }}
    >
      <UserPointsBanner></UserPointsBanner>
      <Formik
	onSubmit={async values => {
	  const response = await post(values)
	  console.log(response)
	  if (response.code == 200) {
	   setShowConfirm (true)
	    return;
	  }
	  if (response.code == 422) {
	    Toast.show(response.data, {
	      backgroundColor: '#ff801f',
	    });
	    return;
	  }
	}}
	initialValues={{
	  points: '0',
	  money: '0',
	  redemtion_type_id: item.id
	}}
      >
	{({setFieldValue, isSubmitting,  values, handleSubmit}) => (
	  <>
	    <View
	      style={{
		marginHorizontal: 10,
	      marginTop: 20,
	      marginBottom: 20,
	      }}
	    >

	      <img src={item.img} width="150" />
	      <ItText style={{
		marginTop: 10,
		marginBottom: 30, 
	      fontWeight: 'bold',
	      fontSize: 24
	    }}>Redimir</ItText>
	      <ItText>Escribe la cantidad de dinero o puntos que quieres redimir:</ItText>

	    </View>
	    	    <Input
	      label="Puntos"
	      onChangeText={(v) => {
		const value = !v ? 0 : parseInt(v)
		setFieldValue('points', value.toString())
		const result = value * config.point_value.value
		setFieldValue('money', result.toString())
	      }}
	      value={values.points}
	      placeholder="Puntos"
	    /> 
	    <Input
	      containerStyle={{ marginTop: 30, marginBottom: 20 }}
	      label="Dinero"
	      onChangeText={(v) => {
		const value = !v ? 0 : parseInt(v)
		setFieldValue('money', value.toString())
		const result = value / config.point_value.value
		setFieldValue('points', result.toString())
	      }}
	      value={values.money}
	      placeholder="Dinero"
	    />
	      <ItBlockButton title="REDIMIR" onPress={() => handleSubmit()} disabled={isSubmitting}></ItBlockButton>
	      <OverlayConfirmMessage
		setIsOverlayOpen={setShowConfirm}
		title="Hecho"
		isOverlayOpen={showConfirm}
		onConfirm={() => {
		  navigation.goBack()
		}}
		text="Puntos redimidos exitosamente" />
	  </>
	)}

      </Formik>
    </ScrollView>
  )
}

export default RedemptionFormScreen
