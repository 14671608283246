import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import {Card} from 'react-native-elements';
import {vs} from 'react-native-size-matters';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import styled from 'styled-components/native';
import {primary, Space} from '../../../theme';
import CategoryDropdown from './CategoryDropdown';
import MakerDropdown from './MakerDropdown';

export interface RedemptionFiltersProps {}

const HEIGHT = vs(39);

const RedemptionFilters: React.FunctionComponent<RedemptionFiltersProps> = () => {
  return (
    <Card containerStyle={styles.container} wrapperStyle={styles.wrapperStyle}>
      <Column>
        <Decoration>
          <FontAwesome5Icon name="filter" size={13} light color="white" />
        </Decoration>
        <MakerDropdown />
      </Column>
      <Divider />
      <Column>
        <CategoryDropdown />
      </Column>
    </Card>
  );
};

const styles = StyleSheet.create({
  container: {
    height: HEIGHT,
    marginHorizontal: Space.MEDIUM,
    padding: 0,
    flexDirection: 'row',
    borderRadius: HEIGHT / 2,
  },
  wrapperStyle: {
    width: '100%',
    flexDirection: 'row',
  },
});

const Column = styled(View)`
  height: ${HEIGHT};
  width: 50%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const Divider = styled(View)`
  width: 1;
  position: absolute;
  top: ${(HEIGHT - vs(24)) / 2};
  left: 50%;
  height: ${vs(24)};
  align-self: center;
  background: #bab9c2;
`;

const Decoration = styled(View)`
  background-color: ${primary};
  width: 39;
  height: ${HEIGHT};
  border-top-left-radius: 20;
  border-bottom-left-radius: 20;
  align-items: center;
  justify-content: center;
`;

export default RedemptionFilters;
