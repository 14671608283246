import * as React from 'react';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {InjectableFormSection} from '../../../entities/InjectableFormSection';
import {primary} from '../../../theme';
import ProfileLastNameField from '../components/profileFields/ProfileLastNameField';
import ProfileNameField from '../components/profileFields/ProfileNameField';

const profileSections: InjectableFormSection[] = [
  // Datos Contacto
  {
    title: 'Datos de contacto',
    IconElement: <FontAwesome5Icon name="user" color={primary} size={20} />,
    fields: [
      {
        name: 'name',
        FieldComponent: ProfileNameField,
      },
      {
        name: 'last_name',
        FieldComponent: ProfileLastNameField,
      },
    ],
  },
  // Recuperar contraseña
 
];

export default profileSections;
