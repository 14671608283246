import {useNavigation} from '@react-navigation/native';
import * as React from 'react';
import {
  ImageBackground,
  ImageSourcePropType,
  StyleSheet,
  View,
} from 'react-native';
import {Card} from 'react-native-elements';
import {RectButton} from 'react-native-gesture-handler';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {primary} from '../../../theme';
import ItText from '../../ui/ItText';

export interface ShopperMenuItemProps {
  iconName?: string;
  iconElement?: React.ReactNode;
  title: string;
  subtitle: string;
  routeName: string;
  bgImage: ImageSourcePropType;
}

const ShopperMenuItem: React.FunctionComponent<RequireTwoProperties<
  ShopperMenuItemProps,
  'iconElement',
  'iconName'
>> = ({
  iconName,
  title,
  subtitle,
  iconElement,
  bgImage,
  routeName,
}: RequireTwoProperties<ShopperMenuItemProps, 'iconElement', 'iconName'>) => {
  const navigation = useNavigation();
  return (
    <Card
      containerStyle={styles.card}
      wrapperStyle={{
        margin: 0,
      }}>
      <RectButton
        underlayColor={primary}
        rippleColor={primary}
        onPress={() => {
          navigation.navigate(routeName);
        }}>
        <ImageBackground source={bgImage} style={styles.container}>
          <View style={styles.icon}>
            {iconName ? (
              <FontAwesome5Icon
                light
                size={28}
                color={primary}
                name={iconName}
              />
            ) : (
              iconElement
            )}
          </View>
          <ItText size={14} strong>
            {title}
          </ItText>
          <ItText light size={14}>
            {subtitle}
          </ItText>
        </ImageBackground>
      </RectButton>
    </Card>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 12,
    paddingVertical: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginBottom: 10,
  },
  card: {
    borderRadius: 10,
    padding: 0,
  },
});
export default ShopperMenuItem;
