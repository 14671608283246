import * as React from 'react';
import {Dimensions, Image, ScrollView} from 'react-native';

export interface ComingSoonScreenProps {}

const {width} = Dimensions.get('screen');

console.log('Comming soon');

const ComingSoonScreen: React.FunctionComponent<ComingSoonScreenProps> = (
  props: ComingSoonScreenProps,
) => {
  return (
    <ScrollView
      contentContainerStyle={{
        backgroundColor: '#7C2E04',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}>
      <Image
        style={{
          width: width - 40,
          aspectRatio: 0.5
        }}
        source={require('../assets/coming.png')}
      />
    </ScrollView>
  );
};

export default ComingSoonScreen;
