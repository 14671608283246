import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

export interface SendIconProps {}

const SendIcon: React.FunctionComponent<SendIconProps> = (
  props: SendIconProps,
) => {
  return (
    <Svg width="17" height="14" viewBox="0 0 17 14">
      <Path
        id="Path_68"
        data-name="Path 68"
        d="M2.008,17,19,10,2.008,3,2,8.444,14.143,10,2,11.556Z"
        transform="translate(0 -2)"
        fill="#fff"
      />
    </Svg>
  );
};

export default SendIcon;
