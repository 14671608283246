import * as React from 'react';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {MenuTabs} from '../MenuTabs';
import DrawerComponent from '../modules/drawer/components/Drawer';
export interface DrawerScreenProps {}

const Drawer = createDrawerNavigator();

const DrawerNavigator: React.FunctionComponent<DrawerScreenProps> = () => {
  const _renderDrawer = React.useCallback(() => <DrawerComponent />, []);
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerType="slide"
      drawerContent={_renderDrawer}>
      <Drawer.Screen
        component={MenuTabs}
        options={{title: 'tabs'}}
        name="tabs"
      />
    </Drawer.Navigator>
  );
};

export default React.memo(DrawerNavigator);
