// @ts-nocheck
import {useNavigation, useRoute} from '@react-navigation/native';
import {Formik} from 'formik';
import * as React from 'react';
import {Button, Card, Divider, Input} from 'react-native-elements';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {SectionTitle} from '../../../components/ItText';
import {primary} from '../../../theme/colors';
import * as Yup from 'yup';
import {EMAIL_VALIDATION, FIELD_REQUIRED} from '../../messages';
import CitiesAutocomplete from '../components/CitiesAutocomplete';
import AddressAutocomplete from '../components/AddressAutocomplete';
import {ScrollView} from 'react-native';
import TypeDocumentPicker from '../components/TypeDocumentPicker';
import ChannelDropdown from '../components/ChannelDropdown';
import SignupSuccess from '../components/SignupSuccess';

export interface SignupFormProps {}

const schema = Yup.object().shape({
  name: Yup.string().required(FIELD_REQUIRED),
  address: Yup.string().required(FIELD_REQUIRED),
  document: Yup.string().required(FIELD_REQUIRED),
  lastName: Yup.string().required(FIELD_REQUIRED),
  email: Yup.string().required(FIELD_REQUIRED).email(EMAIL_VALIDATION),
  password: Yup.string().required(FIELD_REQUIRED),
  password_confirmation: Yup.string().required(FIELD_REQUIRED),
  storeName: Yup.string().required(FIELD_REQUIRED),
  storeChanel: Yup.string().required(FIELD_REQUIRED),
  /* meiko_id: Yup.string().required(FIELD_REQUIRED), */
  documentType: Yup.string().required(FIELD_REQUIRED),
  city: Yup.string().required(
    'Debes seleccionar una de las opciones sugeridas',
  ),
});

const SignupForm: React.SFC<SignupFormProps> = () => {
  const route = useRoute<any>();
  const data = route?.params?.data[0] || {};

  const navigation = useNavigation();
  const [showSuccess, setshowSuccess] = React.useState(false);
  const country = '170';
  return (
    <ScrollView testID="container" keyboardShouldPersistTaps="handled">
      <Formik
        validationSchema={schema}
        initialValues={{
          phone: data.phone,
          name: '',
          lastName: '',
          address: '',
          document: '',
          prefix: data.prefix,
          storeName: '',
          city: '',
          email: '',
          password: '',
          documentType: '',
          storeChanel: '',
          password_confirmation: '',
          meiko_id: '',
          lat: '',
          lng: '',
        }}
        onSubmit={async (values) => {
          navigation.navigate('AuthMeikoCode', {
            values: {
              name: values.name,
              last_name: values.lastName,
              document_type_id: values.documentType,
              document: values.document,
              email: values.email,
              prefix: data.prefix,
              phone: values.phone,
              store_channel_id: values.storeChanel,
              store_name: values.storeName,
              country_id: country,
              city_id: values.city,
              address: values.address,
              aditional_info: '',
              lat: values.lat,
              lng: values.lng,
              password: values.password,
              password_confirmation: values.password_confirmation,
            },
          });
        }}>
        {({
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Card>
            <SectionTitle>3 - Completa tu información</SectionTitle>
            <Divider style={{marginVertical: 20}} />
            <Input
              label="Número de Celular"
              testID="phone"
              value={values.phone}
              errorMessage={
                touched.phone && errors.phone
                  ? (errors.phone as string)
                  : undefined
              }
              disabled
              onChangeText={handleChange('phone')}
              keyboardType="phone-pad"
              leftIcon={
                <FontAwesome5Icon
                  name="comment-alt"
                  color={primary}
                  light
                  size={18}
                />
              }
            />
            <Input
              label="Nombres del propietario"
              value={values.name}
              testID="name"
              errorMessage={
                touched.name && errors.name
                  ? (errors.name as string)
                  : undefined
              }
              onChangeText={handleChange('name')}
              leftIcon={
                <FontAwesome5Icon name="user" color={primary} light size={20} />
              }
            />
            <Input
              label="Apellidos del propietario"
              value={values.lastName}
              testID="lastName"
              onChangeText={handleChange('lastName')}
              errorMessage={
                touched.lastName && errors.lastName
                  ? (errors.lastName as string)
                  : undefined
              }
              leftIcon={
                <FontAwesome5Icon name="user" color={primary} light size={20} />
              }
            />
            <Input
              label="Tipo de documento"
              value={values.documentType}
              onChangeText={handleChange('documentType')}
              errorMessage={
                touched.documentType && errors.documentType
                  ? (errors.documentType as string)
                  : undefined
              }
              InputComponent={(props) => (
                <TypeDocumentPicker country={country} {...props} />
              )}
              leftIcon={
                <FontAwesome5Icon
                  name="id-badge"
                  color={primary}
                  light
                  size={25}
                />
              }
            />
            <Input
              label="Documento de Identidad"
              value={values.document}
              onChangeText={handleChange('document')}
              testID="document"
              keyboardType="phone-pad"
              errorMessage={
                touched.document && errors.document
                  ? (errors.document as string)
                  : undefined
              }
              leftIcon={
                <FontAwesome5Icon
                  name="id-badge"
                  color={primary}
                  light
                  size={25}
                />
              }
            />
            <Input
              label="Contraseña"
              testID="password_signup"
              value={values.password}
              errorMessage={
                touched.password && errors.password
                  ? (errors.password as string)
                  : undefined
              }
              onChangeText={handleChange('password')}
              secureTextEntry
              leftIcon={
                <FontAwesome5Icon name="lock" color={primary} light size={20} />
              }
            />
            <Input
              label="Repita la contraseña"
              testID="password_repeat"
              value={values.password_confirmation}
              errorMessage={
                touched.password_confirmation && errors.password_confirmation
                  ? (errors.password_confirmation as string)
                  : undefined
              }
              onChangeText={handleChange('password_confirmation')}
              secureTextEntry
              leftIcon={
                <FontAwesome5Icon name="lock" color={primary} light size={20} />
              }
            />
            <Input
              label="Correo"
              testID="email"
              value={values.email}
              onChangeText={handleChange('email')}
              errorMessage={
                touched.email && errors.email
                  ? (errors.email as string)
                  : undefined
              }
              keyboardType="email-address"
              leftIcon={
                <FontAwesome5Icon
                  name="envelope"
                  color={primary}
                  light
                  size={20}
                />
              }
            />
            <Input
              label="Nombre del establecimiento"
              value={values.storeName}
              testID="storeName"
              errorMessage={
                touched.storeName && errors.storeName
                  ? (errors.storeName as string)
                  : undefined
              }
              onChangeText={handleChange('storeName')}
              leftIcon={
                <FontAwesome5Icon
                  name="store"
                  color={primary}
                  light
                  size={18}
                />
              }
            />
            <Input
              label="Tipo de establecimiento"
              value={values.storeChanel}
              onChangeText={handleChange('storeChanel')}
              errorMessage={
                touched.storeChanel && errors.storeChanel
                  ? (errors.storeChanel as string)
                  : undefined
              }
              InputComponent={(props) => (
                <ChannelDropdown {...props} country={country} />
              )}
              leftIcon={
                <FontAwesome5Icon
                  name="store"
                  color={primary}
                  light
                  size={18}
                />
              }
            />
            <Input
              label="Dirección"
              testID="address"
              value={values.address}
              InputComponent={(props) => (
                <AddressAutocomplete
                  {...props}
                  onAddressSelected={(address) => {
                    setFieldValue('address', address.address);
                    setFieldValue('lat', address.lat);
                    setFieldValue('lng', address.lng);
                  }}
                />
              )}
              onChangeText={handleChange('address')}
              errorMessage={
                touched.address && errors.address
                  ? (errors.address as string)
                  : undefined
              }
              leftIcon={
                <FontAwesome5Icon
                  name="thumbtack"
                  color={primary}
                  light
                  size={26}
                />
              }
            />
            <Input
              label="Ciudad"
              value={values.city}
              onChangeText={handleChange('city')}
              errorMessage={
                touched.city && errors.city
                  ? (errors.city as string)
                  : undefined
              }
              InputComponent={(props) => (
                <CitiesAutocomplete {...props} countryId={country} />
              )}
              leftIcon={
                <FontAwesome5Icon name="map" color={primary} light size={18} />
              }
            />

            <Button
              title="CONTINUAR"
              testID="signup"
              onPress={() => handleSubmit()}
            />
          </Card>
        )}
      </Formik>
      <SignupSuccess
        isVisible={showSuccess}
        onBackdropPress={() => setshowSuccess(false)}
        onRequestClose={() => setshowSuccess(false)}
      />
    </ScrollView>
  );
};

export default SignupForm;
