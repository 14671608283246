import {useNavigation} from '@react-navigation/native';
import * as React from 'react';
import {View} from 'react-native';
import Toast from 'react-native-root-toast-updated';
import useFetch from 'use-http';
import OverlayConfirmation from '../../../components/OverlayConfirmation';
import {Block} from '../../../components/views';
import {Space} from '../../../theme';
import ItText from '../../ui/ItText';

function OfferConfirmOverlay({
  values,
  showConfirm,
  setShowConfirm,
}: {
  values: any;
  showConfirm: boolean;
  setShowConfirm: Function;
}) {
  const {post: createOffer, response, loading} = useFetch(
    '/api/app/shopper/promotion/create',
    {
      cacheLife: 1,
    },
  );
  const navigation = useNavigation();
  const [showSuccess, setSuccess] = React.useState(false);
  return (
    <>
      <OverlayConfirmation
        onConfirm={async () => {
          await createOffer(values);
          if (response.ok) {
            setShowConfirm(false);
            setSuccess(true);
          } else {
            Toast.show('Error conectando con el servidor', {
              duration: Toast.durations.LONG,
            });
          }
        }}
        loading={loading}
        isOverlayOpen={showConfirm}
        setIsOverlayOpen={setShowConfirm}
        title="Confirmación Promo"
        noCenter
        iconName="thumbs-up"
        TextElement={
          <>
            <ItText textCenter>
              Estás seguro que deseas crear la promoción:
            </ItText>
            <View
              style={{
                marginTop: 2,
                marginBottom: Space.LARGE,
              }}>
              <ItText size={16} strong>
                {values.name}
              </ItText>
              <ItText size={14}>{values.description}</ItText>
              <Block style={{marginTop: 10}}>
                <ItText strong>Precio:</ItText>
                <ItText>
                  {'  '}
                  {values.price}
                </ItText>
              </Block>
              <Block>
                <ItText strong>Unds Disponibles:</ItText>
                <ItText>
                  {'  '}
                  {values.sales_unity}
                </ItText>
              </Block>
              <Block>
                <ItText strong>Fecha Inicio:</ItText>
                <ItText>
                  {'  '}
                  {values.start}
                </ItText>
              </Block>
              <Block>
                <ItText strong>Fecha Finalización:</ItText>
                <ItText>
                  {'  '}
                  {values.end}
                </ItText>
              </Block>
            </View>
          </>
        }
      />
      <OverlayConfirmation
        isOverlayOpen={showSuccess}
        setIsOverlayOpen={setSuccess}
        title="Creación Exitosa"
        text="Promoción creada correctamente."
        onConfirm={() => {
          setSuccess(false);
          navigation.goBack();
        }}
        hideCancel
      />
    </>
  );
}

export default OfferConfirmOverlay;
