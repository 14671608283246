import {produce} from 'immer';
import {
  ADD_ITEM,
  HIDE_SHOPPING_CART,
  REMOVE_ALL_PRODUCTS,
  REMOVE_PRODUCT,
  SET_QUANTITY,
  shoppingCartActionTypes,
  TOGGLE_SHOPPING_CART,
} from './ShoppingCartActionTypes';
import _ from 'lodash';
import {Product} from '../../loyalty/modules/redemptions/entities/Product';

export interface ShoppingCartProduct<T> {
  id: number;
  product: T;
  quantity: number;
}

export interface State<T> {
  products: ShoppingCartProduct<T>[];
  isOpenOverlay: boolean;
}

const initialState = {
  products: [],
  isOpenOverlay: false,
};

export function shoppingCartReducer(
  state: State<Product> = initialState,
  action: shoppingCartActionTypes<Product>,
) {
  if (action.type == ADD_ITEM) {
    return produce(state, (draft) => {
      const prevIndex = _.findIndex(draft.products, ['id', action.payload.id]);

      if (prevIndex > -1) {
        draft.products[prevIndex].quantity += 1;
      } else {
        const newProduct: ShoppingCartProduct<Product> = {
          id: action.payload.id,
          product: action.payload.product,
          quantity: 1,
        };
        // @ts-ignore
        draft.products.unshift(newProduct);
      }

      draft.isOpenOverlay = true;
    });
  }
  if (action.type === TOGGLE_SHOPPING_CART) {
    return produce(state, (draft) => {
      draft.isOpenOverlay = !draft.isOpenOverlay;
    });
  }
  if (action.type === SET_QUANTITY) {
    return produce(state, (draft) => {
      const prevIndex = _.findIndex(draft.products, ['id', action.payload.id]);
      draft.products[prevIndex].quantity = action.payload.quantity;
    });
  }
  if (action.type === REMOVE_PRODUCT) {
    return produce(state, (draft) => {
      const prevIndex = _.findIndex(draft.products, ['id', action.payload.id]);
      draft.products = _.without(draft.products, draft.products[prevIndex]);
    });
  }
  if (action.type === REMOVE_ALL_PRODUCTS) {
    return produce(state, (draft) => {
      draft.products = [];
    });
  }
  if (action.type === HIDE_SHOPPING_CART) {
    return produce(state, (draft) => {
      draft.isOpenOverlay = false;
    });
  }
  return state;
}
