import {Alert, Linking, Platform, YellowBox} from 'react-native';
import {RootSiblingParent} from 'react-native-root-siblings';
import 'react-native-gesture-handler';
import * as React from 'react';
import {StyleSheet} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';
import {SafeAreaProvider, SafeAreaView} from 'react-native-safe-area-context';
import {ThemeProvider} from 'react-native-elements';
import Store from './store';
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import ApiWrapper from './ApiWrapper';
import {enableES5} from 'immer';
import {theme} from './theme-elements';
import {Navigator} from './Navigator';
import {enableScreens} from 'react-native-screens';
import * as yup from 'yup';
import yupLocale from './yupLocale';
import {API_HOST} from './env';
import loadFontAwesomeProWeb from "./loadFontAwesomeProWeb";

yup.setLocale(yupLocale);

enableScreens();

var relativeTime = require('dayjs/plugin/relativeTime');

dayjs.locale('es');
dayjs.extend(relativeTime);

YellowBox.ignoreWarnings(['']);

export const Stack = createStackNavigator();

loadFontAwesomeProWeb();


function App() {
  React.useEffect(() => {
    enableES5();
    const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()
  }, []);
  return (
    <>
    <style type="text/css">{`
        #root > div {
          height: calc(100vh - 3px);
          overflow: hidden;
          max-width: 600px;
          margin: 0 auto;
        }
        @font-face {
          font-family: 'MaterialIcons';
          src: url(${require('react-native-vector-icons/Fonts/MaterialIcons.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'FontAwesome';
          src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'FontAwesome5_Solid';
          src: url(${require('./assets/fonts/FontAwesome5_Pro_Solid.ttf')}) format('truetype');
        }
	@font-face {
          font-family: 'FontAwesome5_Brands';
          src: url(${require('./assets/fonts/FontAwesome5_Brands.ttf')}) format('truetype');
        }
	#root > div {
    height: var(--app-height);
}
      `}</style>

    <RootSiblingParent>
      <SafeAreaProvider>
<Provider store={Store.store}>
          <PersistGate persistor={Store.persistor}>
            <ApiWrapper>
              <ThemeProvider theme={theme}>
                <SafeAreaView style={styles.container}>
                  <Navigator />
                </SafeAreaView>
              </ThemeProvider>
            </ApiWrapper>
          </PersistGate>
        </Provider>
        
      </SafeAreaProvider>
    </RootSiblingParent>

    </>
  );
}
export default App;

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
