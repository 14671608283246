import {useNavigation} from '@react-navigation/native';
import * as React from 'react';
import {useSelector} from 'react-redux';

export interface SplashScreenProps {}

const SplashScreen: React.SFC<SplashScreenProps> = () => {
  const isAuthenticated = useSelector((state: any) => state.auth.user);
  const navigation = useNavigation();

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigation.navigate('login');
      return;
    }
    navigation.navigate('main');
  }, [isAuthenticated, navigation]);
  return null;
};

export default SplashScreen;
