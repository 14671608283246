// @ts-nocheck
import {Card} from 'react-native-elements';
import {s, vs} from 'react-native-size-matters';
import styled from 'styled-components/native';

const TopRoundedCard = styled(Card).attrs<{minHeight: number}>((props) => ({
  containerStyle: {
    minHeight: props.minHeight ?? 780,
    borderTopLeftRadius: 20,
    paddingHorizontal: s(13),
    paddingVertical: vs(17),
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}))<{minHeight: number}>``;

export default TopRoundedCard;
