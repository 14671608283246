import * as React from 'react';
import useFetch from 'use-http';
import {API_HOST} from '../../../env';
import {Block} from '../../../components/views';
import Dropdown from '../../ui/modules/inputs/Dropdown';

export interface ChannelDropdownProps {
  country: string;
}

const ChannelDropdown: React.SFC<ChannelDropdownProps> = ({
  country,
  ...props
}: ChannelDropdownProps) => {
  const {data = {}} = useFetch(
    `${API_HOST}/api/store/channel/list/${country}`,
    {},
    [],
  );
  const {data: channels = []} = data;
  const items = channels.map((c) => ({value: c.id, label: c.descripcion}));
  return (
    <Block testID="channel_dropdown">
      <Dropdown items={items} {...props} />
    </Block>
  );
};

export default ChannelDropdown;
