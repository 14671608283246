import {
  AddItemAction,
  ADD_ITEM,
  HideShoppingCartAction,
  HIDE_SHOPPING_CART,
  RemoveAllProductsAction,
  RemoveProductAction,
  REMOVE_ALL_PRODUCTS,
  REMOVE_PRODUCT,
  SetQuantityAction,
  SET_QUANTITY,
  ToggleShoppingCartAction,
  TOGGLE_SHOPPING_CART,
} from './ShoppingCartActionTypes';

export function addItem<T>(id: number, product: T): AddItemAction<T> {
  return {
    type: ADD_ITEM,
    payload: {
      id,
      product,
    },
  };
}

export function toggleShoppingCart(): ToggleShoppingCartAction {
  return {
    type: TOGGLE_SHOPPING_CART,
  };
}

export function setAmount(id: number, newQuantity: number): SetQuantityAction {
  return {
    type: SET_QUANTITY,
    payload: {
      quantity: newQuantity,
      id,
    },
  };
}

export function removeProduct(id: number): RemoveProductAction {
  return {
    type: REMOVE_PRODUCT,
    payload: {
      id,
    },
  };
}

export function removeAllProducts(): RemoveAllProductsAction {
  return {
    type: REMOVE_ALL_PRODUCTS,
  };
}

export function hideShoppingCart(): HideShoppingCartAction {
  return {
    type: HIDE_SHOPPING_CART,
  };
}
