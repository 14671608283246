import dayjs from 'dayjs';
import * as React from 'react';
import {InputProps} from 'react-native-elements';
import ItText from '../../../../components/ItText';
import {RectButton} from 'react-native-gesture-handler';
import DateTimePicker from '@react-native-community/datetimepicker';
import {primary} from '../../../../theme';

export interface BirthDatePickerProps extends InputProps {
  minDate?: Date;
  maxDate?: Date;
}

const defaultMinDate = dayjs().subtract(120, 'day').toDate();
const defaultMaxDate = dayjs().add(120, 'day').toDate();

const BirthDate: React.FunctionComponent<BirthDatePickerProps> = ({
  minDate = defaultMinDate,
  maxDate = defaultMaxDate,
  ...props
}: BirthDatePickerProps) => {
  const [show, setShow] = React.useState(false);
  return (
    <>
      <RectButton
        onPress={() => setShow(!show)}
        style={{
          flex: 1,
        }}>
        <ItText
          size={18}
          style={{
            color: props.value ? 'black' : '#3B3A3E',
          }}>
          {props.value || props.placeholder}
        </ItText>
      </RectButton>
      {show && (
        <DateTimePicker
          minimumDate={minDate}
          maximumDate={maxDate}
          textColor={primary}
          testID="dateTimePicker"
          value={props.value ? new Date(props.value) : new Date()}
          mode={'date'}
          display="default"
          is24Hour={true}
          onTouchCancel={() => {
            setShow(false);
          }}
          onChange={(_event, date) => {
            setShow(false);
            if (props.onChangeText) {
              if (date) {
                props.onChangeText(dayjs(date).format('YYYY-MM-DD'));
              } else {
                props.onChangeText('');
              }
            }
          }}
        />
      )}
    </>
  );
};

export default BirthDate;
