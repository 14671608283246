import * as React from 'react';
import {Animated} from 'react-native';
import Svg, {Defs, LinearGradient, Rect, Stop} from 'react-native-svg';

const AnimatedStop = Animated.createAnimatedComponent(Stop);
export interface ImagePlaceholderProps {}

const ImagePlaceholder: React.FunctionComponent<ImagePlaceholderProps> = () => {
  const [height, setHeight] = React.useState(0);
  const stopOffset = React.useRef(new Animated.Value(0.1)).current;

  React.useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.delay(100),
        Animated.timing(stopOffset, {
          useNativeDriver: false,
          toValue: 1,
          duration: 1000,
        }),
      ]),
    ).start();
  }, [stopOffset]);

  return (
    <Svg
      height={height}
      width="100%"
      onLayout={(event) => {
        setHeight(event.nativeEvent.layout.width);
      }}>
      <Defs>
        <LinearGradient id="grad" x1="0" y1="0" x2="1" y2="0">
          <Stop offset="0" stopColor="#D3D3D3" stopOpacity="1" />
          <AnimatedStop
            offset={stopOffset}
            stopColor="#D3D3D3"
            stopOpacity="1"
          />
          <Stop offset="1" stopColor="white" stopOpacity="1" />
        </LinearGradient>
      </Defs>
      <Rect width="100%" height="300" fill="url(#grad)" />
    </Svg>
  );
};

export default ImagePlaceholder;
