import {primary, secondary, lightGray} from './colors';
import {Theme} from 'react-native-elements';

const theme: Theme = {
  colors: {
    primary: primary,
    secondary: secondary,
  },
  Input: {
    placeholderTextColor: lightGray,
  },
};

export default theme;
