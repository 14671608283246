import {API_HOST} from '../../../env';

interface RequestParams {
  code: string;
  phone: string;
  prefix: string;
}

export default async function codeVerificationRequestHandler({
  code,
  prefix,
  phone,
}: RequestParams) {
  const res = await fetch(`${API_HOST}/api/oauth/pdv/phone/verification`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      prefix: prefix,
      phone: phone,
      code: code,
    }),
  });
  if (!res.ok) {
    if (res.status === 401) {
      throw new Error('El código ingresado no es correcto.');
    }
    throw new Error('Error completando la petición');
  }
  const data = await res.json();

  return data;
}
