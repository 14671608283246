import {ShoppingCartProduct} from './ShoppingCartState';
import {ItAppState} from '../../../store';
import {Product} from '../../loyalty/modules/redemptions/entities/Product';

export function selectIsShoppingCartOpen(state: ItAppState) {
  return !!state.shoppingCart.isOpenOverlay;
}

export function selectShoppingCartStatus({shoppingCart}: ItAppState) {
  const total = shoppingCart.products.reduce(
    (prev: number, p: ShoppingCartProduct<Product>) => {
      return prev + p.product.points_unity * p.quantity;
    },
    0,
  );
  return {
    products: shoppingCart.products,
    total: total,
  };
}
