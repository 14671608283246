import * as React from 'react';
import {View} from 'react-native';
import {s} from 'react-native-size-matters';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import styled from 'styled-components/native';
import {primary, Space} from '../../../theme';
import ItText from '../../ui/ItText';

export interface LoyaltyInstructionProps {
  text: string;
}

const LoyaltyInstruction: React.FunctionComponent<LoyaltyInstructionProps> = (
  props: LoyaltyInstructionProps,
) => {
  return (
    <Container>
      <FontAwesome5Icon color={primary} name="plus-circle" size={s(36)} />
      <Instruction>{props.text}</Instruction>
    </Container>
  );
};

const Container = styled(View)`
  padding: ${s(27)}px ${s(Space.MEDIUM)}px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1;
  border-bottom-color: #000000;
`;

const Instruction = styled(ItText)`
  font-size: ${s(14)}px;
  margin-left: ${18}px
  flex: 1;
`;

export default LoyaltyInstruction;
