import * as React from 'react';
import {
  ScreenContainer,
  JustifyCenterContainer,
  InputContainerView,
} from '../../../components/views';
import {Card} from 'react-native-elements';
import {Formik} from 'formik';
import * as yup from 'yup';
import {secondary} from '../../../theme/colors';
import {ItBlockButton} from '../../../components/buttons';
import {FaInputIcon} from '../../../components/icons';
import {ItInput} from '../../../components/input';
import Toast from 'react-native-root-toast-updated';
import Loader from '../../../components/Loader';
import {StackNavigationProp} from '@react-navigation/stack';
import {AuthStackParams} from '../../auth/entities/AuthStackParams';
import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import {recoverPasswordRequest} from '../api/recoverPasswordRequest';

interface Values {
  email: string;
}

type NavigationProp = StackNavigationProp<AuthStackParams, 'EmailRecovery'>;
type EmailRouteProp = RouteProp<AuthStackParams, 'EmailRecovery'>;

const EmailRecoveryScreen = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const route = useRoute<EmailRouteProp>();
  const navigation = useNavigation<NavigationProp>();
  const initialValue = route.params?.email ?? '';
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .strict(true)
      .trim('Remueve los espacios en blanco')
      .email('Debe ser un correo')
      .required('Campo requerido'),
  });

  const submit = async (values: Values): Promise<void> => {
    setIsLoading(true);
    try {
      await recoverPasswordRequest(values.email);
      navigation.navigate('ForgotPasswordTokenScreen', {
        email: values.email,
      });
    } catch (ex) {
      Toast.show('Error conectando al servidor', {
        backgroundColor: 'red',
      });
    }
    setIsLoading(false);
  };

  return (
    <ScreenContainer>
      <Loader loading={isLoading} message="cargando..." />
      <Card title="INGRESA TU CORREO" titleStyle={{color: secondary}}>
        <Formik
          onSubmit={submit}
          initialValues={{
            email: initialValue,
          }}
          validationSchema={validationSchema}>
          {({
            values,
            handleSubmit,
            errors,
            handleChange,
            handleBlur,
          }): React.ReactElement => {
            return (
              <>
                <InputContainerView>
                  <ItInput
                    errorMessage={errors.email as string}
                    value={values.email}
                    onBlur={handleBlur('email')}
                    textContentType="emailAddress"
                    autoCapitalize={'none'}
                    autoFocus
                    placeholder="Email"
                    keyboardType="email-address"
                    leftIcon={<FaInputIcon name="envelope" />}
                    onChangeText={handleChange('email')}
                  />
                </InputContainerView>
                <JustifyCenterContainer>
                  <ItBlockButton
                    onPress={(): void => handleSubmit()}
                    title="CONTINUAR"
                  />
                </JustifyCenterContainer>
              </>
            );
          }}
        </Formik>
      </Card>
    </ScreenContainer>
  );
};

EmailRecoveryScreen.navigationOptions = {
  title: 'Restore password',
};

export default EmailRecoveryScreen;
