import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import EmptyListComponent from '../../../components/EmptyListComponent';

const PedidosScreen: React.SFC = () => {
  return (
    <View style={styles.container}>
      {/* <PedidosCard imageSource={require('../assets/bebidas.png')} /> */}
      <EmptyListComponent
        iconName="eye-slash"
        text="No existen productos disponibles para realizar pedidos"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 10,
    paddingVertical: 20,
  },
});

export default PedidosScreen;
