// @ts-nocheck
import * as React from 'react';
import {
  ViewProps,
  View,
  ScrollViewProps,
  StyleSheet,
  ScrollView,
} from 'react-native';
import * as colors from '../theme/colors';
import {verticalScale} from 'react-native-size-matters';
import {CardProps, Card} from 'react-native-elements';

interface CommonProps extends ViewProps {
  children: React.ReactNode;
}

export interface ViewWithOneOrMultipleChilds extends ViewProps {
  children: React.ReactNode[] | React.ReactNode;
}

interface CardContainerProps extends ViewProps {
  children: React.ReactNode;
  padding?: boolean;
  background?: string;
}

interface BlockProps extends ViewProps {
  children: React.ReactNode;
  padding?: boolean;
  paddingHorizontal?: boolean;
  justifySpaceBetween?: boolean;
  alignCenter?: boolean;
  justifyCenter?: boolean;
  justifyEnd?: boolean;
}

interface ScreenContainerProps extends CommonProps, ScrollViewProps {
  padding?: boolean;
}
export const ScreenContainer: React.FC<ScreenContainerProps> = ({
  children,
  contentContainerStyle,
  ...props
}: ScreenContainerProps) => (
  <ScrollView
    contentContainerStyle={[
      {
        backgroundColor: colors.pageBackground,
        flexGrow: 1,
      },
      contentContainerStyle,
    ]}
    {...props}>
    {children}
  </ScrollView>
);

export const JustifyCenterContainer: React.FC<CommonProps> = ({
  style,
  children,
  ...props
}: CommonProps) => (
  <View
    style={[style, {justifyContent: 'center', alignItems: 'center'}]}
    {...props}>
    {children}
  </View>
);

export const InputContainerView: React.FC<CommonProps> = ({
  style,
  children,
  ...props
}: CommonProps) => (
  <View style={[style, {marginBottom: verticalScale(10)}]} {...props}>
    {children}
  </View>
);

export const CardContainer: React.FC<CardContainerProps> = ({
  style,
  children,
  padding,
  background,
  ...props
}: CardContainerProps) => {
  return (
    <View
      style={[
        {
          backgroundColor: background || 'white',
          width: '100%',
          padding: 15,
        },
        !!padding && {
          padding: 16,
        },
        style,
      ]}
      {...props}>
      {children}
    </View>
  );
};

export const Block: React.FC<BlockProps> = (props: BlockProps) => {
  const {
    padding,
    justifyCenter,
    justifySpaceBetween,
    alignCenter,
    paddingHorizontal,
    justifyEnd,
    children,
    style,
    ...restProps
  } = props;
  return (
    <View
      style={[
        styles.block,
        !!padding && styles.padding,
        justifySpaceBetween && styles.justifySpaceBetween,
        alignCenter && styles.alignCenter,
        paddingHorizontal && styles.paddingHorizontal,
        justifyCenter && styles.justifyCenter,
        justifyEnd && styles.justifyEnd,
        style,
      ]}
      {...restProps}>
      {children}
    </View>
  );
};

export const ScreenContainerJobs: React.FC<BlockProps> = (
  props: BlockProps,
) => {
  return <View style={styles.containterJobs}>{props.children}</View>;
};

export const BlueBlock: React.FC<BlockProps> = (props: BlockProps) => (
  <Block style={styles.blueBlock} {...props} />
);

export const SeparatorList: React.FC = ({style}: ViewProps) => (
  <View style={[styles.separator, style]} />
);

export const ItCard: React.FC<CardProps> = (props) => <Card {...props} />;

const styles = StyleSheet.create({
  block: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  padding: {
    padding: 20,
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: 'lightgray',
  },
  justifySpaceBetween: {
    justifyContent: 'space-between',
  },
  blueBlock: {
    backgroundColor: '#E3F5FF',
  },
  alignCenter: {
    alignItems: 'center',
  },
  paddingHorizontal: {
    paddingHorizontal: 20,
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  containterJobs: {
    backgroundColor: colors.pageBackground,
    padding: 15,
  },
});
