import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import ItText from '../../../../components/ItText';
import {secondary} from '../../../../theme/colors';

export function OverlayTitle({children}: {children: React.ReactNode}) {
  return (
    <ItText strong size={16} style={styles.title}>
      {children}
    </ItText>
  );
}

export function OverlayContent({children}: {children: React.ReactNode}) {
  return (
    <ItText size={16} style={styles.content}>
      {children}
    </ItText>
  );
}

export function OverlayBody({children}: {children: React.ReactNode}) {
  return <View style={styles.overlayContainer}>{children}</View>;
}

export function OverlayCode({children}: {children: React.ReactNode}) {
  return <ItText style={styles.overlayCode}>{children}</ItText>;
}

const styles = StyleSheet.create({
  title: {
    marginBottom: 14,
    textAlign: 'center',
  },
  content: {
    textAlign: 'center',
    marginBottom: 20,
    flexShrink: 1,
  },
  item: {
    textAlign: 'center',
    marginVertical: 20,
  },
  overlayContainer: {
    paddingHorizontal: 20,
    maxWidth: 270,
    marginVertical: 20,
  },
  overlay: {
    borderRadius: 10,
  },
  cancel: {
    marginTop: 10,
  },
  overlayCode: {
    color: secondary,
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 20,
  },
});
